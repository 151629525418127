<template>
  <Responsive>
    <template #mobile>
      <div class="w-full h-full flex justify-end">
        <div class="w-14 h-14 flex flex-col items-center justify-center text-white cursor-pointer relative" @click="visible = true">
          <img :src="require('@/assets/menu.svg')" width="24" height="24" />
          <div v-if="unreadMessages" class="absolute w-2 h-2 rounded-full bg-secondary-500 left-0 top-6"></div>
        </div>
      </div>
    </template>
    <template #desktop>
      <div class="w-full h-full flex justify-end">
        <div class="w-fit h-full flex gap-2 items-center text-white px-4 cursor-pointer custom-hover" @click="visible = true">
          <i class="pi pi-user" style="font-size: 1rem"></i>
          <div>{{ nameShort }}</div>
          <i class="pi pi-chevron-down" style="font-size: 0.75rem"></i>
        </div>
      </div>
    </template>
  </Responsive>
  <Sidebar v-model:visible="visible" position="right" style="width: fit-content">
    <template #container>
      <Responsive>
        <template #mobile>
          <div class="h-full flex flex-col items-center" style="width: 240px">
            <div class="w-full px-4 pt-5 pb-4">
              <div class="w-6 h-6 cursor-pointer" @click="visible = false">
                <i class="pi pi-times text-white" style="font-size: 1rem"></i>
              </div>
            </div>
            <div class="grow"></div>
            <div class="w-full">
              <MenuButton v-for="m in menu" :icon="m.icon" :name="m.name" :path="m.path" :activeTest="m.activeTest" :count="m.count" @click="goTo(m.path)"></MenuButton>
            </div>
            <div v-if="$store.getters['user/isStaff']" class="mt-2">
              <router-link :to="{ name: 'clients' }">
                <div class="h-[calc(56px)] flex items-center cursor-pointer">
                  <div class="text-white underline">Administrácia</div>
                </div>
              </router-link>
            </div>
            <div class="grow"></div>
            <div class="flex gap-1 justify-between w-full pr-4 pl-6">
              <div class="flex flex-col gap-2 text-white text-regular">
                <div class="opacity-50">Prihlásený</div>
                <div class="">{{ nameFull }}</div>
              </div>
              <div>
                <div class="w-10 h-10 bg-primary-900 bdr text-danger flex items-center justify-center cursor-pointer leading-none" @click="logout()">
                  <i class="pi pi-sign-out" style="font-size: 0.9rem; padding-left: 2px"></i>
                </div>
              </div>
            </div>
            <div class="pb-6 pt-12">
              <img :src="require('@/assets/logo.svg')" width="116" />
            </div>
          </div>
        </template>
        <template #desktop>
          <div class="h-full flex flex-col items-center bg-white text-regular" style="width: 350px">
            <div class="w-full px-4 pt-5 pb-6 flex justify-between shrink-0">
              <div class="w-6 h-6 cursor-pointer flex items-center justify-center" @click="visible = false">
                <i class="pi pi-times text-primary-900" style="font-size: 1rem"></i>
              </div>
              <div class="text-danger flex items-center gap-2 leading-none cursor-pointer" @click="logout()">
                <i class="pi pi-sign-out" style="font-size: 1rem"></i>
                <div>Odhlásiť sa</div>
              </div>
            </div>
            <div class="w-full px-10 overflow-y-auto">
              <div class="w-full h-fit">
                <div class="text-center pb-4">
                  <ProfileItem label="Ste prihlásený">
                    <div class="font-bold text-h5">{{ nameFull }}</div>
                  </ProfileItem>
                </div>
              </div>
              <ProfileItem label="Telefónne číslo" class="mt-4">
                <div class="font-semibold">{{ phone }}</div>
              </ProfileItem>
              <ProfileItem label="E-mail" class="mt-4">
                <div class="flex items-center gap-2 relative w-fit">
                  <div class="font-semibold">{{ portalUser?.email }}</div>
                  <div
                    class="w-fit cursor-pointer flex items-center justify-center"
                    v-tooltip.bottom.focus="'Tento e-mail je aj Vašim prihlasovacím menom do portálu. Ak si ho prajete zmeniť, kontaktujte nás.'"
                    tabindex="1"
                  >
                    <i class="pi pi-info-circle text-primary-900" style="font-size: 1rem"></i>
                  </div>
                </div>
              </ProfileItem>
              <div class="mt-0.5 w-full">
                <div class="font-semibold text-primary-900 text-regular py-4 underline cursor-pointer" @click="changePassword">Zmeniť prihlasovacie heslo</div>
              </div>
              <div v-if="$store.getters['user/isStaff']">
                <router-link :to="{ name: 'clients' }">
                  <div class="flex items-center cursor-pointer pt-2 pb-4">
                    <div class="font-semibold text-primary-900 underline">Administrácia</div>
                  </div>
                </router-link>
              </div>
              <div class="w-full shrink-0">
                <div class="brd w-full mt-2 mb-6"></div>
                <Bank></Bank>
              </div>
            </div>
          </div>
        </template>
      </Responsive>
    </template>
  </Sidebar>
</template>

<script lang="ts">
import { fallbackString, formatPhoneNumber, shortName } from '@/misc'
import { Portal } from '@/models/portal'
import { PortalUser, OmniumUser } from '@/models/user'
import AppToast from '@/toast'
import { Options, Vue } from 'vue-class-component'
import ChangePasswordtDialog from '../Dialog/ChangePassword.vue'
import Bank from '../Profile/Bank.vue'
import ProfileItem from '../Profile/ProfileItem.vue'
import Responsive from '../Responsive.vue'
import MenuButton from './MenuButton.vue'

interface Menu {
  icon: string
  name: string
  path: string
  activeTest?: string
  count?: number
}

@Options({
  components: {
    MenuButton,
    Responsive,
    ProfileItem,
    Bank,
  },
})
export default class SideMenu extends Vue {
  visible: boolean = false

  loggingOut: boolean = false

  nameShort: String | null = null

  menu: Menu[] = [
    {
      icon: 'pi-chart-line',
      name: 'Portfóliá',
      path: '/strategies/portfolio',
      activeTest: '/strategies',
    },
    {
      icon: 'pi-envelope',
      name: 'Správy',
      path: '/messages/all-messages',
      activeTest: '/messages',
    },
    {
      icon: 'pi-user',
      name: 'Profil',
      path: '/user/profile',
      activeTest: '/user',
    },
    {
      icon: 'pi-phone',
      name: 'Kontakty',
      path: '/contact',
    },
  ]

  async mounted() {
    this.nameShort = shortName(this.nameFull)
    this.getUnseenMessageCount()
  }

  updated() {
    if (this.visible) {
      this.getUnseenMessageCount()
    }
  }

  getUnseenMessageCount() {
    const count = this.portal.unseenMessageCount ?? 0
    this.menu[1].count = count
  }

  async logout() {
    this.loggingOut = true
    try {
      this.$store.commit('portal/clear')
      await this.$store.dispatch('user/logout')
      this.$router.push({ name: 'login' })
      AppToast.info(null, 'Boli ste odhlásený')
    } finally {
      this.loggingOut = false
    }
  }

  async goTo(path: string) {
    this.$router.push({ path: path })
    this.visible = false
  }

  changePassword() {
    if (this.desktop) {
      this.$dialog.open(ChangePasswordtDialog, {
        props: {
          header: 'Zmena hesla',
          style: {
            width: '375px',
          },
          modal: true,
          draggable: false,
        },
      })
    } else {
      this.$router.push({ name: 'change-password' })
      this.visible = false
    }
  }

  get portalUser(): PortalUser | null | undefined {
    return this.$store.state.user.portalUser
  }

  get omniumUser(): OmniumUser | null | undefined {
    return this.portalUser?.omniumUser
  }

  get nameFull(): string | null | undefined {
    return this.omniumUser?.fullName ?? this.portalUser?.email
  }

  get phone(): string {
    if (this.omniumUser?.phone === undefined || this.omniumUser?.phone === null) {
      return fallbackString(this.omniumUser?.phone)
    }
    return formatPhoneNumber(this.omniumUser?.phone)
  }

  get unreadMessages(): boolean {
    return this.$store.getters['portal/unreadMessages']
  }

  get portal(): Portal {
    return this.$store.state.portal
  }

  get desktop() {
    return !this.$store.state.responsive.mobile
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.p-button.p-button-icon-only.p-button-rounded {
  height: 3.5rem;
  width: 3.5rem;
}

.bdr {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 1000px;
}

.custom-hover:hover {
  background-color: rgba(255, 255, 255, 0.1);

  i:nth-child(3) {
    color: #eab402;
  }
}
</style>
