<template>
  <Detail :hideHeader="true">
    <div class="w-full h-full flex flex-col">
      <div class="text-h4 font-bold my-5">Účet už bol aktivovaný</div>
      <div class="text-regular">Prejdite do portálu a prihláste sa.</div>
      <div class="mt-6">
        <router-link :to="{ name: 'login' }">
          <Button label="Prejsť na prihlásenie"></Button>
        </router-link>
      </div>
    </div>
  </Detail>
</template>

<script lang="ts">
import Detail from '@/components/Layout/Detail.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Detail,
  },
})
export default class AlreadyActiveView extends Vue {

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul {
  list-style-type: disc;
}
</style>
