<template>
  <div v-if="loading" class="w-full h-96 flex items-center justify-center">
    <ProgressSpinner />
  </div>
  <Detail :hideHeader="true" v-else>
    <div class="w-full h-full flex flex-col">
      <div class="text-h4 font-bold my-5">Vytvorte si nové heslo</div>
      <div class="text-regular">
        Vytvorte si heslo, ktorým sa budete prihlasovať do klientského portálu.
      </div>
      <div class="mt-6">
        <CreatePassword :email="email" :onCreate="changePassword" />
      </div>
    </div>
  </Detail>
</template>

<script lang="ts">
import CreatePassword from '@/components/Intro/CreatePassword.vue'
import Detail from '@/components/Layout/Detail.vue'
import { Error401 } from '@/errors/axiosErrors'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Detail,
    CreatePassword,
  },
})
export default class UpdatePasswordView extends Vue {

  loading: boolean = false
  refreshToken: string = ''

  async mounted() {
    this.refreshToken = this.$route.params.refreshToken as string
    await this.init()
  }

  async init() {
    if (this.loading) {
      return
    }
    this.loading = true
    this.$store.commit('user/setRefreshToken', this.refreshToken)
    try {
      try {
        await this.$store.dispatch('user/tokenRefresh')
      } catch (e) {
        if (e instanceof Error401) {
          this.$router.push({ name: 'reset-password-expired' })
          return
        }
        throw e
      }
      await this.$store.dispatch('user/initUser')
    } finally {
      this.loading = false
    }
  }

  async changePassword(password: string) {
    await this.$store.dispatch('user/changePassword', { password })
    this.$router.push({ name: 'update-password-success' })
  }

  get email(): string | null | undefined {
    return this.$store.state.user.portalUser.email
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
