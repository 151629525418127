<template>
  <div v-if="message" class="w-full h-full">
    <Responsive>
      <template #mobile>
        <div class="text-h4 font-bold">{{ message.subject }}</div>
        <div class="text-regular text-text-500 mt-1">{{ formatMessageDate() }}</div>
        <div class="mt-6 text-msg text-black w-full" v-html="message.body"></div>
        <div class="mt-6 flex flex-col">
          <div class="text-h5 font-bold text-text-500 pb-1">
            {{ hasAttachments ? 'Prílohy' : 'Žiadne prílohy' }}
          </div>
          <div
            v-if="hasAttachments"
            v-for="attachment in message.attachments"
            class="text-regular font-semibold text-primary-900 flex items-center gap-3 py-1 cursor-pointer"
            @click="downloadAttachment(attachment)"
          >
            <div><i class="pi pi-paperclip" style="font-size: 0.75rem"></i></div>
            <!-- <div>{{ toFileName(attachment.file) }}</div> -->
            <div>{{ attachment.name }}</div>
          </div>
        </div>
      </template>
      <template #desktop>
        <div class="w-full h-full overflow-y-auto">
          <div class="text-regular text-text-500 mt-2 leading-none">{{ formatMessageDate() }}</div>
          <div class="text-h2 font-semibold leading-none mt-1">{{ message.subject }}</div>
          <div class="mt-10 text-msg text-black w-full" style="max-width: 600px" v-html="message.body"></div>
          <div class="mt-10 flex flex-col">
            <div class="text-regular font-bold text-text-500 pb-2 leading-none">
              {{ hasAttachments ? 'Prílohy' : 'Žiadne prílohy' }}
            </div>
            <div
              v-if="hasAttachments"
              v-for="attachment in message.attachments"
              class="text-regular font-semibold text-primary-900 flex items-center gap-3 py-1 cursor-pointer"
              @click="downloadAttachment(attachment)"
            >
              <div><i class="pi pi-paperclip" style="font-size: 0.75rem"></i></div>
              <!-- <div>{{ toFileName(attachment.file) }}</div> -->
              <div>{{ attachment.name }}</div>
            </div>
          </div>
          <div class="w-full h-4"></div>
        </div>
      </template>
    </Responsive>
  </div>
</template>

<script lang="ts">
import MessageApi from '@/api/modules/message'
import { formatDate, isDST } from '@/misc'
import { Attachment, Message } from '@/models/message'
import { Options, Vue } from 'vue-class-component'
import Responsive from '../Responsive.vue'

@Options({
  props: {
    message: {
      type: Object as () => Message,
      required: true,
      nullable: false,
    },
  },
  components: {
    Responsive,
  },
})
export default class MessageDetail extends Vue {
  message!: Message

  beforeMount() {
    this.init()
  }

  init() {}

  formatMessageDate(): string | null {
    const date = this.message?.date
    if (!date) {
      return null
    }
    const real = new Date(date)
    if (isDST(real)) {
      real.setHours(real.getHours() + 2)
    } else {
      real.setHours(real.getHours() + 1)
    }
    return formatDate(real)
  }

  async downloadAttachment(attachment: Attachment) {
    const blob = await MessageApi.getAttachment(attachment.id)
    const href = window.URL.createObjectURL(blob)
    const anchorElement = document.createElement('a')
    anchorElement.href = href
    anchorElement.download = attachment.name
    document.body.appendChild(anchorElement)
    anchorElement.click()
    document.body.removeChild(anchorElement)
    window.URL.revokeObjectURL(href)
  }

  get hasAttachments() {
    return this.message?.attachments != undefined && this.message?.attachments != null && this.message?.attachments.length > 0
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
