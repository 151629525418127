import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ab54172"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full h-full flex flex-col items-center" }
const _hoisted_2 = { class: "w-full h-[calc(56px)] flex flex-col bg-primary-900 justify-center shrink-0 fixed z-50" }
const _hoisted_3 = { class: "w-full flex justify-between items-center gap-4 h-full" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "grow w-full mt-14 overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: 'login' },
          class: "px-4 w-fit flex items-center h-full cursor-pointer"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require('@/assets/logo.svg'),
              width: "136"
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ])
  ]))
}