<template>
  <div class="w-full flex flex-col items-center h-full">
    <div v-if="!hideHeader" class="flex flex-col h-12 w-full px-4 text-primary-900 justify-center items-center">
      <div class="px-2 flex items-center justify-between w-[calc(375px)]">
        <div v-if="back" class="flex items-center cursor-pointer" @click="onBackCallback">
          <div class="w-8 h-8 flex items-center justify-center">
            <i class="pi pi-arrow-left" style="font-size: 0.75rem"></i>
          </div>
          <div class="text-regular font-semibold leading-none">{{ back }}</div>
        </div>
        <div v-else class="w-8 h-8"></div>
        <div class="w-16 h-14 flex items-center justify-center">
          <slot name="trailing"></slot>
        </div>
      </div>
    </div>
    <div class="w-[calc(375px)] flex flex-col items-center pb-4 grow" :class="{ 'px-4': !noPadding }">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
  props: {
    back: {
      type: String,
      required: false,
      nullable: true,
    },
    hideHeader: {
      type: Boolean,
      required: false,
      nullable: false,
      default: false,
    },
    noPadding: {
      type: Boolean,
      required: false,
      nullable: false,
      default: false,
    },
    onBack: {
      type: Function,
      required: false,
      nullable: true,
    },
  },
})
export default class Detail extends Vue {
  back?: string
  hideHeader!: boolean
  noPadding!: boolean

  onBack: Function | null | undefined

  async mounted() {
    await this.init()
  }

  async init() {}

  onBackCallback() {
    if (this.onBack) {
      this.onBack()
    } else {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss"></style>
