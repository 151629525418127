<template>
  <Detail :hideHeader="true">
    <div class="w-full h-full flex flex-col">
      <div class="text-h4 font-bold my-5">Hotovo</div>
      <div class="text-regular">Váš účet bol aktivovaný a nové heslo úspešne nastavené.</div>
      <div class="mt-6">
        <router-link :to="{ name: $store.getters['user/isStaff'] ? 'clients' : 'portfolio' }">
          <Button label="Vstúpiť do portálu"></Button>
        </router-link>
      </div>
    </div>
  </Detail>
</template>

<script lang="ts">
import Detail from '@/components/Layout/Detail.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Detail,
  },
})
export default class SuccessView extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul {
  list-style-type: disc;
}
</style>
