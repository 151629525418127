import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    label: _ctx.label,
    outlined: "",
    severity: "danger",
    loading: _ctx.loading,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.remove()))
  }, null, 8, ["label", "loading"]))
}