<template>
  <div>
    <Button v-tooltip.bottom="'Synchronizovať portfól.'" icon="pi pi-sync" text @click="reSyncStrategies" :loading="loading" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { useConfirm } from 'primevue/useconfirm'
import { PortalUser } from '@/models/user'
import PortalApi from '@/api/modules/portal'
import AppToast from '@/toast'

@Options({
  props: {
    portalUser: {
      type: Object as () => PortalUser,
      required: false,
      nullable: true,
    },
  },
})
export default class ReSyncStrategiesButton extends Vue {
  portalUser?: PortalUser | undefined | null

  loading: boolean = false

  confirm = useConfirm()

  async beforeMount() {
    await this.init()
  }

  async init() {}

  reSyncStrategies() {
    this.confirm.require({
      message: `Synchronizácia može trvať niekolko minút, počas ktorých nebudú údaje v portfóliu dostupné.<br><br>Naozaj chcete spustiť synchronizáciu portfólia klienta <b>${this.portalUser?.email}</b>?`,
      header: 'Synchronizovať portfólio',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      accept: async () => {
        this.loading = true
        try {
          if (this.portalUser?.id) {
            AppToast.info(null, 'Synchronizácia klienta bola spustená')
            await PortalApi.reSyncStrategies(this.portalUser.id)
            // await delay(20000)
          }
        } finally {
          this.loading = false
        }
      },
    })
  }
}
</script>

<style scoped lang="scss"></style>
