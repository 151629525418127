<template>
  <form autocomplete="off" class="w-full h-full">
    <div class="flex flex-col gap-2 w-full">
      <div v-if="error" class="pb-5">
        <InlineMessage severity="error" class="w-full"> Nesprávne aktuálne heslo </InlineMessage>
      </div>
      <label class="label">Aktuálne heslo</label>
      <Password :id="dynamicid()" autocomplete="nope" v-model="oldPassword" :feedback="false" class="w-full" :inputStyle="{ width: '100%' }" toggleMask @keydown.enter="confirm" />
      <!-- <span class="p-input-icon-right">
        <i @click="toggleOldPasswordVisibility" :class="{ 'pi pi-eye-slash': oldPasswordVisible, 'pi pi-eye': !oldPasswordVisible }" />
        <Password :id="dynamicid()" autocomplete="nope" v-model="oldPassword" class="w-full" :inputStyle="{ width: '100%' }" @keydown.enter="confirm" />
      </span> -->
    </div>
    <div class="flex flex-col gap-2 text-regular mt-4 w-full">
      <label class="label">Nové heslo</label>
      <Password
        :id="dynamicid()"
        v-model="newPassword"
        promptLabel=" "
        weakLabel="Slabé"
        mediumLabel="Stredné"
        strongLabel="Silné"
        toggleMask
        class="w-full"
        autocomplete="nope"
        :mediumRegex="mediumRegex.source"
        :strongRegex="strongRegex.source"
        :inputStyle="{ width: '100%' }"
        @update:modelValue="onUpdate"
        @keydown.enter="confirm"
      >
        <template #header>
          <div class="text-regular font-semibold pb-4">Zvoľte si heslo</div>
        </template>
        <template #footer>
          <Divider />
          <div class="leading-none text-regular">Heslo musí obsahovať:</div>
          <ul class="pl-2 ml-2 mt-4 text-regular" style="line-height: 1.5">
            <li>Aspoň jedno malé písmeno</li>
            <li>Aspoň jedno veľké písmeno</li>
            <li>Aspoň jednu číslicu</li>
            <li>Minimálne 8 znakov</li>
          </ul>
        </template>
      </Password>
    </div>
    <div class="mt-4 w-full">
      <Button label="Zmeniť heslo" :loading="loading" :disabled="disabled" @click="confirm"></Button>
    </div>
  </form>
</template>

<script lang="ts">
import { Error400, Error401 } from '@/errors/axiosErrors'
import AppToast from '@/toast'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class ChangePasswordForm extends Vue {
  loading: boolean = false
  disabled: boolean = true
  error: boolean = false

  _oldPassword: string = ''

  oldPassword: string = ''
  newPassword: string = ''

  mediumRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')
  strongRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')

  oldPasswordVisible: boolean = false

  beforeMount() {
    this.init()
  }

  init() {}

  async confirm() {
    if (this.loading) {
      return
    }
    this.error = false
    this.loading = true
    try {
      try {
        await this.$store.dispatch('user/changePassword', { oldPassword: this.oldPassword, password: this.newPassword })
      } catch (e) {
        if (e instanceof Error401 || e instanceof Error400) {
          this.error = true
          return
        }
        throw e
      }
      this.$emit('onSuccess')
      AppToast.info(null, 'Heslo bolo zmenené')
    } finally {
      this.loading = false
    }
  }

  onUpdate(value: string) {
    const valid = (this.newPassword.match(this.mediumRegex) || this.newPassword.match(this.strongRegex)) && this.newPassword != this.oldPassword
    if (this.disabled && valid) {
      this.disabled = false
    } else if (!this.disabled && !valid) {
      this.disabled = true
    }
  }

  toggleOldPasswordVisibility() {
    this.oldPasswordVisible = !this.oldPasswordVisible
  }

  dynamicid() {
    return 'dynamicid-' + Math.floor(Math.random() * Date.now())
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
