<template>
  <div v-if="loading" class="w-full flex items-center justify-center">
    <ProgressSpinner></ProgressSpinner>
  </div>
  <div v-else>
    <DataTable :value="admins">
      <Column field="email" header="Email" :sortable="true" class="font-semibold"></Column>
      <template #empty>
        <div class="w-full text-center text-h5 text-text-500">
          {{ (admins ?? []).length <= 0 ? 'Žiadni admini': 'Žiadne nájdené záznamy' }}
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script lang="ts">
import UserApi from '@/api/modules/user'
import { PortalUser } from '@/models/user'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
})
export default class AdminsView extends Vue {
  admins?: PortalUser[]

  loading: boolean = false

  async beforeMount() {
    await this.init()
  }

  async init() {
    this.loading = true
    try {
      this.admins = await UserApi.getAllAdmins()
    } finally {
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss"></style>
