<template>
  <div>
    <Button
      v-tooltip.bottom="'Vymazať správu'"
      icon="pi pi-trash"
      severity="danger"
      text
      @click="deleteMessage"
      :loading="loading"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { useConfirm } from 'primevue/useconfirm'
import { Message } from '@/models/message'
import PortalApi from '@/api/modules/portal'
import AppToast from '@/toast'

@Options({
  props: {
    message: {
      type: Object as () => Message,
      required: true,
      nullable: false,
    },
  },
})
export default class DeleteMessageButton extends Vue {
  message!: Message

  loading: boolean = false

  confirm = useConfirm()

  async beforeMount() {
    await this.init()
  }

  async init() {}

  deleteMessage() {
    this.confirm.require({
      message: `<b>Pozor:</b> Správa bude vymazaná aj na strane klienta.<br><br>Naozaj chcete vymazať správu?`,
      header: 'Vymazať správu',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      accept: async () => {
        this.loading = true
        try {
          if (this.message) {
            await PortalApi.deletePortalMessage(this.message.id)
            const messages = this.messages.filter(element => element.id != this.message.id)
            this.$store.commit('admin/setMessages', messages)
            AppToast.info(null, 'Správa bola vymazaná')
          }
        } finally {
          this.loading = false
        }
      },
    })
  }

  get messages(): Message[] {
    return this.$store.state.admin.messages
  }
}
</script>

<style scoped lang="scss"></style>
