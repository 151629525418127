<template>
  <CustomDialog :title="showRecipients ? 'Príjemcovia': 'Nová správa'" @onClose="close">
    <div v-if="loadingClients" class="w-full h-full flex items-center justify-center"></div>
    <div v-else class="w-full">
      <ClientsSelect
        v-if="showRecipients"
        leftTabTitle="Všetci klienti"
        rightTabTitle="Príjemcovia"
        addButtonLabel="Pridať príjemcu"
        removeButtonLabel="Odobrať príjemcu"
        :leftTabClients="leftTabClients"
        :rightTabClients="rightTabClients"
        :leftTabCallback="addClient"
        :rightTabCallback="removeClient"
        rightTabMessageWhenEmpty="Žiadni príjemcovia"
      >
      </ClientsSelect>
      <div v-else class="pt-4 px-4">
        <div class="text-h5 font-semibold">Príjemcovia</div>
        <div>{{ recipientsNum }}</div>
        <div class="mt-3">
          <Button outlined @click="showRecipients = true">
            <div class="flex items-center gap-2">
              <div class="text-h6 font-semibold">Spravovať príjemcov</div>
              <i class="pi pi-arrow-right" style="font-size: 0.8rem"></i>
            </div>
          </Button>
        </div>
        <div class="mt-6">
          <div class="text-h5 font-semibold">Správa</div>
          <div class="flex flex-col gap-2 custom-w mt-3">
            <label for="subject" class="label">Predmet</label>
            <InputText id="subject" placeholder="Zadajte predmet" v-model="subject" />
          </div>
          <div class="mt-4">
            <Editor id="body" v-model="body" editorStyle="height: 320px; width: 100%" />
          </div>
          <div class="flex flex-col gap-2 mt-4">
            <div v-for="attachment in attachments">
              <div class="flex items-center gap-2 leading-none">
                <div>{{ attachment.name }}</div>
                <div>
                  <Button icon="pi pi-trash" size="small" severity="danger" text rounded aria-label="Cancel" @click="removeAttachment(attachment)" />
                </div>
              </div>
            </div>
            <div>
              <FileUpload
                id="photo"
                mode="basic"
                customUpload
                class="p-button-outlined"
                @uploader="customUploader"
                accept=".doc,.docx,.pdf,.xls,.xlm,.xlsx,.xlsm,.ppt,.pptx,.png,.jpg,.jpeg"
                :maxFileSize="3000000"
                :auto="true"
                chooseLabel="Pridať prílohu"
              />
            </div>
          </div>
        </div>
        <div class="mt-8">
          <div v-if="error" class="pb-4">
            <InlineMessage severity="error" class="w-full"> {{ error }} </InlineMessage>
          </div>
          <Button label="Poslať správu" @click="createMessage" :loading="loading"></Button>
        </div>
      </div>
    </div>
  </CustomDialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { PortalUser } from '@/models/user'
import AppToast from '@/toast'
import CustomDialog from '@/components/Dialog/CustomDialog.vue'
import ClientsSelect from '@/components/ClientSelect/ClientsSelect.vue'
import { adminClientDisplay, inflect } from '@/misc'
import MessageApi from '@/api/modules/message'
import { FileUploadUploaderEvent } from 'primevue/fileupload'

@Options({
  components: {
    CustomDialog,
    ClientsSelect,
  },
})
export default class AdminMessageCreateView extends Vue {
  subject: string = ''
  body: string = ''
  attachments: File[] = []

  error: string | null = null
  loading: boolean = false
  loadingClients: boolean = false

  showRecipients: boolean = false

  leftTabClients: PortalUser[] = []
  rightTabClients: PortalUser[] = []

  async beforeMount() {
    await this.init()
  }

  async init() {
    this.loadingClients = true
    try {
      await this.$store.dispatch('admin/getAllClients')
      this.leftTabClients = [...this.clients]
      this.rightTabClients = []
    } finally {
      this.loadingClients = false
    }
  }

  async customUploader(event: FileUploadUploaderEvent) {
    const photo = event.files[0]
    if (photo) {
      this.attachments.push(photo)
    }
  }

  removeAttachment(file: File) {
    const index = this.attachments.indexOf(file, 0)
    if (index > -1) {
      this.attachments.splice(index, 1)
    }
  }

  async createMessage() {
    this.error = null
    this.loading = true
    try {
      const formData = new FormData()
      if (this.rightTabClients.length <= 0) {
        throw Error('Nevybrali ste žiadnych príjemcov')
      }
      for (var recipient of this.rightTabClients) {
        if (recipient.id) {
          formData.append('portalUsers', recipient.id.toString())
        }
      }
      for (var i = 0; i < this.attachments.length; i++) {
        let file = this.attachments[i]
        formData.append('attachments', file)
      }
      formData.append('subject', this.subject.trim())
      formData.append('body', this.body.trim())
      const message = await MessageApi.createMessage(formData)
      this.close()
    } catch (e: any) {
      this.error = e.message
      return
    } finally {
      this.loading = false
    }
  }

  toDisplay(user: PortalUser): string {
    return adminClientDisplay(user)
  }

  async close() {
    if (this.showRecipients) {
      this.showRecipients = false
    } else {
      await this.$router.push({ name: 'admin-messages' })
    }
  }

  async addClient(client: PortalUser) {
    this.rightTabClients.push(client)
    this.leftTabClients = this.leftTabClients.filter((element) => element.id != client.id)
    AppToast.info(null, 'Klient bol pridaný medzi príjemcov správy')
  }
  async removeClient(client: PortalUser) {
    this.rightTabClients = this.rightTabClients.filter((element) => element.id != client.id)
    this.leftTabClients.push(client)
    AppToast.info(null, 'Klient bol odobratý z príjemcov správy')
  }

  get recipientsNum() {
    const n = this.rightTabClients.length
    return inflect(n, 'Žiadni príjemcovia', '1 príjemca', `${n} príjemcovia`, `${n} príjemcov`)
  }

  get clients(): PortalUser[] {
    return this.$store.state.admin.clients
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.custom-w {
  width: 256px;
}
</style>
