import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex items-center justify-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "w-full text-center text-h5 text-text-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ProgressSpinner)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_DataTable, { value: _ctx.admins }, {
          empty: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, _toDisplayString((_ctx.admins ?? []).length <= 0 ? 'Žiadni admini': 'Žiadne nájdené záznamy'), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "email",
              header: "Email",
              sortable: true,
              class: "font-semibold"
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]))
}