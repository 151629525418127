import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mobile)
      ? _renderSlot(_ctx.$slots, "mobile", { key: 0 })
      : _renderSlot(_ctx.$slots, "desktop", { key: 1 })
  ]))
}