<template>
  <CustomDialog title="Vytvoriť agenta" routeOnClose="agents">
    <div class="custom-dialog-wrapper h-full mt-4 px-4">
      <div class="flex flex-col gap-4 overflow-auto">
        <div class="flex flex-col gap-2">
          <label for="name" class="label">Celé meno</label>
          <InputText id="name" placeholder="Zadajte meno a priezvisko" v-model="name" @keydown.enter="createAgent(false)" />
        </div>
        <div class="flex flex-col gap-2">
          <label for="email" class="label">E-mail</label>
          <InputText id="email" placeholder="Zadajte email" v-model="email" @keydown.enter="createAgent(false)" />
        </div>
        <div class="flex flex-col gap-2">
          <label for="phone" class="label">Telefónne číslo</label>
          <InputText id="phone" placeholder="Zadajte telefónne číslo" v-model="phone" @keydown.enter="createAgent(false)" />
        </div>
        <div class="flex flex-col gap-2 h-[calc(212px)] w-full">
          <label for="photo" class="label">Fotka</label>
          <div class="w-full grow flex flex-col gap-4 items-center justify-center">
            <div v-if="photoBase64" class="w-24 h-24 bdr rounded-full">
              <img :src="photoBase64" width="96" height="96" style="object-fit: cover; border-radius: 10000px" />
            </div>
            <div
              :class="{
                'bg-primary-100 w-40 h-40 flex items-center justify-center rounded-full': !photoBase64,
                'flex items-center gap-2 pl-14': photoBase64,
              }"
            >
              <FileUpload
                id="photo"
                mode="basic"
                customUpload
                @uploader="customUploader"
                accept="image/*"
                :maxFileSize="500000"
                :auto="true"
                :chooseLabel="photoBase64 ? 'Zmeniť' : 'Vybrať'"
                :uploadIcon="photoBase64 ? 'pi pi-sync' : 'pi pi-upload'"
              />
              <div v-if="photoBase64">
                <Button icon="pi pi-trash" severity="danger" text rounded aria-label="Cancel" @click="clearPhoto" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <label for="role" class="label">Rola</label>
          <InputText id="role" placeholder="Napr.: Váš portfófio manažér" v-model="role" @keydown.enter="createAgent(false)" />
        </div>
        <div class="flex flex-col gap-2">
          <label for="webname" :class="{ 'text-text-200 text-regular leading-none': notAnClinet, label: !notAnClinet }">Webname</label>
          <InputText :disabled="notAnClinet" id="webname" placeholder="Zadajte Webname" v-model="webname" @keydown.enter="createAgent(false)" />
          <div class="flex items-center gap-2">
            <Checkbox id="isClient" v-model="notAnClinet" :binary="true" />
            <label for="isClient" class="label cursor-pointer" @click="notAnClinet = !notAnClinet">Agent nemá webname <span class="text-text-300">(nie je klient)</span></label>
          </div>
        </div>
        <div class="w-full h-1 shrink-0"></div>
      </div>
      <div v-if="error" class="mt-4">
        <InlineMessage severity="error" class="w-full"> {{ error }} </InlineMessage>
      </div>
      <div class="mt-4">
        <Button label="Vytvoriť agenta" @click="createAgent(false)" :loading="loading"></Button>
      </div>
    </div>
  </CustomDialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import UserApi from '@/api/modules/user'
import { PortalUser } from '@/models/user'
import AppToast from '@/toast'
import CustomDialog from '@/components/Dialog/CustomDialog.vue'
import { Error409 } from '@/errors/axiosErrors'
import { getBase64 } from '@/misc'
import { FileUploadUploaderEvent } from 'primevue/fileupload'
import { useConfirm } from 'primevue/useconfirm'

@Options({
  components: {
    CustomDialog,
  },
})
export default class AgentCreateView extends Vue {
  error: string | null = null

  agent?: PortalUser
  loading: boolean = false

  name: string = ''
  email: string = ''
  phone: string = ''
  photo: File | null | undefined = null
  photoBase64: string | null = null
  role: string = ''
  notAnClinet: boolean = false
  webname: string | null | undefined = null

  warningVisible: boolean = false

  confirm = useConfirm()

  async customUploader(event: FileUploadUploaderEvent) {
    this.photo = event.files[0]
    if (this.photo) {
      this.photoBase64 = await getBase64(this.photo)
    }
  }

  async createAgent(force: boolean = false) {
    this.error = null
    this.loading = true
    try {
      const formData = new FormData()
      formData.append('name', this.name.trim())
      formData.append('email', this.email.trim())
      formData.append('phone', this.phone.trim())
      if (this.photo) formData.append('photo', this.photo)
      formData.append('role', this.role.trim())
      if (this.webname) formData.append('webname', this.webname.trim())
      const portalUser = await UserApi.registerAgent(formData, force)
      const agents = this.agents
      const index = agents.findIndex((element) => element.id === portalUser.id)
      if (index < 0) {
        agents.push(portalUser)
        this.$store.commit('setAgents', agents)
        AppToast.info(null, 'Agent bol vytvorený')
      }
      this.close()
    } catch (e: any) {
      if (e instanceof Error409) {
        const warning = `Užívateľ s e-mailom: <b>${this.email}</b> už existuje. Chcete mu priradiť rolu agenta?`
        this._confirm(warning)
        return
      }
      this.error = e.message
      return
    } finally {
      this.loading = false
    }
  }

  private _confirm(warning: string) {
    this.confirm.require({
      message: warning,
      header: 'Konflikt',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      accept: async () => {
        await this.createAgent(true)
      },
    })
  }

  clearPhoto() {
    this.photoBase64 = null
    this.photo = null
  }

  close() {
    this.$router.push({ name: 'agents' })
  }

  get agents(): PortalUser[] {
    return this.$store.state.admin.agents
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
