<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
})
export default class StrategiesView extends Vue {
  beforeMount() {
    this.init()
  }

  init() {}
}
</script>

<style scoped lang="scss"></style>
