<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
})
export default class MessagesView extends Vue {}
</script>

<style scoped lang="scss"></style>
