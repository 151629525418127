<template>
  <div v-if="loading" class="w-full flex items-center justify-center">
    <ProgressSpinner></ProgressSpinner>
  </div>
  <div v-else>
    <Responsive>
      <template #mobile>
        <div class="pb-40">
          <DataView :value="displayClients" dataKey="id" paginator :alwaysShowPaginator="false" :rows="10" paginatorTemplate="PrevPageLink PageLinks NextPageLink">
            <template v-if="clients.length > 1" #header>
              <div class="flex justify-between gap-4 items-center">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" @update:modelValue="(value?: string) => search(value)" placeholder="Vyhľadať klienta..." />
                </span>
                <div v-if="isAdmin" class="w-fit">
                  <DailySyncStrategiesButton />
                </div>
              </div>
            </template>
            <template #list="slotProps">
              <AdminPreview :title="slotProps.data?.omniumUser?.fullName" :subtitle="slotProps.data?.email">
                <template #body>
                  <div v-if="slotProps.data?.omniumUser?.agent">Agent: {{ slotProps.data?.omniumUser?.agent?.name }} ({{ slotProps.data?.omniumUser?.agent?.email }})</div>
                  <div v-else>
                    <div>Agent: nepriradený</div>
                  </div>
                </template>
                <template #actions>
                  <div class="flex gap-2">
                    <Button v-tooltip.bottom="'Portfólio klienta'" icon="pi pi-chart-line" @click="openClientsPortfolio(slotProps.data)" />
                    <ReSyncStrategiesButton v-if="isAdmin" :portalUser="slotProps.data"></ReSyncStrategiesButton>
                    <Button v-tooltip.bottom="'Upraviť klienta'" text icon="pi pi-pencil" @click="openUpdateClient(slotProps.data)" />
                  </div>
                </template>
              </AdminPreview>
            </template>
            <template #empty>
              <div class="px-4 py-6 w-full text-center text-h5 text-text-500">
                {{ (clients ?? []).length <= 0 ? 'Žiadni klienti' : 'Žiadne nájdené záznamy' }}
              </div>
            </template>
          </DataView>
        </div>
      </template>
      <template #desktop>
        <DataTable
          v-model:filters="filters"
          :value="clients"
          dataKey="id"
          :globalFilterFields="['omniumUser.fullName', 'email']"
          @filter="(value) => updateFilters(value)"
          paginator
          :rows="10"
          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
          :alwaysShowPaginator="false"
        >
          <template v-if="clients.length > 1" #header>
            <div class="flex justify-between gap-4 items-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Vyhľadať klienta..." />
              </span>
              <div v-if="isAdmin" class="w-fit">
                <DailySyncStrategiesButton />
              </div>
            </div>
          </template>
          <Column field="omniumUser.fullName" header="Meno" :sortable="true">
            <template #body="slotProps">
              <div v-if="slotProps.data?.omniumUser?.fullName" class="font-semibold">
                {{ slotProps.data?.omniumUser?.fullName }}
              </div>
              <div v-else>
                <div>-</div>
              </div>
            </template>
          </Column>
          <Column field="email" header="Email" :sortable="true" class="text-text-700"></Column>
          <Column field="omniumUser.agent.name" header="Agent" :sortable="true" class="text-text-700">
            <template #body="slotProps">
              <div v-if="slotProps.data?.omniumUser?.agent">
                <div class="flex gap-2 items-center leading-none">
                  <div>{{ slotProps.data?.omniumUser?.agent?.name }}</div>
                  <div>({{ slotProps.data?.omniumUser?.agent?.email }})</div>
                </div>
              </div>
              <div v-else>
                <div>-</div>
              </div>
            </template>
          </Column>
          <Column header="">
            <template #body="slotProps">
              <div class="flex gap-2">
                <Button v-tooltip.bottom="'Portfólio klienta'" icon="pi pi-chart-line" @click="openClientsPortfolio(slotProps.data)" />
                <ReSyncStrategiesButton v-if="isAdmin" :portalUser="slotProps.data"></ReSyncStrategiesButton>
                <Button v-tooltip.bottom="'Upraviť klienta'" text icon="pi pi-pencil" @click="openUpdateClient(slotProps.data)" />
              </div>
            </template>
          </Column>
          <template #empty>
            <div class="w-full text-center text-h5 text-text-500">
              {{ (clients ?? []).length <= 0 ? 'Žiadni klienti' : 'Žiadne nájdené záznamy' }}
            </div>
          </template>
        </DataTable>
      </template>
    </Responsive>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { PortalUser } from '@/models/user'
import ReSyncStrategiesButton from '@/components/Buttons/ReSyncStrategiesButton.vue'
import DailySyncStrategiesButton from '@/components/Buttons/DailySyncStrategiesButton.vue'
import { FilterMatchMode } from 'primevue/api'
import Responsive from '@/components/Responsive.vue'
import AdminPreview from '@/components/Preview/AdminPreview.vue'
import { DataTableFilterEvent } from 'primevue/datatable'

@Options({
  components: {
    ReSyncStrategiesButton,
    DailySyncStrategiesButton,
    Responsive,
    AdminPreview,
  },
})
export default class ClientsView extends Vue {
  loading: boolean = false

  displayClients: PortalUser[] = []

  filters = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  }

  async beforeMount() {
    await this.init()
  }

  async init() {
    this.loading = true
    try {
      await this.$store.dispatch('admin/getAllClients')
      this.displayClients = [...this.clients]
    } finally {
      this.loading = false
    }
  }

  openClientsPortfolio(user: PortalUser) {
    if (user.id) {
      this.$router.push({ name: 'client', params: { id: user.id } })
    }
  }

  openUpdateClient(user: PortalUser) {
    if (user.id) {
      this.$router.push({ name: 'client-edit', params: { id: user.id } })
    }
  }

  search(value?: string) {
    if (!value) {
      this.displayClients = [...this.clients]
      return
    }
    if (value.length > 0) {
      this.displayClients = this.clients.filter((element) => {
        const email = element.email?.toLowerCase()
        const name = element.omniumUser?.fullName?.toLowerCase()
        const val = value.toLocaleLowerCase()
        return name?.includes(val) || email?.includes(val)
      })
    }
  }

  updateFilters(value: DataTableFilterEvent) {
    this.displayClients = [...value.filteredValue]
  }

  get clients(): PortalUser[] {
    return this.$store.state.admin.clients
  }

  get isAdmin(): boolean {
    return this.$store.getters['user/isAdmin']
  }
}
</script>

<style scoped lang="scss"></style>
