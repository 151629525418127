<template>
  <div class="text-regular font-medium">
    Omnium Capital a.s. o.c.p.<br />
    +421 909 123 456<br />
    info@omniumcapital.sk<br />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Contact extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
