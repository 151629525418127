<template>
  <vue100vh :css="{ height: '100rvh' }">
    <router-view />
  </vue100vh>
  <Toast position="bottom-center" />
  <DynamicDialog />
  <ConfirmDialog>
    <template #message="slotProps">
      <div class="flex items-center gap-4 leading-none">
        <div class="text-secondary-600"><i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i></div>
        <div class="text-regular" v-html="slotProps.message.message"></div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script lang="ts">
import vue100vh from 'vue-100vh'
import Config from './config'
import { Error401 } from './errors/axiosErrors'
import router from './router'
import { store } from './store'
import AppToast from './toast'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { vue100vh },
  mounted() {
    this.determineMobile()
    setInterval(async () => {
      await this.checkIfLoggedIn()
    }, Config.LOGOUT_TIME)

    if (store.getters['user/isLoggedIn']) {
      this.$store.dispatch('portal/getUnseenMessageCount')
    }
  },
  created() {
    window.addEventListener('resize', this.determineMobile)
    window.addEventListener('orientationchange', this.determineMobile)
  },
  destroyed() {
    window.removeEventListener('resize', this.determineMobile)
    window.removeEventListener('orientationchange', this.determineMobile)
  },
  methods: {
    determineMobile() {
      const width = document.documentElement.clientWidth
      if (width) {
        store.commit('responsive/setResponsiveMode', width)
      }
    },

    async checkIfLoggedIn() {
      const isIdle = store.state.idleVue.isIdle
      const isLoggedIn = store.getters['user/isLoggedIn']
      if (isLoggedIn && isIdle) {
        store.commit('portal/clear')
        await store.dispatch('user/logout')
        router.push({ name: 'login' })
        AppToast.info(null, 'Kvôli nečinnosti ste boli odhlásený')
      } else if (!isIdle && isLoggedIn) {
        try {
          await store.dispatch('user/tokenRefresh')
        } catch (e) {
          if (e instanceof Error401) {
            store.commit('portal/clear')
            await store.dispatch('user/logout')
            router.push({ name: 'login' })
            AppToast.info(null, 'Platnosť prihlásenia vypršala')
            return
          }
          throw e
        }
      }
    },
  },
})
</script>

<style lang="scss">
body {
  font-family: var(--font-family);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
