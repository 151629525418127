<template>
  <Detail :hideHeader="true">
    <div class="w-full h-full flex flex-col">
      <div class="text-h4 font-bold my-5">Vytvorte si nové heslo</div>
      <div class="text-regular">Vytvorte si heslo, ktorým sa budete prihlasovať do klientského portálu.</div>
      <div class="mt-6">
        <CreatePassword :email="email" :onCreate="registerClient" />
      </div>
    </div>
  </Detail>
</template>

<script lang="ts">
import CreatePassword from '@/components/Intro/CreatePassword.vue'
import Detail from '@/components/Layout/Detail.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Detail,
    CreatePassword,
  },
})
export default class EnterPasswordView extends Vue {
  webname!: string
  password!: string

  mounted() {
    this.webname = this.$route.params.webname as string
    this.password = this.$route.params.password as string
  }

  async registerClient(portalPassword: string) {
    await this.$store.dispatch('user/registerClient', {
      webname: this.webname,
      password: this.password,
      portalPassword,
    })
    await this.$store.dispatch('user/login', {
      email: this.email?.toLowerCase(),
      password: portalPassword,
    })
    this.$router.push({ name: 'success' })
  }

  get email(): string | null | undefined {
    return this.$store.state.user.portalUser.email
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul {
  list-style-type: disc;
}
</style>
