import { Message } from '@/models/message'
import Api from '../api'

export default class MessageApi {
  static async getAllMessages(): Promise<Message[]> {
    const res = await Api.get('portal/get-all-messages/')
    return res.data
  }

  static async getAllPortlMessages(): Promise<Message[]> {
    const res = await Api.get('portal/get-all-portal-messages/')
    return res.data
  }

  static async getMessage(id: number): Promise<Message> {
    const res = await Api.get('portal/get-message/' + id)
    return res.data
  }

  static async getUnseenMessageCount(): Promise<number> {
    const res = await Api.get('portal/get-unseen-message-count/')
    return res.data
  }

  static async markAllMessagesSeen(): Promise<void> {
    await Api.put('portal/mark-all-messages-seen/')
  }

  static async markMessageSeen(id: number): Promise<void> {
    await Api.put('portal/mark-message-seen/' + id)
  }

  static async deleteMessage(id: number): Promise<void> {
    await Api.delete('portal/delete-message/' + id)
  }

  static async getAttachment(id: number): Promise<Blob> {
    const res = await Api.get('portal/get-attachment/' + id, 'blob')
    return res.data
  }

  static async createMessage(message: FormData): Promise<Message> {
    const res = await Api.post('portal/create-message/', message)
    return res.data
  }
}
