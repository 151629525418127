<template>
  <div class="flex flex-col font-semibold text-regular w-full h-fit text-black">
    <a href="https://www.omniumcapital.sk/produkty-a-sluzby" target="_blank" rel="noreferrer" class="hover:underline">
      <div class="flex gap-3 py-2">
        <div><i class="pi pi-plus"></i></div>
        <div>Nové portfólio</div>
      </div>
    </a>
    <div v-if="showIban" class="flex gap-3 py-2 cursor-pointer hover:underline" @click="openIBAN()">
      <div><i class="pi pi-credit-card"></i></div>
      <div>Zaslať prostriedky na investovanie</div>
    </div>
    <a href="https://www.omniumcapital.sk/produkty-a-sluzby" target="_blank" rel="noreferrer" class="hover:underline">
      <div class="flex gap-3 py-2">
        <div><i class="pi pi-ellipsis-h"></i></div>
        <div>
          <div>Ponuka služieb a portfólií</div>
          <div class="flex font-normal gap-1">
            <div>omniumcapital.sk</div>
            <div class="opacity-80">
              <i class="pi pi-arrow-up-right" style="font-size: 0.6rem"></i>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <Dialog v-model:visible="ibanDialogVisible" modal header="Zaslať prostriedky na investovanie" :draggable="false" :style="{ 'max-width': '566px', width: '100%' }">
    <div class="flex justify-center w-full">
      <div class="w-[calc(300px)]">
        <Bank :showDescription="false"></Bank>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Bank from '../Profile/Bank.vue'

@Options({
  components: {
    Bank,
  },
  props: {
    showIban: {
      type: Boolean,
      required: false,
      nullable: false,
      default: true,
    },
  },
})
export default class StrategyActions extends Vue {
  showIban!: boolean

  ibanDialogVisible: boolean = false

  openIBAN() {
    this.ibanDialogVisible = true
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
