import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col items-center h-full"
}
const _hoisted_2 = { class: "flex flex-col w-full h-12 bg-primary-100 text-primary-900 justify-center items-center flex-shrink-0" }
const _hoisted_3 = { class: "px-4 w-[calc(375px)]" }
const _hoisted_4 = { class: "text-h5 font-semibold" }
const _hoisted_5 = { class: "w-[calc(375px)] px-2 flex flex-col items-center pb-4 grow" }
const _hoisted_6 = { class: "w-full flex flex-col items-center h-full" }
const _hoisted_7 = { class: "flex flex-col w-full h-12 bg-primary-100 text-primary-900 justify-center items-center flex-shrink-0" }
const _hoisted_8 = { class: "px-4 w-[calc(375px)]" }
const _hoisted_9 = { class: "text-h5 font-semibold" }
const _hoisted_10 = { class: "w-[calc(375px)] px-2 flex flex-col items-center pb-4 grow" }
const _hoisted_11 = { class: "w-full flex justify-center h-full" }
const _hoisted_12 = {
  style: {"max-width":"1400px"},
  class: "h-full w-full pt-6 flex flex-col"
}
const _hoisted_13 = {
  key: 0,
  class: "pb-8 w-full text-h2 font-semibold px-6 leading-none h-[calc(60px)]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Responsive = _resolveComponent("Responsive")!

  return (_ctx.ignoreResponsive)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "heading")
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
    : (_openBlock(), _createBlock(_component_Responsive, { key: 1 }, {
        mobile: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _renderSlot(_ctx.$slots, "heading")
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ]),
        desktop: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              (_ctx.hasHeadingSlot)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _renderSlot(_ctx.$slots, "heading")
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["w-full", { 'h-[calc(100%-60px)]': _ctx.hasHeadingSlot, 'h-full': !_ctx.hasHeadingSlot }])
              }, [
                _renderSlot(_ctx.$slots, "default")
              ], 2)
            ])
          ])
        ]),
        _: 3
      }))
}