import { Agent, OmniumUser, PortalUser } from '@/models/user'
import Api from '../api'

export default class UserApi {
  static async login(email: string, password: string): Promise<{ access: string; refresh: string }> {
    const res = await Api.post('users/login/', { email, password })
    return res.data
  }

  static async logout(): Promise<void> {
    await Api.get('users/logout/')
  }

  static async tokenRefresh(refreshToken: string): Promise<{ access: string; refresh: string }> {
    const res = await Api.post('users/token-refresh/', { refresh: refreshToken })
    return res.data
  }

  static async resetPassword(email: string): Promise<void> {
    await Api.post('users/reset-password/', { email })
  }

  static async changePassword(oldPassword: string, password: string): Promise<void> {
    await Api.post('users/change-password/', { oldPassword, password })
  }

  static async loginOmniumUser(webname: string, password: string): Promise<{ registered: boolean; email: string }> {
    const res = await Api.post('users/login-omnium-user/', { webname, password })
    return res.data
  }

  static async registerClient(webname: string, password: string, portalPassword: string): Promise<void> {
    await Api.post('users/register-client/', { webname, password, portalPassword })
  }

  static async registerAgent(registerAgent: FormData, force: boolean = false): Promise<PortalUser> {
    const res = force
      ? await Api.post('users/register-agent-force/', registerAgent)
      : await Api.post('users/register-agent/', registerAgent)
    return res.data
  }

  static async getCurrentUser(): Promise<PortalUser> {
    const res = await Api.get('users/current-user')
    return res.data
  }

  static async getUser(id: number): Promise<PortalUser> {
    const res = await Api.get('users/user/' + id)
    return res.data
  }

  static async getAllClients(): Promise<PortalUser[]> {
    const res = await Api.get('users/get-all-clients')
    return res.data
  }

  static async getAllAgents(): Promise<PortalUser[]> {
    const res = await Api.get('users/get-all-agents')
    return res.data
  }

  static async getAllAdmins(): Promise<PortalUser[]> {
    const res = await Api.get('users/get-all-admins')
    return res.data
  }

  static async updateAgent(agent: Agent, updateAgent: FormData): Promise<PortalUser> {
    const res = await Api.put('users/update-agent/' + agent.id, updateAgent)
    return res.data
  }

  static async updateAgentClients(agent: Agent, omniumUsers: PortalUser[]): Promise<void> {
    await Api.put('users/update-agent-clients/' + agent.id, {
      omniumUsers: omniumUsers.map((element) => element.omniumUser?.kidc),
    })
  }

  static async deleteAgent(agent: Agent): Promise<void> {
    await Api.delete('users/delete-agent/' + agent.id)
  }

  static async updatePortalUser(portalUser: PortalUser, email: string): Promise<PortalUser> {
    const res = await Api.put('users/update-portal-user/' + portalUser.id, {
      email,
    })
    return res.data
  }
}
