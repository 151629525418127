<template>
  <div class="text-text-500 text-regular w-full flex flex-col items-center">
    <img :src="require('@/assets/piggy.svg')" width="330" />
    <div class="text-h6 text-text-500 mt-6">Aktuálne nemáte žiadne porfólio</div>
    <div class="mt-6">
      <a href="https://www.omniumcapital.sk/produkty-a-sluzby" target="_blank" rel="noreferrer">
        <Button label="Viac o portfóliách"></Button>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class NoStrategy extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
