import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

import AdminView from '../views/admin/AdminView.vue'
import AdminSectionsView from '../views/admin/sections/AdminSectionsView.vue'
import ClientsView from '../views/admin/sections/ClientsView.vue'
import AgentsView from '../views/admin/sections/AgentsView.vue'
import AdminsView from '../views/admin/sections/AdminsView.vue'
import AdminMessagesView from '../views/admin/sections/AdminMessagesView.vue'

import DetailView from '../views/admin/details/DetailView.vue'
import ClientDetailView from '../views/admin/details/ClientDetailView.vue'
import ClientEditView from '../views/admin/details/ClientEditView.vue'
import AgentCreateView from '../views/admin/details/AgentCreateView.vue'
import AgentEditView from '../views/admin/details/AgentEditView.vue'
import AgentManageView from '../views/admin/details/AgentManageView.vue'
import AdminMessageView from '../views/admin/details/MessageView.vue'
import AdminMessageCreateView from '../views/admin/details/MessageCreateView.vue'

import HomeView from '../views/home/HomeView.vue'
import StrategiesView from '../views/home/sections/strategies/StrategiesView.vue'
import PortfolioView from '../views/home/sections/strategies/PortfolioView.vue'
import StrategyDetailView from '../views/home/sections/strategies/StrategyDetailView.vue'
import UserView from '../views/home/sections/user/UserView.vue'
import UserProfileView from '../views/home/sections/user/UserProfileView.vue'
import ChangePasswordView from '../views/home/sections/user/ChangePasswordView.vue'
import MessagesView from '../views/home/sections/messages/MessagesView.vue'
import AllMessagesView from '../views/home/sections/messages/AllMessagesView.vue'
import MessageDetailView from '../views/home/sections/messages/MessageDetailView.vue'
import ContactView from '../views/home/sections/ContactView.vue'
import AuthView from '../views/auth/AuthView.vue'
import LoginView from '../views/auth/sections/LoginView.vue'
import ResetPasswordView from '../views/auth/sections/ResetPasswordView.vue'
import ResetPasswordSuccessView from '../views/auth/sections/ResetPasswordSuccessView.vue'
import ResetPasswordExpiredView from '../views/auth/sections/ResetPasswordExpiredView.vue'
import UpdatePasswordView from '../views/auth/sections/UpdatePasswordView.vue'
import UpdatePasswordSuccessView from '../views/auth/sections/UpdatePasswordSuccessView.vue'
import OnboardingView from '../views/onboarding/OnboardingView.vue'
import LandingPageView from '../views/onboarding/sections/LandingPageView.vue'
import EnterPasswordView from '../views/onboarding/sections/EnterPasswordView.vue'
import SuccessView from '../views/onboarding/sections/SuccessView.vue'
import AlreadyActiveView from '../views/onboarding/sections/AlreadyActiveView.vue'
import { store } from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'strategies',
        name: 'strategies',
        component: StrategiesView,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'portfolio',
            name: 'portfolio',
            component: PortfolioView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'strategy-detail/:code',
            name: 'strategy-detail',
            component: StrategyDetailView,
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
        ],
      },
      {
        path: '/user',
        name: 'user',
        component: UserView,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'profile',
            name: 'profile',
            component: UserProfileView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'change-password',
            name: 'change-password',
            component: ChangePasswordView,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: '/messages',
        name: 'messages',
        component: MessagesView,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'all-messages',
            name: 'all-messages',
            component: AllMessagesView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'message-detail/:id',
            name: 'message-detail',
            component: MessageDetailView,
            meta: {
              requiresAuth: true,
            },
            props: true,
          },
        ],
      },
      {
        path: '/contact',
        name: 'contact',
        component: ContactView,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginView,
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: ResetPasswordView,
      },
      {
        path: 'reset-password-success',
        name: 'reset-password-success',
        component: ResetPasswordSuccessView,
      },
      {
        path: 'reset-password-expired',
        name: 'reset-password-expired',
        component: ResetPasswordExpiredView,
      },
      {
        path: 'update-password/:refreshToken',
        name: 'update-password',
        props: true,
        component: UpdatePasswordView,
      },
      {
        path: 'update-password-success',
        name: 'update-password-success',
        component: UpdatePasswordSuccessView,
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: OnboardingView,
    children: [
      {
        path: 'landing',
        name: 'landing',
        component: LandingPageView,
      },
      {
        path: 'password/:webname/:password',
        name: 'password',
        props: true,
        component: EnterPasswordView,
      },
      {
        path: 'success',
        name: 'success',
        component: SuccessView,
      },
      {
        path: 'active',
        name: 'active',
        component: AlreadyActiveView,
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    meta: {
      requiresAuth: true,
      requiresStaff: true,
    },
    children: [
      {
        path: 'sections',
        name: 'admin-sections',
        component: AdminSectionsView,
        meta: {
          requiresAuth: true,
          requiresStaff: true,
        },
        children: [
          {
            path: 'clients',
            name: 'clients',
            component: ClientsView,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
          {
            path: 'agents',
            name: 'agents',
            component: AgentsView,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
          {
            path: 'admins',
            name: 'admins',
            component: AdminsView,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
          {
            path: 'messages',
            name: 'admin-messages',
            component: AdminMessagesView,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
        ],
      },
      {
        path: 'detail',
        name: 'admin-detail',
        component: DetailView,
        meta: {
          requiresAuth: true,
          requiresStaff: true,
        },
        children: [
          {
            path: 'client/:id',
            name: 'client',
            component: ClientDetailView,
            props: true,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
          {
            path: 'client-edit/:id',
            name: 'client-edit',
            component: ClientEditView,
            props: true,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
          {
            path: 'agent-create',
            name: 'agent-create',
            component: AgentCreateView,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
          {
            path: 'agent-edit/:id',
            name: 'agent-edit',
            props: true,
            component: AgentEditView,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
          {
            path: 'agent-manage-clients/:id',
            name: 'agent-manage-clients',
            props: true,
            component: AgentManageView,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
          {
            path: 'admin-message/:id',
            name: 'admin-message',
            props: true,
            component: AdminMessageView,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
          {
            path: 'admin-message-create',
            name: 'admin-message-create',
            component: AdminMessageCreateView,
            meta: {
              requiresAuth: true,
              requiresStaff: true,
            },
          },
        ],
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters['user/isLoggedIn']) {
      next({ name: 'login' })
      return
    } else {
      if (to.matched.some((record) => record.meta.requiresStaff)) {
        if (!store.getters['user/isStaff']) {
          next({ name: 'portfolio' })
          return
        } else {
          next()
          return
        }
      }
      next()
      return
    }
  } else {
    next()
    return
  }
})

export default router
