<template>
  <div v-if="loading" class="w-full flex items-center justify-center">
    <ProgressSpinner></ProgressSpinner>
  </div>
  <div v-else>
    <Responsive>
      <template #mobile>
        <div class="w-full flex justify-end py-2 bdr px-4">
          <Button label="Pridať agenta" icon="pi pi-plus" outlined @click="openCreateAgent()"></Button>
        </div>
        <div class="pb-40">
          <DataView :value="agents" dataKey="id">
            <template #list="slotProps">
              <AdminPreview :title="slotProps.data.agentUser?.name" :subtitle="slotProps.data.email">
                <template #actions>
                  <div class="flex gap-2">
                    <Button v-tooltip.bottom="'Manažment klientov'" icon="pi pi-users" @click="openManageAgentClients(slotProps.data)" />
                    <Button v-tooltip.bottom="'Upraviť agenta'" icon="pi pi-pencil" text @click="openUpdateAgent(slotProps.data)" />
                    <DeleteAgentButton :agent="slotProps.data" />
                  </div>
                </template>
              </AdminPreview>
            </template>
            <template #empty>
              <div class="px-4 py-6 w-full text-center text-h5 text-text-500">
                {{ (agents ?? []).length <= 0 ? 'Žiadni agenti': 'Žiadne nájdené záznamy' }}
              </div>
            </template>
          </DataView>
        </div>
      </template>
      <template #desktop>
        <div class="w-full flex justify-end py-4">
          <Button label="Pridať agenta" icon="pi pi-plus" outlined @click="openCreateAgent()"></Button>
        </div>
        <DataTable :value="agents">
          <Column field="agentUser.name" header="Meno" :sortable="true" class="font-semibold"></Column>
          <Column field="email" header="Email" :sortable="true" class="text-text-700"></Column>
          <Column header="">
            <template #body="slotProps">
              <div class="flex gap-2">
                <Button v-tooltip.bottom="'Manažment klientov'" icon="pi pi-users" @click="openManageAgentClients(slotProps.data)" />
                <Button v-tooltip.bottom="'Upraviť agenta'" icon="pi pi-pencil" text @click="openUpdateAgent(slotProps.data)" />
                <DeleteAgentButton :agent="slotProps.data" />
              </div>
            </template>
          </Column>
          <template #empty>
            <div class="w-full text-center text-h5 text-text-500">
              {{ (agents ?? []).length <= 0 ? 'Žiadni agenti': 'Žiadne nájdené záznamy' }}
            </div>
          </template>
        </DataTable>
      </template>
    </Responsive>
  </div>
</template>

<script lang="ts">
import { PortalUser } from '@/models/user'
import { Options, Vue } from 'vue-class-component'
import { useConfirm } from 'primevue/useconfirm'
import DeleteAgentButton from '@/components/Buttons/DeleteAgentButton.vue'
import Responsive from '@/components/Responsive.vue'
import AdminPreview from '@/components/Preview/AdminPreview.vue'

@Options({
  components: {
    DeleteAgentButton,
    Responsive,
    AdminPreview,
  },
})
export default class AgentsView extends Vue {
  loading: boolean = false

  confirm = useConfirm()

  async beforeMount() {
    await this.init()
  }

  async init() {
    this.loading = true
    try {
      await this.$store.dispatch('admin/getAllAgents')
    } finally {
      this.loading = false
    }
  }

  openCreateAgent() {
    this.$router.push({ name: 'agent-create' })
  }

  openUpdateAgent(agent: PortalUser) {
    const id = agent.id
    if (id) {
      this.$router.push({ name: 'agent-edit', params: { id } })
    }
  }

  openManageAgentClients(agent: PortalUser) {
    const id = agent.id
    if (id) {
      this.$router.push({ name: 'agent-manage-clients', params: { id } })
    }
  }

  get agents(): PortalUser[] {
    return this.$store.state.admin.agents
  }
}
</script>

<style scoped lang="scss">
.bdr {
  border-bottom: 1px solid #d9d9d9;
}
</style>
