<template>
  <CustomDialog title="Upraviť agenta" routeOnClose="agents">
    <div class="h-full mt-4 custom-dialog-wrapper px-4">
      <div v-if="loadingAgentInfo">
        <ProgressSpinner />
      </div>
      <div v-else class="w-full flex flex-col gap-4 overflow-auto">
        <div class="flex flex-col gap-2">
          <label for="name" class="label">Celé meno</label>
          <InputText id="name" placeholder="Zadajte meno a priezvisko" v-model="name" @keydown.enter="updateAgent()" />
        </div>
        <div class="flex flex-col gap-2">
          <label for="email" class="label">E-mail</label>
          <InputText id="email" placeholder="Zadajte email" v-model="email" @keydown.enter="updateAgent()" />
        </div>
        <div class="flex flex-col gap-2">
          <label for="phone" class="label">Telefónne číslo</label>
          <InputText id="phone" placeholder="Zadajte telefónne číslo" v-model="phone" @keydown.enter="updateAgent()" />
        </div>
        <div class="flex flex-col gap-2 h-[calc(212px)] w-full">
          <label for="photo" class="label">Fotka</label>
          <div class="w-full grow flex flex-col gap-4 items-center justify-center">
            <div v-if="photoBase64" class="w-24 h-24 bdr rounded-full">
              <img :src="photoBase64" width="96" height="96" style="object-fit: cover; border-radius: 10000px" />
            </div>
            <div
              :class="{
                'bg-primary-100 w-40 h-40 flex items-center justify-center rounded-full': !photoBase64,
                'flex items-center gap-2 pl-14': photoBase64,
              }"
            >
              <FileUpload
                id="photo"
                mode="basic"
                customUpload
                @uploader="customUploader"
                accept="image/*"
                :maxFileSize="500000"
                :auto="true"
                :chooseLabel="photoBase64 ? 'Zmeniť' : 'Vybrať'"
                :uploadIcon="photoBase64 ? 'pi pi-sync' : 'pi pi-upload'"
              />
              <div v-if="photoBase64">
                <Button icon="pi pi-trash" severity="danger" text rounded aria-label="Cancel" @click="clearPhoto" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <label for="role" class="label">Rola</label>
          <InputText id="role" placeholder="Napr.: Váš portfófio manažér" v-model="role" @keydown.enter="updateAgent()" />
        </div>
        <div class="w-full h-1 shrink-0"></div>
      </div>
      <div v-if="error" class="mt-4">
        <InlineMessage severity="error" class="w-full"> {{ error }} </InlineMessage>
      </div>
      <div class="mt-4">
        <Button label="Potvrdiť" @click="updateAgent()" :loading="loading"></Button>
      </div>
    </div>
  </CustomDialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import UserApi from '@/api/modules/user'
import { PortalUser } from '@/models/user'
import AppToast from '@/toast'
import CustomDialog from '@/components/Dialog/CustomDialog.vue'
import { getBase64 } from '@/misc'
import { FileUploadUploaderEvent } from 'primevue/fileupload'
import PortalApi from '@/api/modules/portal'

@Options({
  components: {
    CustomDialog,
  },
  props: ['id'],
})
export default class AgentEditView extends Vue {
  id!: number
  agent?: PortalUser

  name!: string
  email!: string
  phone!: string
  photo: File | null | undefined = null
  photoBase64: string | null = null
  role!: string

  error: string | null = null
  warningVisible: boolean = false
  loading: boolean = false
  loadingAgentInfo: boolean = false

  async beforeMount() {
    await this._init()
  }

  async _init() {
    this.loadingAgentInfo = true
    this.agent = this.agents.find((element) => element.id == this.id)
    if (this.agent === undefined || this.agent === null) {
      await this.close()
    }
    try {
      const portalUser = this.agent
      const agent = this.agent?.agentUser!
      this.name = agent.name ?? ''
      this.email = portalUser?.email ?? ''
      this.phone = agent.phone ?? ''
      if (agent.photo) {
        this.photo = new File([await PortalApi.getAgentPhoto(agent.id!)], agent.photo)
        this.photoBase64 = await getBase64(this.photo)
      }
      this.role = agent.role ?? ''
    } catch (e) {
      this.close()
      throw e
    } finally {
      this.loadingAgentInfo = false
    }
  }

  async customUploader(event: FileUploadUploaderEvent) {
    this.photo = event.files[0]
    if (this.photo) {
      this.photoBase64 = await getBase64(this.photo)
    }
  }

  async updateAgent() {
    this.error = null
    this.loading = true

    try {
      const formData = new FormData()
      formData.append('name', this.name.trim())
      formData.append('email', this.email.trim())
      formData.append('phone', this.phone.trim())
      formData.append('photo', this.photo ?? '')
      formData.append('role', this.role.trim())
      const portalUser = await UserApi.updateAgent(this.agent?.agentUser!, formData)
      const agents = this.agents
      const index = agents.findIndex((element) => element.id === portalUser.id)
      if (index >= 0) {
        agents[index] = portalUser
        this.$store.commit('setAgents', agents)
        AppToast.info(null, 'Agent bol upravený')
      }
      this.close()
    } catch (e: any) {
      this.error = e.message
      return
    } finally {
      this.loading = false
    }
  }

  async close() {
    await this.$router.push({ name: 'agents' })
  }

  clearPhoto() {
    this.photoBase64 = null
    this.photo = null
  }

  get agents(): PortalUser[] {
    return this.$store.state.admin.agents
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
