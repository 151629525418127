<template>
  <div class="w-full h-full flex flex-col items-center">
    <div class="w-full h-[calc(56px)] flex flex-col bg-primary-900 justify-end shrink-0 fixed z-50 overflow-x-auto">
      <div class="w-full flex justify-between items-center gap-1">
        <router-link :to="{ name: 'clients' }" class="px-2 w-fit h-[calc(56px)] flex items-center gap-4 cursor-pointer">
          <img :src="require('@/assets/logo.svg')" width="136" />
          <div class="text-white">Administrácia</div>
        </router-link>
        <div class="grow h-full">
          <Responsive>
            <template #mobile>
              <div class="flex items-center h-[calc(56px)] w-full justify-end">
                <div class="w-fit h-14 px-4 flex gap-2 items-center cursor-pointer text-white custom-hover" @click="menuVisible = true">
                  <i class="pi pi-user" style="font-size: 0.75rem"></i>
                  <i class="pi pi-chevron-down" style="font-size: 0.75rem"></i>
                </div>
              </div>
              <Sidebar v-model:visible="menuVisible" position="top" style="height: auto">
                <template #container>
                  <div class="h-14 flex items-center gap-2 justify-between">
                    <div class="px-2 w-fit h-[calc(56px)] flex items-center gap-4 cursor-pointer">
                      <img :src="require('@/assets/logo.svg')" width="136" />
                      <div class="text-white text-regular font-extralight">Administrácia</div>
                    </div>
                    <div class="w-fit h-14 px-4 flex gap-2 items-center cursor-pointer text-white" @click="menuVisible = false">
                      <i class="pi pi-times" style="font-size: 0.75rem"></i>
                      <!-- <i class="pi pi-user" style="font-size: 0.75rem"></i>
                      <i class="pi pi-chevron-down" style="font-size: 0.75rem"></i> -->
                    </div>
                  </div>
                  <div class="w-full flex flex-col items-start">
                    <div class="text-white p-4 flex flex-col text-regular gap-1">
                      <div class="opacity-50">Ste prihlásený</div>
                      <div>{{ nameFull ?? email ?? '' }}</div>
                    </div>
                    <div class="w-full pb-2">
                      <div class="w-full text-white hover:text-secondary-500" @click="openChangePasswordDialog()">
                        <AdminMenuButton icon="pi-cog" name="Zmena hesla"></AdminMenuButton>
                      </div>
                      <div v-if="$store.getters['user/isClient']" class="w-full">
                        <router-link :to="{ name: 'portfolio' }" class="w-full">
                          <div class="w-full text-white hover:text-secondary-500">
                            <AdminMenuButton icon="pi-chart-line" name="Osobný účet"></AdminMenuButton>
                          </div>
                        </router-link>
                      </div>
                      <div class="w-full text-danger" @click="logout()">
                        <AdminMenuButton icon="pi-sign-out" name="Odhlásiť"></AdminMenuButton>
                      </div>
                    </div>
                  </div>
                </template>
              </Sidebar>
            </template>
            <template #desktop>
              <div class="flex items-center h-[calc(56px)] w-full justify-end">
                <div class="w-fit h-14 px-6 flex gap-2 items-center text-white">
                  <i class="pi pi-user" style="font-size: 0.75rem"></i>
                  <div>{{ nameShort }}</div>
                </div>
                <div class="w-fit text-white hover:text-secondary-500" @click="openChangePasswordDialog()">
                  <AdminMenuButton icon="pi-cog" name="Zmena hesla"></AdminMenuButton>
                </div>
                <div v-if="$store.getters['user/isClient']">
                  <router-link :to="{ name: 'portfolio' }">
                    <div class="w-fit text-white hover:text-secondary-500">
                      <AdminMenuButton icon="pi-chart-line" name="Osobný účet"></AdminMenuButton>
                    </div>
                  </router-link>
                </div>
                <div class="w-fit text-danger" @click="logout()">
                  <AdminMenuButton icon="pi-sign-out" name="Odhlásiť"></AdminMenuButton>
                </div>
              </div>
            </template>
          </Responsive>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import ChangePasswordtDialog from '@/components/Dialog/ChangePassword.vue'
import AdminMenuButton from '@/components/Menu/AdminMenuButton.vue'
import Responsive from '@/components/Responsive.vue'
import { shortName } from '@/misc'
import { PortalUser, User } from '@/models/user'
import AppToast from '@/toast'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Responsive,
    AdminMenuButton,
  },
})
export default class AdminView extends Vue {
  nameShort!: string

  menuVisible: boolean = false

  beforeMount() {
    this.init()
  }

  init() {
    this.nameShort = shortName(this.nameFull) ?? this.email ?? ''
  }

  async logout() {
    this.$store.commit('portal/clear')
    await this.$store.dispatch('user/logout')
    this.$router.push({ name: 'login' })
    AppToast.info(null, 'Boli ste odhlásený')
  }

  openChangePasswordDialog() {
    this.$dialog.open(ChangePasswordtDialog, {
      props: {
        header: 'Zmena hesla',
        style: {
          width: '375px',
        },
        modal: true,
        draggable: false,
      },
    })
  }

  get nameFull(): string | undefined | null {
    return this.portalUser?.omniumUser?.fullName
  }

  get email(): string | undefined | null {
    return this.portalUser?.email
  }

  get portalUser(): PortalUser | undefined | null {
    return this.$store.state.user?.portalUser
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.custom-hover:hover {
  background-color: rgba(255, 255, 255, 0.1);

  i:nth-child(2) {
    color: #eab402;
  }
}
</style>
