<template>
  <div
    class="flex items-center justify-center h-8 px-3"
    :class="{
      'opacity-10': disabled,
      'cursor-pointer': !disabled,
    }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    disabled: {
      type: Boolean,
      required: false,
      nullable: false,
      default: false,
    },
  },
})
export default class NavArrow extends Vue {
  disabled!: boolean

  beforeMount() {
    this.init()
  }

  init() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
