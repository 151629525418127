<template>
  <CustomDialog :title="showRecipients ? 'Príjemcovia' : 'Správa'" @onClose="close">
    <div class="w-full flex flex-col">
      <div v-if="showRecipients">
        <DataView :value="displayRecipients" dataKey="id" paginator :rows="10" :alwaysShowPaginator="false" paginatorTemplate="PrevPageLink PageLinks NextPageLink">
          <template v-if="(message?.recipients ?? []).length > 1" #header>
            <div class="flex">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="searchRecipient" @update:modelValue="(value?: string) => search(value)" placeholder="Vyhľadať príjemcu..." />
              </span>
            </div>
          </template>
          <template #list="slotProps">
            <AdminPreview :title="slotProps.data"> </AdminPreview>
          </template>
          <template #empty>
            <div class="px-4 py-6 w-full text-center text-h5 text-text-500">
              {{ (displayRecipients ?? []).length <= 0 ? 'Žiadni príjemcovia': 'Žiadne nájdené záznamy' }}
            </div>
          </template>
        </DataView>
      </div>
      <div v-else class="w-full grow px-4 mt-4">
        <div v-if="message" class="w-full h-full flex flex-col gap-4 overflow-auto">
          <div class="w-full">
            <div class="text-h5 font-semibold">Príjemcovia</div>
            <div>{{ recipientsNum }}</div>
            <div v-if="message?.recipients?.length && message?.recipients?.length > 0" class="py-2 cursor-pointer" @click="showRecipients = true">
              <div class="text-regular text-primary-900" text>
                <div class="flex items-center gap-2">
                  <div class="text-h6 font-semibold">Zobraziť príjemcov</div>
                  <i class="pi pi-arrow-right" style="font-size: 0.8rem"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="text-h5 font-semibold mt-4">Správa</div>
          <div class="w-full">
            <MessageDetail :message="message"></MessageDetail>
          </div>
        </div>
      </div>
    </div>
  </CustomDialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CustomDialog from '@/components/Dialog/CustomDialog.vue'
import { Message } from '@/models/message'
import MessageDetail from '@/components/Message/MessageDetail.vue'
import { inflect } from '@/misc'
import AdminPreview from '@/components/Preview/AdminPreview.vue'

@Options({
  components: {
    CustomDialog,
    MessageDetail,
    AdminPreview,
  },
  props: ['id'],
})
export default class AdminMessageView extends Vue {
  id!: number
  message?: Message

  searchRecipient: string = ''
  showRecipients: boolean = false
  displayRecipients: string[] = []

  async beforeMount() {
    await this._init()
  }

  async _init() {
    this.message = this.messages.find((element) => element.id == this.id)
    if (this.message === undefined || this.message === null) {
      await this.close()
    }
    this.displayRecipients = [...(this.message?.recipients ?? [])]
  }

  search(value?: string) {
    if (!value) {
      this.displayRecipients = [...(this.message?.recipients ?? [])]
      return
    }
    if (value.length > 0 && this.message?.recipients != undefined && this.message?.recipients != null) {
      this.displayRecipients = this.message?.recipients.filter((element) => {
        const email = element.toLowerCase()
        const val = value.toLocaleLowerCase()
        return email?.includes(val)
      })
    }
  }

  async close() {
    if (this.showRecipients) {
      this.showRecipients = false
    } else {
      await this.$router.push({ name: 'admin-messages' })
    }
  }

  get recipientsNum(): string {
    const n = this.message?.recipients?.length ?? 0
    return inflect(n, 'Žiadni príjemcovia', '1 príjemca', `${n} príjemcovia`, `${n} príjemcov`)
  }

  get messages(): Message[] {
    return this.$store.state.admin.messages
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
