<template>
  <div class="flex flex-col gap-0.5">
    <div class="text-text-500 text-regular">
      {{ label }}
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    label: {
      type: String,
      required: true,
      nullable: false,
    },
  },
})
export default class ProfileItem extends Vue {
  label!: string
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
