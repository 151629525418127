<template>
  <div class="w-full h-full relative flex flex-col items-center">
    <div class="w-full h-full">
      <TabView :activeIndex="activeIndex">
        <TabPanel :header="leftTabTitle">
          <DataView :value="leftTabClientsDisplay.value" dataKey="id" :alwaysShowPaginator="false" :rows="10" paginatorTemplate="PrevPageLink PageLinks NextPageLink">
            <template v-if="leftTabClients.length > 1" #header>
              <div class="flex">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="leftTabSearch" placeholder="Vyhľadať klienta..." @update:modelValue="(value?: string) => leftTabClientsSearch(value)" />
                </span>
              </div>
            </template>
            <template #list="slotProps">
              <AdminPreview :title="slotProps.data?.omniumUser?.fullName" :subtitle="slotProps.data?.email">
                <template #actions>
                  <AddClientButton :client="slotProps.data" :label="addButtonLabel" :addClient="leftTabClick" />
                </template>
              </AdminPreview>
            </template>
            <template #empty>
              <div class="px-4 py-6 w-full text-center text-h5 text-text-500">
                {{ (leftTabClients ?? []).length <= 0 ? leftTabMessageWhenEmpty : 'Žiadne nájdené záznamy' }}
              </div>
            </template>
          </DataView>
        </TabPanel>
        <TabPanel :header="rightTabTitle + ` (${rightTabClients.length})`">
          <DataView :value="rightTabClientsDisplay.value" dataKey="id" :alwaysShowPaginator="false" :rows="10" paginatorTemplate="PrevPageLink PageLinks NextPageLink">
            <template v-if="rightTabClients.length > 1" #header>
              <div class="flex">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="rightTabSearch" placeholder="Vyhľadať klienta..." @update:modelValue="(value?: string) => rightTabClientsSearch(value)" />
                </span>
              </div>
            </template>
            <template #list="slotProps">
              <AdminPreview :title="slotProps.data?.omniumUser?.fullName" :subtitle="slotProps.data?.email">
                <template #actions>
                  <RemoveClientButton :client="slotProps.data" :label="removeButtonLabel" :removeClient="rightTabClick" />
                </template>
              </AdminPreview>
            </template>
            <template #empty>
              <div class="px-4 py-6 w-full text-center text-h5 text-text-500">
                {{ (rightTabClients ?? []).length <= 0 ? rightTabMessageWhenEmpty : 'Žiadne nájdené záznamy' }}
              </div>
            </template>
          </DataView>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { PortalUser } from '@/models/user'
import AdminPreview from '../Preview/AdminPreview.vue'
import { Ref, ref } from 'vue'
import RemoveClientButton from './RemoveClientButton.vue'
import AddClientButton from './AddClientButton.vue'

type CallbackFunction = (client: PortalUser) => Promise<void>

@Options({
  components: {
    AdminPreview,
    AddClientButton,
    RemoveClientButton,
  },
  props: {
    leftTabTitle: {
      type: String,
      required: true,
      nullable: false,
    },
    rightTabTitle: {
      type: String,
      required: true,
      nullable: false,
    },
    addButtonLabel: {
      type: String,
      required: true,
      nullable: false,
    },
    removeButtonLabel: {
      type: String,
      required: true,
      nullable: false,
    },
    leftTabClients: {
      type: Object as () => PortalUser[],
      required: true,
      nullable: false,
      default: [],
    },
    rightTabClients: {
      type: Object as () => PortalUser[],
      required: true,
      nullable: false,
      default: [],
    },
    leftTabCallback: {
      type: Function,
      required: true,
      nullable: false,
    },
    rightTabCallback: {
      type: Function,
      required: true,
      nullable: false,
    },
    activeIndex: {
      type: Number,
      required: false,
      nullable: false,
      default: 0,
    },
    leftTabMessageWhenEmpty: {
      type: String,
      required: false,
      nullable: false,
      default: 'Žiadni klienti',
    },
    rightTabMessageWhenEmpty: {
      type: String,
      required: false,
      nullable: false,
      default: 'Žiadni klienti',
    },
  },
})
export default class ClientsSelect extends Vue {
  title!: string
  routeOnClose?: string | undefined | null
  leftTabTitle!: string
  rightTabTitle!: string
  addButtonLabel!: string
  removeButtonLabel!: string
  leftTabClients!: PortalUser[]
  rightTabClients!: PortalUser[]
  leftTabCallback!: CallbackFunction
  rightTabCallback!: CallbackFunction
  activeIndex!: number
  leftTabMessageWhenEmpty!: string
  rightTabMessageWhenEmpty!: string

  leftTabClientsDisplay!: Ref<PortalUser[]>
  rightTabClientsDisplay!: Ref<PortalUser[]>
  leftTabSearch: string = ''
  rightTabSearch: string = ''

  async beforeMount() {
    await this.init()
  }

  async init() {
    this.leftTabClientsDisplay = ref([...this.leftTabClients])
    this.rightTabClientsDisplay = ref([...this.rightTabClients])
  }

  async leftTabClick(client: PortalUser) {
    await this.leftTabCallback(client)
    this.leftTabClientsSearch()
    this.rightTabClientsSearch()
  }
  async rightTabClick(client: PortalUser) {
    await this.rightTabCallback(client)
    this.rightTabClientsSearch()
    this.leftTabClientsSearch()
  }

  leftTabClientsSearch(value?: string) {
    if (!value) {
      this.leftTabClientsDisplay.value = [...this.leftTabClients]
    }
    this.leftTabClientsDisplay.value = this.leftTabClients.filter((element) => {
      const name = element.omniumUser?.fullName
      const email = element.email
      const search = this.leftTabSearch.toLowerCase()
      return name?.toLowerCase()?.includes(search) || email?.toLowerCase()?.includes(search)
    })
  }
  rightTabClientsSearch(value?: string) {
    if (!value) {
      this.rightTabClientsDisplay.value = [...this.rightTabClients]
    }
    this.rightTabClientsDisplay.value = this.rightTabClients.filter((element) => {
      const name = element.omniumUser?.fullName
      const email = element.email
      const search = this.rightTabSearch.toLowerCase()
      return name?.toLowerCase()?.includes(search) || email?.toLowerCase()?.includes(search)
    })
  }
}
</script>

<style scoped lang="scss"></style>
