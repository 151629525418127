<template>
  <Overview :ignoreResponsive="true">
    <template #heading> Klientský portál </template>
    <div class="w-full h-full flex flex-col px-2">
      <div class="text-h4 font-bold my-5">Prihlásenie</div>
      <div v-if="error" class="pb-5">
        <InlineMessage severity="error" class="w-full"> Nesprávny e-mail alebo heslo </InlineMessage>
      </div>
      <div class="flex flex-col gap-2">
        <label for="username" class="label">Prihlasovací e-mail</label>
        <InputText id="username" placeholder="Zadajte prihlasovacie meno" v-model="username" @keydown.enter="login" />
      </div>
      <div class="flex flex-col gap-2 mt-4">
        <label for="password" class="label">Heslo</label>
        <Password id="password" placeholder="Zadajte heslo" v-model="password" :feedback="false" class="w-full" :inputStyle="{ width: '100%' }" toggleMask @keydown.enter="login" />
      </div>
      <div class="mt-4">
        <Button label="Prihlásiť sa" @click="login()" :loading="loading"></Button>
      </div>
      <div class="grow"></div>
      <div class="mt-6 pb-6 w-full flex justify-center">
        <router-link :to="{ name: 'reset-password' }">
          <div class="text-regular font-medium link">Mám problém s prihlásením</div>
        </router-link>
      </div>
    </div>
  </Overview>
</template>

<script lang="ts">
import Overview from '@/components/Layout/Overview.vue'
import { Error401, Error400 } from '@/errors/axiosErrors'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Overview,
  },
})
export default class LoginView extends Vue {
  username: string = ''
  password: string = ''

  error: boolean = false
  loading: boolean = false

  async login() {
    if (this.loading) {
      return
    }
    try {
      this.error = false
      this.loading = true
      try {
        await this.$store.dispatch('user/login', { email: this.username.toLowerCase(), password: this.password })
      } catch (e) {
        if (e instanceof Error401 || e instanceof Error400) {
          this.error = true
          return
        }
        throw e
      }
      if (this.$store.getters['user/isStaff']) {
        this.$router.push({ name: 'clients' })
      } else {
        this.$router.push({ name: 'portfolio' })
      }
    } finally {
      this.loading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
