<template>
  <div class="w-full h-full flex flex-col">
    <div class="w-full h-12 flex items-center justify-between shrink-0 pl-4 bg-black text-white text-regular leading-none fixed top-14 z-20">
      <div class="opacity-70">Účet klienta: {{ clientName ?? 'načítavám...' }}</div>
      <router-link :to="{ name: 'clients' }">
        <div class="opacity-70 px-4 py-3 font-bold cursor-pointer text-white">Ukončiť</div>
      </router-link>
    </div>
    <div class="mt-14 h-12 w-full shrink-0"></div>
    <div class="w-full grow shrink-0">
      <Responsive>
        <template #mobile>
          <Detail v-if="showDetail" back="Portfóliá" :noPadding="true" :onBack="deselectStrategy">
            <StrategyDetail v-if="strategy" :strategy="strategy" :userId="portalUser?.id" />
          </Detail>
          <Overview v-else>
            <template #heading> Portfóliá </template>
            <div v-if="loading" class="w-full flex justify-center">
              <ProgressSpinner></ProgressSpinner>
            </div>
            <div v-else class="w-full">
              <div v-if="strategies && strategies.length > 0" class="w-full">
                <div v-for="strategy in strategies" class="w-full cursor-pointer" @click="selectStrategy(strategy)">
                  <StrategyPreview :strategy="strategy" />
                </div>
                <div class="mt-4"><StrategiesUpdated :strategy="strategies[0]" /></div>
              </div>
              <div v-else class="w-full mt-4 px-6">
                <NoStrategy></NoStrategy>
              </div>
            </div>
            <div v-if="strategies && strategies.length > 0" class="w-fit mt-6"><CapitolDown :strategy="strategies[0]" /></div>
          </Overview>
        </template>
        <template #desktop>
          <Overview>
            <div v-if="loading" class="w-full flex justify-center">
              <ProgressSpinner></ProgressSpinner>
            </div>
            <div v-else class="w-full h-full pb-10">
              <div v-if="strategies && strategies.length > 0" class="grid grid-cols-12 h-full w-full gap-5 px-6">
                <div class="col-span-3 h-full">
                  <div class="w-full h-full flex flex-col pr-7">
                    <div class="w-full grow flex flex-col gap-2">
                      <div v-for="str in strategies" class="w-full cursor-pointer" @click="selectStrategy(str)">
                        <StrategyPreview :strategy="str" :selected="str.code === strategy?.code" />
                      </div>
                      <div class="w-fit pl-4 pb-2 grow"><StrategiesUpdated :strategy="strategies[0]" /></div>
                      <div class="w-fit pb-6"><CapitolDown :strategy="strategies[0]" /></div>
                    </div>
                    <div class="w-full mt-4">
                      <StrategyActions :showIban="true"></StrategyActions>
                    </div>
                  </div>
                </div>
                <div class="col-span-9 h-full">
                  <div class="w-full h-full">
                    <StrategyDetail v-if="strategy" :strategy="strategy" :userId="portalUser?.id"> </StrategyDetail>
                  </div>
                </div>
              </div>
              <div v-else class="w-full mt-4 px-6 flex flex-col items-center">
                <NoStrategy></NoStrategy>
                <div class="mt-4">
                  <StrategyActions :showIban="false"></StrategyActions>
                </div>
              </div>
            </div>
          </Overview>
        </template>
      </Responsive>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import UserApi from '@/api/modules/user'
import { PortalUser } from '@/models/user'
import { Strategy } from '@/models/portal'
import PortalApi from '@/api/modules/portal'
import Overview from '@/components/Layout/Overview.vue'
import StrategyPreview from '@/components/Preview/StrategyPreview.vue'
import StrategyDetail from '@/components/Strategy/StrategyDetail.vue'
import Detail from '@/components/Layout/Detail.vue'
import StrategiesUpdated from '@/components/StrategiesUpdated.vue'
import Responsive from '@/components/Responsive.vue'
import NoStrategy from '@/components/Strategy/NoStrategy.vue'
import CapitolDown from '@/components/CapitolDown.vue'
import StrategyActions from '@/components/Strategy/StrategyActions.vue'

@Options({
  components: {
    Detail,
    Overview,
    StrategyPreview,
    StrategyDetail,
    StrategiesUpdated,
    Responsive,
    NoStrategy,
    CapitolDown,
    StrategyActions
  },
  props: ['id'],
})
export default class ClientDetailView extends Vue {
  id!: number
  portalUser: PortalUser | null | undefined = null
  strategies: Strategy[] | null | undefined = null
  strategy: Strategy | null | undefined = null

  loading: boolean = false

  showDetail: boolean = false

  async beforeMount() {
    await this.init()
  }

  async init() {
    this.loading = true
    try {
      this.portalUser = await UserApi.getUser(this.id)
      this.strategies = await PortalApi.getAllUserStrategies(this.id)
      if (this.strategies.length > 0) {
        this.strategy = this.strategies[0]
      }
    } finally {
      this.loading = false
    }
  }

  selectStrategy(strategy: Strategy) {
    this.strategy = strategy
    this.showDetail = true
  }

  deselectStrategy() {
    this.strategy = null
    this.showDetail = false
  }

  get clientName(): string | undefined {
    const user = this.portalUser?.omniumUser
    if (user !== undefined && user !== null && user.fullName !== undefined && user.fullName !== null) {
      return `${user.fullName}`
    }
    return this.portalUser?.email
  }
}
</script>

<style scoped lang="scss"></style>
