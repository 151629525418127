<template>
  <Responsive>
    <template #mobile>
      <Overview>
        <template #heading>
          <div class="flex items-center gap-1.5">
            <div>Správy</div>
            <div
              v-if="unseenCount > 0"
              class="count-avatar text-white h-6 text-regular leading-none"
              style="padding-right: 0.5px; padding-top: 0.5px"
              :class="{ 'w-8': unseenCount > 9, 'w-6': unseenCount <= 9 }"
            >
              {{ unseenCount > 9 ? 9 : unseenCount }}{{ unseenCount > 9 ? '+' : '' }}
            </div>
          </div>
        </template>
        <div class="w-full px-2">
          <div v-if="loading" class="mt-8 w-full flex items-center">
            <ProgressSpinner />
          </div>
          <div v-else class="w-full">
            <div v-if="messages.length <= 0" class="text-text-500 text-regular w-full flex flex-col items-center mt-4 px-6">
              <img :src="require('@/assets/envelope.svg')" width="330" />
              <div class="text-h6 text-text-500 mt-6">Nemáte žiadnu správu</div>
            </div>
            <div v-else class="w-full flex flex-col items-end">
              <div class="font-semibold text-primary-900 text-regular py-4 leading-none cursor-pointer hover:underline" @click="markAllAsRead()">Označiť všetky ako prečítané</div>
              <div class="w-full bdr">
                <div v-for="message in messages" class="w-full">
                  <MessagePreview :message="message" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Overview>
    </template>
    <template #desktop>
      <Overview>
        <template #heading>
          <div class="w-full flex justify-between">
            <div class="flex items-center gap-1.5 h-7">
              <div>Správy</div>
              <div
                v-if="unseenCount > 0"
                class="count-avatar text-white h-7 text-regular leading-none"
                style="padding-right: 0.5px; padding-top: 0.5px"
                :class="{ 'w-9': unseenCount > 9, 'w-7': unseenCount <= 9 }"
              >
                {{ unseenCount > 9 ? 9 : unseenCount }}{{ unseenCount > 9 ? '+' : '' }}
              </div>
            </div>
            <div v-if="messages.length > 0" class="font-semibold text-primary-900 text-regular pt-2 leading-none cursor-pointer hover:underline" @click="markAllAsRead()">
              Označiť všetky ako prečítané
            </div>
          </div>
        </template>
        <div class="w-full h-full">
          <div v-if="loading" class="mt-8 w-full flex items-center">
            <ProgressSpinner />
          </div>
          <div v-else class="w-full h-full">
            <div v-if="messages.length <= 0" class="text-text-500 text-regular w-full flex flex-col items-center mt-4 px-6">
              <img :src="require('@/assets/envelope.svg')" width="330" />
              <div class="text-h6 text-text-500 mt-6">Nemáte žiadnu správu</div>
            </div>
            <div v-else class="w-full h-full">
              <div class="grid grid-cols-12 h-full w-full gap-5">
                <div class="col-span-4 h-full pl-5 overflow-y-auto">
                  <div class="w-full h-full flex flex-col pr-7 pb-4">
                    <div class="w-full px-2">
                      <div class="w-full h-[calc(0.5px)] bdr-desktop"></div>
                    </div>
                    <div class="w-full">
                      <div v-for="message in messages" class="w-full">
                        <MessagePreview :message="message" />
                      </div>
                    </div>
                    <div class="w-full px-2">
                      <div class="w-full h-[calc(0.5px)] bdr-desktop"></div>
                    </div>
                    <div class="w-full h-20 shrink-0"></div>
                  </div>
                </div>
                <div class="col-span-8 h-full pr-6">
                  <div class="w-full h-full pb-4">
                    <div class="w-full h-full empty-container flex justify-center text-center">
                      <div class="text-h4 font-semibold text-black opacity-20 mt-20">Zvoľte správu</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Overview>
    </template>
  </Responsive>
</template>

<script lang="ts">
import MessageApi from '@/api/modules/message'
import MessagePreview from '@/components/Preview/MessagePreview.vue'
import { Message } from '@/models/message'
import { User } from '@/models/user'
import { Options, Vue } from 'vue-class-component'
import connectWebSocket from '@/websock/websock'
import Overview from '@/components/Layout/Overview.vue'
import Responsive from '@/components/Responsive.vue'

@Options({
  components: {
    Overview,
    MessagePreview,
    Responsive,
  },
})
export default class AllMessagesView extends Vue {
  loading: boolean = false
  messages: Message[] = []

  socket!: WebSocket

  async mounted() {
    await this.init()
  }

  async init() {
    if (this.loading) {
      return
    }
    this.loading = true
    try {
      this.messages = await MessageApi.getAllMessages()
    } finally {
      this.loading = false
    }
    // this.connectAndRecive()
  }

  connectAndRecive() {
    const token = this.user.accessToken
    if (token) {
      this.socket = connectWebSocket(token)
      this.socket.onmessage = this.recieve
    }
  }

  async recieve(event: MessageEvent<any>) {
    this.messages = await MessageApi.getAllMessages()
  }

  async markAllAsRead() {
    for (const message of this.messages) {
      message.seen = true
    }
    await MessageApi.markAllMessagesSeen()
    this.$store.commit('portal/setUnseenMessageCount', 0)
  }

  get user(): User {
    return this.$store.state.user
  }

  get unseenCount(): number {
    var count = 0
    for (const message of this.messages) {
      if (!message.seen) {
        count++
      }
    }
    return count
  }
}
</script>

<style scoped lang="scss">
.bdr {
  border-top: 1px solid #d9d9d9;
}

.bdr-desktop {
  background-color: #d9d9d9;
}
</style>
