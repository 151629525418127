<template>
  <div class="w-96 h-full flex flex-col">
    <div class="h-16"></div>
    <div class="pt-8 px-8 flex items-center justify-between">
      <img src="/images/omnium-logo.png" />
      <div>
        <slot name="trailing"></slot>
      </div>
    </div>
    <div class="grow">
      <div class="px-4 w-full h-full flex flex-col">
        <div class="h-14 flex flex-col justify-center">
          <div>
            <slot name="header"></slot>
          </div>
        </div>
        <div class="px-4 grow">
          <div class="h-full w-full flex flex-col">
            <div v-if="icon" class="pb-1 w-12 h-12 flex items-center justify-center">
              <i :class="'pi ' + icon" style="font-size: 2rem"></i>
            </div>
            <div class="text-h1 font-medium leading-tight whitespace-pre-wrap">{{ title }}</div>
            <div v-if="description" class="mt-4 text-regular font-medium whitespace-pre-wrap">
              {{ description }}
            </div>
            <div class="pt-6 grow">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-32"></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    title: {
      type: String,
      required: true,
      nullable: false,
    },
    description: {
      type: String,
      required: false,
      nullable: true,
    },
    icon: {
      type: String,
      required: false,
      nullable: true,
    },
  },
})
export default class Intro extends Vue {
  title!: string
  description?: string
  icon?: string
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
  height: 668px;
}
</style>
