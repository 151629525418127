<template>
  <Responsive>
    <template #mobile>
      <Detail v-if="loading" back="Správy" :onBack="onBack">
        <ProgressSpinner></ProgressSpinner>
      </Detail>
      <Detail v-else back="Správy" :onBack="onBack">
        <template #trailing>
          <div class="w-8 h-8 rounded-full bg-primary-100 cursor-pointer flex items-center justify-center text-primary-900" @click="deleteVisible = true">
            <i class="pi pi-ellipsis-h" style="font-size: 1rem"></i>
          </div>
        </template>
        <div v-if="message" class="w-full mt-2">
          <MessageDetail :message="message"></MessageDetail>
        </div>
      </Detail>
      <div v-if="deleteVisible" class="fixed top-0 left-0 bg-black bg-opacity-40 z-50 w-screen h-screen" @click="deleteVisible = false">
        <div class="w-full h-12 bg-primary-100 mt-28 cursor-pointer" @click="deleteMessage">
          <div class="w-[calc(375px)] flex items-center px-6 mx-auto h-full gap-3 leading-none text-primary-900 text-regular font-semibold">
            <div><i class="pi pi-trash" style="font-size: 1rem"></i></div>
            <div>Vymazať správu</div>
            <ProgressSpinner v-if="deleting" />
          </div>
        </div>
      </div>
    </template>
    <template #desktop>
      <Overview>
        <template #heading>
          <div class="w-full flex justify-between">
            <div class="flex items-center gap-1.5 h-7">
              <div>Správy</div>
              <div
                v-if="unseenCount > 0"
                class="count-avatar text-white h-7 text-regular leading-none"
                style="padding-right: 0.5px; padding-top: 0.5px"
                :class="{ 'w-9': unseenCount > 9, 'w-7': unseenCount <= 9 }"
              >
                {{ unseenCount > 9 ? 9 : unseenCount }}{{ unseenCount > 9 ? '+' : '' }}
              </div>
            </div>
            <div v-if="messages.length > 0" class="font-semibold text-primary-900 text-regular pt-2 leading-none cursor-pointer hover:underline" @click="markAllAsRead()">
              Označiť všetky ako prečítané
            </div>
          </div>
        </template>
        <div class="w-full h-full">
          <div v-if="loading" class="mt-8 w-full flex items-center">
            <ProgressSpinner />
          </div>
          <div v-else class="w-full h-full">
            <div v-if="messages.length <= 0" class="text-text-500 text-regular w-full flex flex-col items-center mt-4 px-6">
              <img :src="require('@/assets/envelope.svg')" width="330" />
              <div class="text-h6 text-text-500 mt-6">Nemáte žiadnu správu</div>
            </div>
            <div v-else class="w-full h-full">
              <div class="grid grid-cols-12 h-full w-full gap-5">
                <div class="col-span-4 h-full overflow-y-auto pl-5">
                  <div class="w-full h-full flex flex-col pr-7">
                    <div class="w-full px-2">
                      <div class="w-full h-[calc(0.5px)] bdr-desktop"></div>
                    </div>
                    <div class="w-full">
                      <div v-for="msg in messages" class="w-full">
                        <MessagePreview :message="msg" :selected="msg.id === message?.id" />
                      </div>
                    </div>
                    <div class="w-full px-2">
                      <div class="w-full h-[calc(0.5px)] bdr-desktop"></div>
                    </div>
                    <div class="w-full h-20 shrink-0"></div>
                  </div>
                </div>
                <div class="col-span-8 h-full overflow-y-auto pr-6">
                  <div class="w-full h-full flex gap-10">
                    <div class="grow h-full">
                      <MessageDetail :message="message"></MessageDetail>
                    </div>
                    <div class="w-8 h-8 rounded-full bg-primary-100 cursor-pointer flex items-center justify-center text-primary-900" @click="deleteVisible = true">
                      <i class="pi pi-ellipsis-h" style="font-size: 1rem"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Overview>
      <div v-if="deleteVisible" class="fixed top-0 left-0 bg-black bg-opacity-40 z-50 w-screen h-screen" @click="overlayClick">
        <Responsive>
          <template #mobile>
            <div class="w-full h-12 bg-primary-100 mt-48 cursor-pointer flex items-center" @click="deleteMessage">
              <div class="w-full h-full" style="max-width: 1400px">
                <div class="flex items-center justify-end h-full gap-3 leading-none text-primary-900 text-regular font-semibold w-full px-6">
                  <div><i class="pi pi-trash" style="font-size: 1rem"></i></div>
                  <div>Vymazať správu</div>
                  <ProgressSpinner v-if="deleting" />
                </div>
              </div>
            </div>
          </template>
          <template #desktop>
            <div class="w-full h-12 mt-48 flex justify-center">
              <div class="w-full flex justify-end" style="max-width: 1400px">
                <div
                  class="flex items-center justify-end h-full gap-3 leading-none bg-primary-100 text-primary-900 text-regular font-semibold w-fit pl-6 pr-20 mr-6 cursor-pointer rounded-md"
                  @click="deleteMessage"
                >
                  <ProgressSpinner v-if="deleting" />
                  <div v-if="!deleting"><i class="pi pi-trash" style="font-size: 1rem"></i></div>
                  <div>Vymazať správu</div>
                </div>
              </div>
            </div>
          </template>
        </Responsive>
      </div>
    </template>
  </Responsive>
</template>

<script lang="ts">
import Detail from '@/components/Layout/Detail.vue'
import { formatDate, isDST } from '@/misc'
import { Attachment, Message } from '@/models/message'
import { Options, Vue } from 'vue-class-component'
import MessageApi from '@/api/modules/message'
import AppToast from '@/toast'
import MessageDetail from '@/components/Message/MessageDetail.vue'
import Responsive from '@/components/Responsive.vue'
import Overview from '@/components/Layout/Overview.vue'
import MessagePreview from '@/components/Preview/MessagePreview.vue'

@Options({
  components: {
    Detail,
    MessageDetail,
    Responsive,
    Overview,
    MessagePreview,
  },
  props: ['id'],
})
export default class MessageDetailView extends Vue {
  id!: number
  message?: Message | null | undefined = null
  messages: Message[] = []

  loading: boolean = false

  deleteVisible: boolean = false
  deleting: boolean = false

  async beforeMount() {
    await this.init()
  }

  beforeUpdate() {
    this._findMessage()
  }

  private _findMessage() {
    this.message = this.messages.find((element) => element.id == this.id) ?? null
  }

  async init() {
    this.loading = true
    try {
      this.messages = await MessageApi.getAllMessages()
      this._findMessage()
    } finally {
      this.loading = false
    }
  }

  formatMessageDate(): string | null {
    const date = this.message?.date
    if (!date) {
      return null
    }
    const real = new Date(date)
    if (isDST(real)) {
      real.setHours(real.getHours() + 2)
    } else {
      real.setHours(real.getHours() + 1)
    }
    return formatDate(real)
  }

  async deleteMessage(event: MouseEvent) {
    event.stopPropagation()
    if (this.deleting || !this.message?.id) {
      return
    }
    this.deleting = true
    try {
      await MessageApi.deleteMessage(this.message.id)
      this.$router.push({ name: 'all-messages' })
      AppToast.info(null, 'Správa bola vymazaná')
    } finally {
      this.deleting = false
      this.deleteVisible = false
    }
  }

  async downloadAttachment(attachment: Attachment) {
    const blob = await MessageApi.getAttachment(attachment.id)
    const href = window.URL.createObjectURL(blob)
    const anchorElement = document.createElement('a')
    anchorElement.href = href
    anchorElement.download = attachment.name
    document.body.appendChild(anchorElement)
    anchorElement.click()
    document.body.removeChild(anchorElement)
    window.URL.revokeObjectURL(href)
  }

  onBack() {
    this.$router.push({ name: 'all-messages' })
  }

  async markAllAsRead() {
    for (const message of this.messages) {
      message.seen = true
    }
    await MessageApi.markAllMessagesSeen()
  }

  overlayClick(event: Event) {
    this.deleteVisible = false
    event.stopPropagation()
  }

  get unseenCount(): number {
    var count = 0
    for (const message of this.messages) {
      if (!message.seen) {
        count++
      }
    }
    return count
  }
}
</script>

<style scoped lang="scss">
.p-progress-spinner {
  width: 24px;
  height: 24px;
  margin: 0px;
}
</style>
