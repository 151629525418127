<template>
  <ChangePasswordForm @onSuccess="close"></ChangePasswordForm>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { inject } from 'vue'
import ChangePasswordForm from '../ChangePassword.vue'

@Options({
  inject: ['dialogRef'],
  components: {
    ChangePasswordForm,
  }
})
export default class ChangePasswordtDialog extends Vue {

  dialog: any = inject('dialogRef')

  async beforeMount() {
    await this.init()
  }

  async init() {}

  close() {
    this.dialog?.close()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bdr {
  border: 1px solid rgb(206 212 218);
}
</style>
