<template>
  <div
    class="w-full h-14 px-6 flex gap-4 border-white border-opacity-40 items-center cursor-pointer"
    :class="{ 'text-secondary-500 bg-lightPurple bg-opacity-10': active, 'text-white': !active }"
  >
    <div><i :class="'pi ' + icon" style="font-size: 1rem"></i></div>
    <div class="flex items-center gap-2">
      <div class="font-medium">{{ name }}</div>
      <div
        v-if="count && count > 0"
        class="count-avatar text-primary-900 h-5 text-regular font-bold"
        style="padding-right: 0.5px; padding-top: 0.5px"
        :class="{ 'w-6': count > 9, 'w-5': count <= 9 }"
      >
        {{ count > 9 ? 9 : count }}{{ count > 9 ? '+' : '' }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router'

@Options({
  props: {
    icon: {
      type: String,
      required: true,
      nullable: false,
    },
    name: {
      type: String,
      required: true,
      nullable: false,
    },
    path: {
      type: String,
      required: true,
      nullable: false,
    },
    activeTest: {
      type: String,
      required: false,
      nullable: true,
    },
    count: {
      type: Number,
      required: false,
      nullable: true,
    },
  },
})
export default class MenuButton extends Vue {
  icon!: string
  name!: string
  path!: string
  activeTest?: string
  count?: number

  route!: RouteLocationNormalizedLoaded
  active: boolean = false

  mounted() {
    this.route = useRoute()
    this.active = this.route.path.startsWith(this.activeTest ?? this.path)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
