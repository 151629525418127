<template>
  <Responsive>
    <template #mobile>
      <div class="w-full h-full flex flex-col gap-6">
        <div class="w-full grow flex flex-col">
          <div class="w-full text-h2 font-bold mt-4 leading-none px-4">{{ strategy.name ?? 'Všeobecná stratégia' }}</div>
          <div class="w-full mt-6">
            <StrategyGraph :strategy="strategy" :userId="userId"></StrategyGraph>
          </div>
          <div class="mt-10 w-fit flex flex-col text-regular text-lightGrey px-4">
            <div class="flex items-center gap-2 justify-between">
              <div class="w-32">Hodnota akcií:</div>
              <div class="font-semibold">{{ formatValue(valuePort) }} €</div>
            </div>
            <div class="flex items-center gap-2 justify-between">
              <div class="w-32">Hodnota hotovosti:</div>
              <div class="font-semibold">{{ formatValue(valueFin) }} €</div>
            </div>
          </div>
          <div class="mt-4 px-4">
            <a v-if="docs" :href="docs" target="_blank" rel="noreferrer" class="text-black hover:underline leading-none">
              <div class="flex items-center gap-3 text-regular font-semibold py-2">
                <div><i class="pi pi-info-circle" style="font-size: 1rem"></i></div>
                <div>Informácie o produkte</div>
              </div>
            </a>
          </div>
          <div class="mt-2 w-full flex flex-col items-center justify-end pb-4 grow px-4">
            <StrategiesUpdated :strategy="strategy" />
            <div class="text-small font-normal text-text-500 text-center mt-1 w-56">Hodnota portfólia je počítaná k predchádzajúcemu obchodnému dňu.</div>
          </div>
        </div>
      </div>
    </template>
    <template #desktop>
      <div class="w-full h-full flex flex-col gap-6">
        <div class="w-full grow flex flex-col">
          <div class="text-text-500 px-4 mt-2 pb-1">Portfólio</div>
          <div class="w-full text-h2 font-bold leading-none px-4">{{ strategy.name ?? 'Všeobecná stratégia' }}</div>
          <div class="w-full mt-6">
            <StrategyGraph :strategy="strategy" :userId="userId"></StrategyGraph>
          </div>
          <div class="w-fit flex flex-col text-regular text-lightGrey px-4 mt-8">
            <div class="flex items-center gap-2 justify-between">
              <div class="w-32">Hodnota akcií:</div>
              <div class="font-semibold">{{ formatValue(valuePort) }} €</div>
            </div>
            <div class="flex items-center gap-2 justify-between">
              <div class="w-32">Hodnota hotovosti:</div>
              <div class="font-semibold">{{ formatValue(valueFin) }} €</div>
            </div>
          </div>
          <div class="mt-5 grow px-4 w-full flex justify-between items-end">
            <div>
              <div class="w-fit"><StrategiesUpdated :strategy="strategy" /></div>
              <div class="text-small w-fit font-normal text-text-500 text-center mt-1">Hodnota portfólia je počítaná k predchádzajúcemu obchodnému dňu.</div>
            </div>
            <div class="h-8">
              <a v-if="docs" :href="docs" target="_blank" rel="noreferrer" class="text-black hover:underline leading-none">
                <div class="flex items-center gap-3 text-regular font-semibold py-2">
                  <div><i class="pi pi-info-circle" style="font-size: 1rem"></i></div>
                  <div>Informácie o produkte</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Responsive>
</template>

<script lang="ts">
import StrategiesUpdated from '@/components/StrategiesUpdated.vue'
import { Strategy } from '@/models/portal'
import { Options, Vue } from 'vue-class-component'
import StrategyGraph from '@/components/Strategy/Graph/StrategyGraph.vue'
import Responsive from '../Responsive.vue'
import { formatCurrencyValue } from '@/misc'

@Options({
  components: {
    StrategiesUpdated,
    StrategyGraph,
    Responsive,
  },
  props: {
    strategy: {
      type: Object as () => Strategy,
      required: true,
      nullable: false,
    },
    userId: {
      type: Number,
      required: false,
      nullable: true,
    },
  },
})
export default class StrategyDetail extends Vue {
  strategy!: Strategy
  userId?: number | null | undefined

  valueFin: number = 0
  valuePort: number = 0

  docs: string | null | undefined

  docsSrc = {
    'Equity US': 'https://www.omniumcapital.sk/documents/12382_omniumcapital_dokument_equity_US_2023.pdf',
    'Aristocrat Dividend': 'https://www.omniumcapital.sk/documents/12406_omniumcapital_dokument_aristocrat_dividend_2023.pdf',
    'Safe Harbor': 'https://www.omniumcapital.sk/documents/05_04_2023_5529.pdf',
    Defense: 'https://www.omniumcapital.sk/documents/12372_omniumcapital_brozura_omnium_defense_2023.pdf',
  }

  beforeMount() {
    this.init()
  }

  init() {
    this._initData()
    this._initDocs()
  }

  beforeUpdate() {
    this._initData()
    this._initDocs()
  }

  formatValue(value: number): string {
    return formatCurrencyValue(value)
  }

  private _initData() {
    const values = this.strategy.values
    if (values && values.length > 0) {
      this.valueFin = values[0].valueFin ?? 0
      this.valuePort = values[0].valuePort ?? 0
    } else {
      this.valueFin = 0
      this.valuePort = 0
    }
  }

  _initDocs() {
    this.docs = null
    if (this.strategy.name && this.docsSrc.hasOwnProperty(this.strategy.name)) {
      this.docs = this.docsSrc[this.strategy.name]
    }
  }
}
</script>

<style scoped lang="scss">
.p-button.p-button-icon-only.p-button-rounded {
  height: 1rem;
  width: 1rem;
}
</style>
