import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full h-full flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_ClientsSelect = _resolveComponent("ClientsSelect")!
  const _component_CustomDialog = _resolveComponent("CustomDialog")!

  return (_openBlock(), _createBlock(_component_CustomDialog, {
    title: "Manažment klientov",
    routeOnClose: "agents"
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ProgressSpinner)
          ]))
        : (_openBlock(), _createBlock(_component_ClientsSelect, {
            key: 1,
            leftTabTitle: "Všetci klienti",
            rightTabTitle: "Agentovi klienti",
            addButtonLabel: "Priradiť agentovi",
            removeButtonLabel: "Odobrať agentovi",
            leftTabClients: _ctx.leftTabClients,
            rightTabClients: _ctx.rightTabClients,
            leftTabCallback: _ctx.addClient,
            rightTabCallback: _ctx.removeClient,
            activeIndex: 1,
            leftTabMessageWhenEmpty: "Žiadni dostupní klienti",
            rightTabMessageWhenEmpty: "Žiadni priradení klienti"
          }, null, 8, ["leftTabClients", "rightTabClients", "leftTabCallback", "rightTabCallback"]))
    ]),
    _: 1
  }))
}