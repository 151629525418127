<template>
  <Responsive>
    <template #mobile>
      <div class="w-full flex items-center justify-between gap-4 p-4 bg-darkBlue rounded-xl mt-4 text-white">
        <div style="padding-top: 72px">
          <div class="text-h5 font-regular opacity-80 pb-1">
            {{ strategy.name ?? 'Všeobecná stratégia' }}
          </div>
          <StrategyValue :strategy="strategy" displayType="compact" />
        </div>
        <div>
          <div><i class="pi pi-arrow-right" style="font-size: 1rem"></i></div>
        </div>
      </div>
    </template>
    <template #desktop>
      <div
        class="w-full flex flex-col rounded-md custom-border p-4 hover:bg-primary-100"
        :class="{
          'bg-primary-100': selected,
        }"
      >
        <div class="text-h6 font-regular pb-1">
          {{ strategy.name ?? 'Všeobecná stratégia' }}
        </div>
        <StrategyValue :strategy="strategy" displayType="compact" />
      </div>
    </template>
  </Responsive>
</template>

<script lang="ts">
import { Strategy } from '@/models/portal'
import { Options, Vue } from 'vue-class-component'
import StrategyValue from '../Strategy/StrategyValue.vue'
import Responsive from '../Responsive.vue'

@Options({
  props: {
    strategy: {
      type: Object as () => Strategy,
      required: true,
      nullable: false,
    },
    selected: {
      type: Boolean,
      required: false,
      nullable: false,
      default: false,
    },
  },
  components: {
    StrategyValue,
    Responsive,
  },
})
export default class StrategyPreview extends Vue {
  strategy!: Strategy
  selected!: boolean

  beforeMount() {
    this.init()
  }

  init() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.custom-border {
  border: 1px solid #ebebeb;
}
</style>
