import { Portal, Strategy } from '@/models/portal'
import { State } from '..'
import { ActionContext } from 'vuex'
import PortalApi from '@/api/modules/portal'
import MessageApi from '@/api/modules/message'

export default {
  namespaced: true,
  state: (): Portal => ({ unseenMessageCount: 0 }),
  mutations: {
    setAllStrategies(portal: Portal, strategies: Strategy[]) {
      portal.strategies = strategies
    },

    setUnseenMessageCount(portal: Portal, count: number) {
      portal.unseenMessageCount = count
    },

    decreeseUnseenMessageCount(portal: Portal) {
      const count = portal.unseenMessageCount
      if (count > 0) {
        portal.unseenMessageCount = count - 1
      }
    },

    clear(portal: Portal) {
      portal.strategies = undefined
      sessionStorage.clear()
    },
  },
  actions: {
    async getAllStrategies(context: ActionContext<Portal, State>) {
      const data = await PortalApi.getAllStrategies()
      context.commit('setAllStrategies', data)
    },

    async getUnseenMessageCount(context: ActionContext<Portal, State>) {
      const data = await MessageApi.getUnseenMessageCount()
      context.commit('setUnseenMessageCount', data)
    },
  },
  getters: {
    unreadMessages(state: Portal): boolean {
      return state.unseenMessageCount > 0
    },
  },
}
