<template>
  <Detail :hideHeader="true">
    <div class="w-full h-full flex flex-col">
      <div class="text-h4 font-bold my-5">Aktivujte si účet</div>
      <div class="text-regular">Zadajte dočasné prihlasovacie údaje, ktoré sme Vám zaslali na e-mail.</div>
      <div class="h-full flex flex-col mt-6">
        <div v-if="error" class="pb-5">
          <InlineMessage severity="error" class="w-full"> Nesprávne meno alebo heslo </InlineMessage>
        </div>
        <div class="flex flex-col gap-2">
          <label for="webname" class="label">Prihlasovacie meno</label>
          <InputText
            id="webname"
            placeholder="Zadajte prihlasovacie meno"
            v-model="webname"
            @keydown.enter="loginOmniumUser"
          />
        </div>
        <div class="flex flex-col gap-2 mt-4">
          <label for="password" class="label">Heslo</label>
          <Password
            id="password"
            placeholder="Zadajte heslo"
            v-model="password"
            :feedback="false"
            class="w-full"
            :inputStyle="{ width: '100%' }"
            toggleMask
            @keydown.enter="loginOmniumUser"
          />
        </div>
        <div class="mt-4">
          <Button label="Aktivovať účet" @click="loginOmniumUser()" :loading="loading"></Button>
        </div>
        <div class="mt-6">
          <div class="text-regular font-medium">
            Máte už aktivovaný účet?
            <span class="link"><router-link :to="{ name: 'login' }">Prihláste sa</router-link></span>
          </div>
        </div>
        <div class="grow"></div>
        <div class="mt-8 pb-6">
          <div v-if="showContactInfo" class="fade">
            <Contact />
          </div>
          <div v-else class="text-regular font-medium w-full flex justify-center text-center">
            <div>Problémy s prihlásením? <span class="link" @click="showContactInfo = true">Kontaktujte nás</span></div>
          </div>
        </div>
      </div>
    </div>
  </Detail>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Error401, Error400 } from '@/errors/axiosErrors'
import Contact from '@/components/Intro/Contact.vue'
import Detail from '@/components/Layout/Detail.vue'

@Options({
  components: {
    Detail,
    Contact,
  },
})
export default class LandingPageView extends Vue {
  webname: string = ''
  password: string = ''

  error: boolean = false
  loading: boolean = false
  showContactInfo: boolean = false

  async loginOmniumUser() {
    try {
      this.loading = true
      try {
        await this.$store.dispatch('user/loginOmniumUser', { webname: this.webname.trim(), password: this.password.trim() })
      } catch (e) {
        if (e instanceof Error401 || e instanceof Error400) {
          this.error = true
          return
        }
        throw e
      }
      const user = this.$store.state.user
      if (user.registered) {
        this.$router.push({ name: 'active' })
      } else {
        this.$router.push({ name: 'password', params: { webname: this.webname.trim(), password: this.password.trim() } })
      }
    } finally {
      this.loading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.p-inline-message {
  justify-content: start;
}
</style>
