<template>
  <Responsive>
    <template #mobile>
      <Detail back="Portfóliá" :onBack="onBack" :noPadding="true">
        <StrategyDetail v-if="strategy" :strategy="strategy" />
      </Detail>
    </template>
    <template #desktop>
      <Overview>
        <div v-if="strategies && strategies.length > 0" class="grid grid-cols-12 h-full w-full gap-5 px-6 pb-4">
          <div class="col-span-3 h-full">
            <div class="w-full h-full flex flex-col pr-7">
              <div class="w-full grow flex flex-col gap-2 brd">
                <div v-for="str in strategies" class="w-full">
                  <router-link :to="{ name: 'strategy-detail', params: { code: str.code } }">
                    <StrategyPreview :strategy="str" :selected="str === strategy" />
                  </router-link>
                </div>
                <div class="w-fit pl-4 pb-2"><StrategiesUpdated :strategy="strategies[0]" /></div>
              </div>
              <div class="w-full mt-4">
                <StrategyActions :showIban="true"></StrategyActions>
              </div>
            </div>
          </div>
          <div class="col-span-9 h-full">
            <div class="w-full h-full">
              <StrategyDetail :strategy="strategy"> </StrategyDetail>
            </div>
          </div>
        </div>
        <div v-else class="w-full mt-4 px-6 flex flex-col items-center">
          <NoStrategy></NoStrategy>
          <div class="mt-4">
            <StrategyActions :showIban="false"></StrategyActions>
          </div>
        </div>
      </Overview>
    </template>
  </Responsive>
</template>

<script lang="ts">
import Detail from '@/components/Layout/Detail.vue'
import { Strategy } from '@/models/portal'
import { Options, Vue } from 'vue-class-component'
import StrategyDetail from '@/components/Strategy/StrategyDetail.vue'
import Responsive from '@/components/Responsive.vue'
import StrategiesUpdated from '@/components/StrategiesUpdated.vue'
import NoStrategy from '@/components/Strategy/NoStrategy.vue'
import StrategyActions from '@/components/Strategy/StrategyActions.vue'
import StrategyPreview from '@/components/Preview/StrategyPreview.vue'
import Overview from '@/components/Layout/Overview.vue'

@Options({
  components: {
    Detail,
    StrategyDetail,
    Responsive,
    StrategiesUpdated,
    StrategyPreview,
    StrategyActions,
    NoStrategy,
    Overview,
  },
  props: ['code'],
})
export default class StrategyDetailView extends Vue {
  code!: string
  strategy: Strategy | null | undefined = null
  strategies: Strategy[] = []

  beforeMount() {
    this.init()
  }

  beforeUpdate() {
    this._updateStrategy()
  }

  init() {
    this.strategies = this.$store.state.portal.strategies
    this._updateStrategy()
  }

  onBack() {
    this.$router.push({ name: 'portfolio' })
  }

  private _updateStrategy() {
    if (this.strategies) {
      this.strategy = this.strategies.find((element) => element.code == this.code)
    }
  }
}
</script>

<style scoped lang="scss">
.p-button.p-button-icon-only.p-button-rounded {
  height: 1rem;
  width: 1rem;
}
</style>
