import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f1eaea5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "w-full flex items-center justify-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "w-full flex justify-end py-2 bdr px-4" }
const _hoisted_4 = { class: "pb-40" }
const _hoisted_5 = { class: "flex gap-2" }
const _hoisted_6 = { class: "px-4 py-6 w-full text-center text-h5 text-text-500" }
const _hoisted_7 = { class: "w-full flex justify-end py-4" }
const _hoisted_8 = { class: "flex gap-2" }
const _hoisted_9 = { class: "w-full text-center text-h5 text-text-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DeleteAgentButton = _resolveComponent("DeleteAgentButton")!
  const _component_AdminPreview = _resolveComponent("AdminPreview")!
  const _component_DataView = _resolveComponent("DataView")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Responsive = _resolveComponent("Responsive")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ProgressSpinner)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_Responsive, null, {
          mobile: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Button, {
                label: "Pridať agenta",
                icon: "pi pi-plus",
                outlined: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCreateAgent()))
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_DataView, {
                value: _ctx.agents,
                dataKey: "id"
              }, {
                list: _withCtx((slotProps) => [
                  _createVNode(_component_AdminPreview, {
                    title: slotProps.data.agentUser?.name,
                    subtitle: slotProps.data.email
                  }, {
                    actions: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _withDirectives(_createVNode(_component_Button, {
                          icon: "pi pi-users",
                          onClick: ($event: any) => (_ctx.openManageAgentClients(slotProps.data))
                        }, null, 8, ["onClick"]), [
                          [
                            _directive_tooltip,
                            'Manažment klientov',
                            void 0,
                            { bottom: true }
                          ]
                        ]),
                        _withDirectives(_createVNode(_component_Button, {
                          icon: "pi pi-pencil",
                          text: "",
                          onClick: ($event: any) => (_ctx.openUpdateAgent(slotProps.data))
                        }, null, 8, ["onClick"]), [
                          [
                            _directive_tooltip,
                            'Upraviť agenta',
                            void 0,
                            { bottom: true }
                          ]
                        ]),
                        _createVNode(_component_DeleteAgentButton, {
                          agent: slotProps.data
                        }, null, 8, ["agent"])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["title", "subtitle"])
                ]),
                empty: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, _toDisplayString((_ctx.agents ?? []).length <= 0 ? 'Žiadni agenti': 'Žiadne nájdené záznamy'), 1)
                ]),
                _: 1
              }, 8, ["value"])
            ])
          ]),
          desktop: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Button, {
                label: "Pridať agenta",
                icon: "pi pi-plus",
                outlined: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openCreateAgent()))
              })
            ]),
            _createVNode(_component_DataTable, { value: _ctx.agents }, {
              empty: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, _toDisplayString((_ctx.agents ?? []).length <= 0 ? 'Žiadni agenti': 'Žiadne nájdené záznamy'), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "agentUser.name",
                  header: "Meno",
                  sortable: true,
                  class: "font-semibold"
                }),
                _createVNode(_component_Column, {
                  field: "email",
                  header: "Email",
                  sortable: true,
                  class: "text-text-700"
                }),
                _createVNode(_component_Column, { header: "" }, {
                  body: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_8, [
                      _withDirectives(_createVNode(_component_Button, {
                        icon: "pi pi-users",
                        onClick: ($event: any) => (_ctx.openManageAgentClients(slotProps.data))
                      }, null, 8, ["onClick"]), [
                        [
                          _directive_tooltip,
                          'Manažment klientov',
                          void 0,
                          { bottom: true }
                        ]
                      ]),
                      _withDirectives(_createVNode(_component_Button, {
                        icon: "pi pi-pencil",
                        text: "",
                        onClick: ($event: any) => (_ctx.openUpdateAgent(slotProps.data))
                      }, null, 8, ["onClick"]), [
                        [
                          _directive_tooltip,
                          'Upraviť agenta',
                          void 0,
                          { bottom: true }
                        ]
                      ]),
                      _createVNode(_component_DeleteAgentButton, {
                        agent: slotProps.data
                      }, null, 8, ["agent"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        })
      ]))
}