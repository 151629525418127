import Config from '@/config'
import { handleAxiosError } from '@/errors/axiosErrors'
import { store } from '@/store'
import axios, { AxiosError, AxiosResponse, ResponseType } from 'axios'

const apiClient = axios.create({
  baseURL: `${Config.WSGI_PROTOCOL}://${Config.BASE_URL}/api`,
  headers: {
    Accept: 'application/json',
  },
})

apiClient.interceptors.request.use(
  (config) => {
    const token = store.state.user.accessToken
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    if (error instanceof AxiosError) {
      handleAxiosError(error)
    }
    return Promise.reject(error)
  }
)

export default class Api {
  static async get(url: string, responseType?: ResponseType): Promise<AxiosResponse> {
    return await apiClient.get(`${url}`, { responseType: responseType ?? 'json' })
  }

  static async post(url: string, data?: object): Promise<AxiosResponse> {
    return await apiClient.post(`${url}`, data)
  }

  static async put(url: string, data?: object): Promise<AxiosResponse> {
    return await apiClient.put(`${url}`, data)
  }

  static async delete(url: string, data?: object): Promise<AxiosResponse> {
    return await apiClient.delete(`${url}`, data)
  }
}
