import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-454890be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-h5 py-4" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between gap-4 text-regular font-normal pb-0.5"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = {
  key: 1,
  class: "mt-0.5 text-text-700"
}
const _hoisted_7 = {
  key: 2,
  class: "mt-2 text-text-700 text-regular"
}
const _hoisted_8 = {
  key: 3,
  class: "mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.leading || _ctx.trailing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            (_ctx.leading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.leading), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            (_ctx.trailing)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.trailing), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.subtitle), 1))
      : _createCommentVNode("", true),
    (_ctx.hasBodySlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "body", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasActionsSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}