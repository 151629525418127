<template>
  <Responsive>
    <template #mobile>
      <Button v-tooltip.bottom="'Spustiť dennú synchronizáciu'" icon="pi pi-sync" @click="dailySyncStrategies" :loading="loading" />
    </template>
    <template #desktop>
      <Button label="Spustiť dennú synchronizáciu" icon="pi pi-sync" @click="dailySyncStrategies" :loading="loading" />
    </template>
  </Responsive>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { useConfirm } from 'primevue/useconfirm'
import PortalApi from '@/api/modules/portal'
import AppToast from '@/toast'
import Responsive from '@/components/Responsive.vue'

@Options({
  components: {
    Responsive,
  },
  props: {},
})
export default class DailySyncStrategiesButton extends Vue {
  loading: boolean = false

  confirm = useConfirm()

  async beforeMount() {
    await this.init()
  }

  async init() {}

  dailySyncStrategies() {
    this.confirm.require({
      message: `Synchronizácia bude trvať niekolko minút, počas ktorých sa doťiahnú nové údaje klientov od posledného známeho dátumu až po včerajší dátum.<br><br>Naozaj chcete spustiť dennú synchronizáciu?`,
      header: 'Spustiť dennú synchronizáciu',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      accept: async () => {
        this.loading = true
        try {
          AppToast.info(null, 'Denná synchronizácia bola spustená')
          await PortalApi.syncStrategies()
        } finally {
          this.loading = false
        }
      },
    })
  }
}
</script>

<style scoped lang="scss"></style>
