<template>
  <div class="w-full">
    <img v-if="showDescription" :src="require('@/assets/bank.svg')" width="32" height="32" />
    <Responsive v-if="showDescription">
      <template #mobile>
        <div class="text-h4 font-semibold">Bankové účty na zasielanie investícií</div>
      </template>
      <template #desktop>
        <div class="text-h5 font-semibold">Bankové účty na zasielanie investícií</div>
      </template>
    </Responsive>
    <div class="text-regular mt-4">Na tieto bankové účty môžete zasielať finančné prostriedky určené na investovanie.</div>
    <div class="flex flex-col gap-1 mt-4">
      <div class="text-regular">Zvoľte menu:</div>
      <SelectButton v-model="currency" :options="currencyOptions" aria-labelledby="basic" />
    </div>
    <div class="mt-4 flex flex-col gap-1 pb-4">
      <div class="text-regular text-text-500 leading-none">Účet pre prevody v {{ currency }}</div>
      <Responsive>
        <template #mobile>
          <div class="flex gap-4 items-center justify-between cursor-pointer" @click="copyIban()">
            <div class="text-h5 font-semibold">
              {{ currency === 'EUR' ? 'SK83 7500 0000 0002 5515 3463' : 'SK22 7500 0000 0010 5502 3933' }}
            </div>
            <div class="w-6 h-6 flex items-center justify-center">
              <i class="pi pi-copy text-secondary-500" style="font-size: 1.25rem"></i>
            </div>
          </div>
        </template>
        <template #desktop>
          <div class="flex gap-2 items-center justify-between cursor-pointer" @click="copyIban()">
            <div class="text-regular font-bold">
              {{ currency === 'EUR' ? 'SK83 7500 0000 0002 5515 3463' : 'SK22 7500 0000 0010 5502 3933' }}
            </div>
            <div class="w-6 h-6 flex items-center justify-center">
              <i class="pi pi-copy text-secondary-500" style="font-size: 1.25rem"></i>
            </div>
          </div>
        </template>
      </Responsive>
      <div class="mt-2 flex flex-col gap-2 text-regular">
        <div class="flex gap-1 flex-col">
          <div class="text-text-500">Variabilný symbol:</div>
          <div>Vaše rodné číslo alebo IČO</div>
        </div>
        <div class="flex gap-1 flex-col">
          <div class="text-text-500">Špecifický symbol:</div>
          <div>Číslo komisionárskej zmluvy</div>
        </div>
        <div class="flex gap-1 flex-col">
          <div class="text-regular text-text-500">Konštantný symbol:</div>
          <div>558</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import AppToast from '@/toast'
import Responsive from '../Responsive.vue'

@Options({
  components: {
    Responsive,
  },
  props: {
    showDescription: {
      type: Boolean,
      required: false,
      nullable: false,
      default: true,
    },
  },
})
export default class Bank extends Vue {
  currency: string = 'EUR'
  currencyOptions: string[] = ['EUR', 'USD']

  showDescription!: boolean

  copyIban() {
    navigator.clipboard.writeText(this.currency === 'EUR' ? 'SK8375000000000255153463' : 'SK2275000000001055023933')
    AppToast.info(null, 'IBAN skopírovaný')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
