<template>
  <InlineMessage v-if="isDown" severity="warn">
    <div class="text-regular">Evidujeme technický problém s aktualizáciou údajov a pracujeme na jeho odstránení.</div>
  </InlineMessage>
</template>

<script lang="ts">
import { numDaysBetween } from '@/misc'
import { Strategy } from '@/models/portal'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    strategy: {
      type: Object as () => Strategy,
      required: true,
      nullable: false,
    },
  },
})
export default class CapitolDown extends Vue {
  strategy!: Strategy
  isDown: boolean = false

  beforeMount() {
    this.init()
  }

  init() {
    const strategy = this.strategy
    if (strategy && strategy.values) {
      const value = strategy.values[0]
      if (value && value.date) {
        const date = new Date(value.date)
        if (numDaysBetween(date, new Date()) > 5) {
            this.isDown = true
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
