import { AxiosError, HttpStatusCode } from 'axios'
import { CustomError } from './customError'

export class Error400 extends CustomError {
  constructor(message?: string | null) {
    super(message ?? 'Neplatný dopyt')
  }
}
export class Error401 extends CustomError {
  constructor(message?: string | null) {
    super(message ?? 'Neplatné prihlasovacie údaje')
  }
}
export class Error404 extends CustomError {
  constructor(message?: string | null) {
    super(message ?? 'Záznam sa nenašiel')
  }
}
export class Error409 extends CustomError {
  constructor(message?: string | null) {
    super(message ?? 'Konflikt')
  }
}

export function handleAxiosError(error: AxiosError) {
  const data = error.response?.data
  const message = data ? (typeof data === 'string' ? data : JSON.stringify(data)) : null
  switch (error.response?.status) {
    case HttpStatusCode.BadRequest:
      throw new Error400(message)
    case HttpStatusCode.Unauthorized:
      throw new Error401(message)
    case HttpStatusCode.NotFound:
      throw new Error404(message)
    case HttpStatusCode.Conflict:
      throw new Error409(message)
    default:
      throw error
  }
}
