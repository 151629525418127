import { Strategy, StrategyValuesResponse } from '@/models/portal'
import Api from '../api'

export default class PortalApi {
  static async getAllStrategies(): Promise<Strategy[]> {
    const res = await Api.get('portal/get-all-strategies/')
    return res.data
  }

  static async getAllUserStrategies(id: number): Promise<Strategy[]> {
    const res = await Api.get('portal/get-all-strategies/' + id)
    return res.data
  }

  static async getStrategyValues(code: string, interval: string, dateTo: Date): Promise<StrategyValuesResponse> {
    const res = await Api.get(`portal/get-strategy-values/${code}/${interval}/${dateTo.toISOString()}`)
    return res.data
  }

  static async getUserStrategyValues(code: string, interval: string, dateTo: Date, userId: number): Promise<StrategyValuesResponse> {
    const res = await Api.get(`portal/get-strategy-values/${code}/${interval}/${dateTo.toISOString()}/${userId}`)
    return res.data
  }

  static async getAgentPhoto(id: number): Promise<Blob> {
    const res = await Api.get('portal/get-agent-photo/' + id, 'blob')
    return res.data
  }

  static async deletePortalMessage(id: number): Promise<void> {
    await Api.delete('portal/delete-portal-message/' + id)
  }

  static async reSyncStrategies(portalUserId: number): Promise<void> {
    await Api.put('portal/re-sync-strategies/' + portalUserId)
  }

  static async syncStrategies(): Promise<void> {
    await Api.get('portal/sync-strategies/')
  }
}
