<template>
  <Responsive>
    <template #mobile>
      <div
        class="flex text-h5 font-bold gap-2 items-end leading-none w-full"
        :class="{
          'justify-between': !displayType || displayType == 'normal',
        }"
      >
        <div class="flex items-end gap-2">
          <div
            :class="{
              'text-h2': !displayType || displayType == 'normal',
              'text-h3': displayType == 'compact',
            }"
          >
            {{ currentValue }}
          </div>
          <div style="padding-bottom: 1px">€</div>
        </div>
        <div
          class="flex relative z-10"
          :class="{
            'text-danger': diffPercent < 0,
            'text-success': diffPercent >= 0,
            'text-h2': !displayType || displayType == 'normal',
          }"
        >
          <div>{{ diffPercent > 0 ? '+' : '' }}{{ diffPercent == 0 ? '0' : diffPercent.toLocaleString('sk-SK', { maximumFractionDigits: 2 }) }}</div>
          <div>%</div>
          <div v-if="!displayType || displayType == 'normal'" class="absolute right-0 -top-4">
            <div class="text-small font-normal text-text-500 whitespace-nowrap">Zmena za 24h</div>
          </div>
        </div>
      </div>
    </template>
    <template #desktop>
      <div
        :key="componentKey"
        class="flex text-h5 font-bold gap-2 items-end leading-none w-full"
        :class="{
          'justify-between': !displayType || displayType == 'normal',
        }"
      >
        <div class="flex items-end gap-2">
          <div
            :class="{
              'text-h1': !displayType || displayType == 'normal',
              'text-h4': displayType == 'compact',
            }"
          >
            {{ currentValue }}
          </div>
          <div :style="{ 'padding-bottom': !displayType || displayType == 'normal' ? '3px' : '0px' }">€</div>
        </div>
        <div
          class="flex relative z-10"
          :class="{
            'text-danger': diffPercent < 0,
            'text-success': diffPercent >= 0,
            'text-h2': !displayType || displayType == 'normal',
          }"
        >
          <div :style="{ 'padding-bottom': !displayType || displayType == 'normal' ? '1px' : '0px' }">
            {{ diffPercent > 0 ? '+' : '' }}{{ diffPercent == 0 ? '0' : diffPercent.toLocaleString('sk-SK', { maximumFractionDigits: 2 }) }}
          </div>
          <div>%</div>
          <div v-if="!displayType || displayType == 'normal'" class="change-label">
            <div class="text-small font-normal text-text-500 whitespace-nowrap">Zmena za 24h</div>
          </div>
        </div>
      </div>
    </template>
  </Responsive>
</template>

<script lang="ts">
import { calculateDiffPercent, formatCurrencyValue } from '@/misc'
import { Strategy } from '@/models/portal'
import { Options, Vue } from 'vue-class-component'
import Responsive from '../Responsive.vue'
import { ref } from 'vue'

export type DisplayType = 'normal' | 'compact'

@Options({
  props: {
    strategy: {
      type: Object as () => Strategy,
      required: true,
      nullable: false,
    },
    displayType: {
      type: String,
      required: false,
      nullable: false,
    },
  },
  components: {
    Responsive,
  },
})
export default class StrategyValue extends Vue {
  strategy!: Strategy

  currentValue!: string
  diffPercent!: number

  displayType!: DisplayType

  componentKey = Math.random()

  beforeMount() {
    this.init()
  }

  beforeUpdate() {
    this.init()
    this.componentKey = Math.random()
  }

  init() {
    const value1 = this._getValue(0)
    const value2 = this._getValue(1)
    this.currentValue = this.formatValue(value1)
    this.diffPercent = calculateDiffPercent(value1, value2)
  }

  formatValue(value: number): string {
    return formatCurrencyValue(value)
  }

  private _getValue(index: number): number {
    const values = this.strategy.values
    if (values && values.length > index) {
      const valueFin = values[index].valueFin
      const valuePort = values[index].valuePort
      return (valueFin ?? 0) + (valuePort ?? 0)
    }
    return 0
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.change-label {
  position: absolute;
  right: 0px;
  top: -22px;
}
</style>
