<template>
  <div class="text-h5 py-4">
    <div v-if="leading || trailing" class="flex items-center justify-between gap-4 text-regular font-normal pb-0.5">
      <div>
        <div v-if="leading">{{ leading }}</div>
      </div>
      <div>
        <div v-if="trailing">{{ trailing }}</div>
      </div>
    </div>
    <div class="font-semibold">{{ title }}</div>
    <div v-if="subtitle" class="mt-0.5 text-text-700">{{ subtitle }}</div>
    <div v-if="hasBodySlot" class="mt-2 text-text-700 text-regular"><slot name="body"></slot></div>
    <div v-if="hasActionsSlot" class="mt-3"><slot name="actions"></slot></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    leading: {
      type: String,
      required: false,
      nullable: true,
    },
    trailing: {
      type: String,
      required: false,
      nullable: true,
    },
    title: {
      type: String,
      required: false,
      nullable: true,
    },
    subtitle: {
      type: String,
      required: false,
      nullable: true,
    },
    description: {
      type: String,
      required: false,
      nullable: true,
    },
  },
  components: {},
})
export default class AdminPreview extends Vue {
  leading?: string | undefined | null
  trailing?: string | undefined | null
  title?: string | undefined | null
  subtitle?: string | undefined | null
  description?: string | undefined | null

  beforeMount() {
    this.init()
  }

  init() {}

  get hasBodySlot() {
    return !!this.$slots.body
  }

  get hasActionsSlot() {
    return !!this.$slots.actions
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bdr {
  border-bottom: 1px solid #d9d9d9;
}
</style>
