import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4b2f0b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid grid-cols-12 h-full w-full gap-5 px-6 pb-4"
}
const _hoisted_2 = { class: "col-span-3 h-full" }
const _hoisted_3 = { class: "w-full h-full flex flex-col pr-7" }
const _hoisted_4 = { class: "w-full grow flex flex-col gap-2 brd" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "w-fit pl-4 pb-2" }
const _hoisted_7 = { class: "w-full mt-4" }
const _hoisted_8 = { class: "col-span-9 h-full" }
const _hoisted_9 = { class: "w-full h-full" }
const _hoisted_10 = {
  key: 1,
  class: "w-full mt-4 px-6 flex flex-col items-center"
}
const _hoisted_11 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StrategyDetail = _resolveComponent("StrategyDetail")!
  const _component_Detail = _resolveComponent("Detail")!
  const _component_StrategyPreview = _resolveComponent("StrategyPreview")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StrategiesUpdated = _resolveComponent("StrategiesUpdated")!
  const _component_StrategyActions = _resolveComponent("StrategyActions")!
  const _component_NoStrategy = _resolveComponent("NoStrategy")!
  const _component_Overview = _resolveComponent("Overview")!
  const _component_Responsive = _resolveComponent("Responsive")!

  return (_openBlock(), _createBlock(_component_Responsive, null, {
    mobile: _withCtx(() => [
      _createVNode(_component_Detail, {
        back: "Portfóliá",
        onBack: _ctx.onBack,
        noPadding: true
      }, {
        default: _withCtx(() => [
          (_ctx.strategy)
            ? (_openBlock(), _createBlock(_component_StrategyDetail, {
                key: 0,
                strategy: _ctx.strategy
              }, null, 8, ["strategy"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onBack"])
    ]),
    desktop: _withCtx(() => [
      _createVNode(_component_Overview, null, {
        default: _withCtx(() => [
          (_ctx.strategies && _ctx.strategies.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.strategies, (str) => {
                        return (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_router_link, {
                            to: { name: 'strategy-detail', params: { code: str.code } }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_StrategyPreview, {
                                strategy: str,
                                selected: str === _ctx.strategy
                              }, null, 8, ["strategy", "selected"])
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]))
                      }), 256)),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_StrategiesUpdated, {
                          strategy: _ctx.strategies[0]
                        }, null, 8, ["strategy"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_StrategyActions, { showIban: true })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_StrategyDetail, { strategy: _ctx.strategy }, null, 8, ["strategy"])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_NoStrategy),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_StrategyActions, { showIban: false })
                ])
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}