import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import userModule from './modules/user'
import portalModule from './modules/portal'
import responsiveModule from './modules/responsive'
import adminModule from './modules/admin'
import VuexPersistence from "vuex-persist";
import { User } from '@/models/user';
import { Portal } from '@/models/portal';
import { Admin } from '@/models/admin';
import { Responsive } from '@/models/responsive'

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  modules: [
    'user',
    'portal',
    'responsive',
  ]
});

// define your typings for the store state
export interface State {
  user: User
  portal: Portal
  responsive: Responsive
  admin: Admin
  idleVue: any
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  modules: {
    user: userModule,
    portal: portalModule,
    responsive: responsiveModule,
    admin: adminModule,
  },
  plugins: [vuexLocal.plugin],
})

export function useStore() {
  return baseUseStore(key)
}
