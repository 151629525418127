<template>
  <Button :label="label" outlined severity="danger" :loading="loading" @click="remove()" />
</template>

<script lang="ts">
import { PortalUser } from '@/models/user'
import { Options, Vue } from 'vue-class-component'

type CallbackFunction = (client: PortalUser) => Promise<void>

@Options({
  props: {
    client: {
      type: Object as () => PortalUser,
      required: true,
      nullable: false,
    },
    label: {
      type: String,
      required: true,
      nullable: false,
    },
    removeClient: {
      type: Function,
      required: true,
      nullable: false,
    },
  },
})
export default class RemoveClientButton extends Vue {
  loading: boolean = false

  client!: PortalUser
  label!: string
  removeClient!: CallbackFunction

  async beforeMount() {
    await this.init()
  }

  async remove() {
    this.loading = true
    try {
      await this.removeClient(this.client)
    } finally {
      this.loading = false
    }
  }

  async init() {}
}
</script>

<style scoped lang="scss"></style>
