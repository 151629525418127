<template>
  <div class="w-full">
    <Responsive>
      <template #mobile>
        <div id="valueNullRef" class="w-full text-black inline">
          <div class="text-regular text-black pb-1 leading-none px-4">Hodnota portfólia</div>
          <div class="w-full px-4">
            <StrategyValue :strategy="strategy" />
          </div>
        </div>
        <div id="valueRef" class="w-full text-regular text-black leading-none hidden">
          <div class="pb-1 px-4"></div>
          <div class="w-full flex items-center gap-2 px-4">
            <div class="w-3 h-3 rounded-full bg-secondary-500"></div>
            <div class="text-h2 font-bold"></div>
            <div class="text-h5 font-semibold self-end" style="padding-bottom: 1px">€</div>
          </div>
        </div>
        <div v-if="loading" class="w-full flex items-center" style="height: 187px">
          <ProgressSpinner></ProgressSpinner>
        </div>
        <div v-else class="w-full">
          <div v-if="noData" class="w-full h-48 empty-container flex justify-center items-center text-center mt-10">
            <div class="text-h5 font-semibold text-black opacity-20 px-10">Nedostatok dát na zobrazenie vývoja portfólia</div>
          </div>
          <div v-else class="w-full touch-none" v-click-outside="clickOutside">
            <Chart ref="chart" type="line" :data="chartData" :options="chartOptions" :style="{ width: '100%' }" :plugins="chartPlugins" />
          </div>
        </div>
        <div v-if="!noData" class="flex items-center justify-between gap-4 pl-2.5 pr-4">
          <div class="flex">
            <SelectIntervalButton
              v-for="interval of intervalOptions"
              :label="intervalOptionsLabel[interval]"
              :active="selectedInterval == interval"
              @click="changeInterval(interval)"
              class="cursor-pointer"
            ></SelectIntervalButton>
          </div>
          <div class="flex gap-2 text-primary-900">
            <NavArrow :disabled="strategyValuesResponse?.last" @click="prev()">
              <i class="pi pi-arrow-left" style="font-size: 1rem"></i>
            </NavArrow>
            <NavArrow :disabled="strategyValuesResponse?.first" @click="next()">
              <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
            </NavArrow>
          </div>
        </div>
      </template>
      <template #desktop>
        <div style="max-width: 780px">
          <div id="valueNullRef" class="w-fit text-black flex flex-col">
            <div class="text-regular text-black pb-1 leading-none px-4">Hodnota portfólia</div>
            <div class="px-4 w-fit" style="min-width: 345px">
              <StrategyValue :strategy="strategy" />
            </div>
          </div>
          <div id="valueRef" class="w-full text-regular text-black leading-none hidden">
            <div class="pb-1 px-4"></div>
            <div class="w-full flex items-center gap-2 px-4">
              <div class="w-3 h-3 rounded-full bg-secondary-500"></div>
              <div class="text-h1 font-bold"></div>
              <div class="text-h5 font-semibold self-end" style="padding-bottom: 3px">€</div>
            </div>
          </div>
          <div v-if="loading" class="w-full flex items-center" style="height: 390px">
            <ProgressSpinner></ProgressSpinner>
          </div>
          <div v-else class="w-full">
            <div v-if="noData" class="w-full h-64 empty-container flex justify-center items-center text-center mt-14">
              <div class="text-h4 font-semibold text-black opacity-20">Nedostatok dát na zobrazenie vývoja portfólia</div>
            </div>
            <div v-else class="touch-none" v-click-outside="clickOutside">
              <Chart ref="chart" type="line" :data="chartData" :options="chartOptions" :style="{ width: '100%' }" :plugins="chartPlugins" />
            </div>
          </div>
          <div v-if="!noData" class="flex items-center justify-end gap-10 pl-2.5 pr-4">
            <div class="flex">
              <SelectIntervalButton
                v-for="interval of intervalOptions"
                :label="intervalOptionsLabel[interval]"
                :active="selectedInterval == interval"
                @click="changeInterval(interval)"
                class="cursor-pointer"
              ></SelectIntervalButton>
            </div>
            <div class="flex gap-2 text-primary-900">
              <NavArrow :disabled="strategyValuesResponse?.last" @click="prev()">
                <i class="pi pi-arrow-left" style="font-size: 1rem"></i>
              </NavArrow>
              <NavArrow :disabled="strategyValuesResponse?.first" @click="next()">
                <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
              </NavArrow>
            </div>
          </div>
        </div>
      </template>
    </Responsive>
  </div>
</template>

<script lang="ts">
import { addDays, formatCurrencyValue, formatDate } from '@/misc'
import { Strategy, StrategyValuesResponse } from '@/models/portal'
import { Options, Vue } from 'vue-class-component'
import zoomPlugin from 'chartjs-plugin-zoom'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import StrategyValue from './../StrategyValue.vue'
import SelectIntervalButton from './SelectIntervalButton.vue'
import PortalApi from '@/api/modules/portal'
import NavArrow from './NavArrow.vue'
import Responsive from '@/components/Responsive.vue'
import Chart from 'primevue/chart'

const days = ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So']

@Options({
  components: {
    StrategyValue,
    SelectIntervalButton,
    NavArrow,
    Responsive,
  },
  props: {
    strategy: {
      type: Object as () => Strategy,
      required: true,
      nullable: false,
    },
    userId: {
      type: Number,
      required: false,
      nullable: true,
    },
  },
})
export default class StrategyGraph extends Vue {
  strategy!: Strategy
  userId?: number | null | undefined
  strategyValuesResponse: StrategyValuesResponse | null = null
  loading: boolean = false

  chartData: any
  chartOptions: any
  chartPlugins: any

  labels: Date[] = []
  values: number[] = []
  noData: boolean = false
  min: number | null = 999999999
  max: number = 0
  minIndex: number | null = 0
  maxIndex: number = 0
  selectedInterval: string = 'month'

  intervalOptions: string[] = ['week', 'month', 'year', 'all']
  intervalOptionsLabel = {
    week: 'Týždeň',
    month: 'Mesiac',
    year: 'Rok',
    all: 'Všetko',
  }

  declare $refs: {
    chart: any
  }

  async beforeMount() {
    this._initStrategy()
  }

  isUpdated: boolean = false

  async beforeUpdate() {
    this._initStrategy()
  }

  async changeInterval(newInterval: string) {
    this.selectedInterval = newInterval
    await this._getData(new Date())
  }
  async next() {
    if (!this.strategyValuesResponse || this.strategyValuesResponse.first) {
      return
    }
    const values = this.strategyValuesResponse.values
    if (!values || values.length <= 0) {
      return
    }
    const date = values[0].date
    if (date) {
      var daysToAdd = 0
      switch (this.selectedInterval) {
        case 'week':
          daysToAdd = 7
          break
        case 'month':
          daysToAdd = 30
          break
        case 'year':
          daysToAdd = 365
          break
      }
      await this._getData(addDays(new Date(date), daysToAdd))
    }
  }
  async prev() {
    if (!this.strategyValuesResponse || this.strategyValuesResponse.last) {
      return
    }
    const values = this.strategyValuesResponse.values
    if (!values || values.length <= 0) {
      return
    }
    const date = values[values.length - 1].date
    if (date) {
      await this._getData(new Date(date))
    }
  }

  clickOutside() {
    const controler = this.$refs.chart
    const chart = controler.chart
    if (controler !== undefined && controler && chart !== undefined && chart && chart.getActiveElements().length !== 0) {
      chart.setActiveElements([])
      controler.refresh()
    }
  }

  private _initStrategy() {
    this.labels = []
    this.values = []
    this.min = 999999999
    this.max = 0
    this.minIndex = 0
    this.maxIndex = 0
    this.selectedInterval = 'month'
    this.strategyValuesResponse = {
      first: true,
      last: false,
      values: this.strategy.values ?? [],
    }
    this._initData()
    this._initGraph()
  }

  private async _getData(date: Date) {
    const code = this.strategy.code
    const interval = this.selectedInterval
    if (code) {
      this.loading = true
      try {
        this.strategyValuesResponse = this.userId
          ? await PortalApi.getUserStrategyValues(code, interval, date, this.userId)
          : await PortalApi.getStrategyValues(code, interval, date)
        this._initData()
        this._initGraph()
      } finally {
        this.loading = false
      }
    }
  }

  get strategies(): Strategy[] {
    return this.$store.state.portal.strategies ?? []
  }

  private _initData() {
    const values = this.strategyValuesResponse?.values
    if (values && values.length > 0) {
      this.noData = false
      this.min = 999999999
      this.max = 0
      this.values = values.map((element, index, array) => {
        const value = array[array.length - 1 - index]
        const finalValue = (value.valueFin ?? 0) + (value.valuePort ?? 0)
        // const finalValue = Math.random() * 20000
        if (finalValue < this.min!) {
          this.min = finalValue
          this.minIndex = index
        }
        if (finalValue >= this.max) {
          this.max = finalValue
          this.maxIndex = index
        }
        return finalValue
      })
      if (this.min === this.max) {
        this.min = null
        this.minIndex = null
      }
      this.labels = values.map((element, index, array) => {
        const date = array[array.length - 1 - index].date!
        return new Date(date)
      })
    } else {
      this.noData = true
    }
  }

  private _initGraph(): void {
    this.chartData = {
      labels: this.labels,
      datasets: [
        {
          label: 'Hodnota portfólia',
          data: this.values,
          pointRadius: 6,
          radius: 6,
          borderColor: '#290394',
          pointBorderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          hoverRadius: 6,
          pointHoverBackgroundColor: '#eab402',
          normalized: true,
          min: this.min,
          minIndex: this.minIndex,
          max: this.max,
          maxIndex: this.maxIndex,
        },
      ],
      responsive: true,
      maintainAspectRatio: false,
    }
    this.chartOptions = {
      animation: false,
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
      layout: {
        padding: {
          top: 36,
          bottom: 0,
          left: 0,
          right: 0,
        },
        margin: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      scales: {
        y: {
          // beginAtZero: true,
          ticks: {
            display: true,
          },
          display: false,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            padding: 32,
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 8,
            align: 'inner',
            callback: function (value: number, index: number, values: any[]) {
              if (values.length > 3) {
                if (index == 0 || index == values.length - 1) {
                  return ''
                }
              }
              return formatDate(new Date((this as any).getLabelForValue(value)), true, true)
            },
          },
          afterFit: (axis: any) => {
            axis.paddingRight = 12
            axis.paddingLeft = 12
          },
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          color: '#6c757d',
          align: function (context: any) {
            const dataset = context?.dataset
            const index = context?.dataIndex
            const n = dataset.data.length - 1
            const minIndex = dataset?.minIndex
            const maxIndex = dataset?.maxIndex
            if (index == minIndex) {
              if (minIndex == 0) {
                return 45
              } else if (minIndex == n) {
                return -225
              } else {
                return 'bottom'
              }
            }
            if (index == maxIndex) {
              if (maxIndex == 0) {
                return -45
              } else if (maxIndex == n) {
                return 225
              } else {
                return 'top'
              }
            }
            return 'top'
          },
          clip: false,
          clamp: true,
          display: function (context: any) {
            const dataset = context?.dataset
            const index = context?.dataIndex
            const minIndex = dataset?.minIndex
            const maxIndex = dataset?.maxIndex
            return index == minIndex || index == maxIndex
          },
          offset: function (context: any) {
            const dataset = context?.dataset
            const index = context?.dataIndex
            const n = dataset.data.length - 1
            const minIndex = dataset?.minIndex
            const maxIndex = dataset?.maxIndex
            if (index == minIndex) {
              if (minIndex == 0) {
                return 16
              } else {
                return 10
              }
            }
            if (index == maxIndex) {
              if (maxIndex == n) {
                return 16
              } else {
                return 10
              }
            }
            return 10
          },
          formatter: function (value: number, context: any) {
            return formatCurrencyValue(value) + ' €'
          },
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: false,
            },
          },
          limits: {
            y: { min: 0, max: 'original' },
          },
          pan: {
            enabled: false,
            mode: 'xy',
          },
        },
      },
    }
    this.chartPlugins = [
      {
        id: 'hoverValue',
        beforeRender(chart: any, args: any, pluginOptions: any) {
          const { ctx, data, options } = chart
          const activeElements = chart.getActiveElements()
          const el = activeElements.at(0)
          const index = el?.element?.$context?.index
          const value = data?.datasets?.at(0)?.data?.at(index)
          const date = data?.labels?.at(index)
          const valueRef = document.getElementById('valueRef')
          const valueNullRef = document.getElementById('valueNullRef')
          if (valueRef && valueNullRef) {
            if (value && activeElements.length > 0) {
              valueNullRef.style.display = 'none'
              valueRef.style.display = 'inline'
              const labelNode = valueRef.children[0]
              const valueNode = valueRef.children[1].children[1]
              const d = new Date(date)
              labelNode.textContent = `${formatDate(d, true)}, ${days[d.getDay()]}`
              valueNode.textContent = `${value.toLocaleString('sk-SK', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
            } else {
              valueNullRef.style.display = 'inline'
              valueRef.style.display = 'none'
            }
          }
        },
      },
      zoomPlugin,
      ChartDataLabels,
    ]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
