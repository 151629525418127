import { app } from './main'

export default class AppToast {
  static error(title: string, info: string) {
    app.config.globalProperties.$toast.add({
      severity: 'error',
      summary: title,
      detail: info,
      life: 4000,
    })
  }

  static info(title: string | null | undefined, info?: string | null | undefined) {
    app.config.globalProperties.$toast.add({
      severity: 'info',
      summary: title ?? undefined,
      detail: info,
      life: 4000,
    })
  }
}
