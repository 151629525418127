<template>
  <Detail back="Profil" :onBack="onBack">
    <div class="py-5 text-h4 font-bold w-full">Zmena hesla</div>
    <ChangePasswordForm @onSuccess="onBack"></ChangePasswordForm>
  </Detail>
</template>

<script lang="ts">
import ChangePasswordForm from '@/components/ChangePassword.vue'
import Detail from '@/components/Layout/Detail.vue'
import { Error400, Error401 } from '@/errors/axiosErrors'
import AppToast from '@/toast'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Detail,
    ChangePasswordForm,
  },
})
export default class ChangePasswordView extends Vue {
  loading: boolean = false
  disabled: boolean = true
  error: boolean = false

  oldPassword: string = ''
  newPassword: string = ''

  mediumRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')
  strongRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')

  beforeMount() {
    this.init()
  }

  init() {}

  async confirm() {
    if (this.loading) {
      return
    }
    this.error = false
    this.loading = true
    try {
      try {
        await this.$store.dispatch('user/changePassword', { oldPassword: this.oldPassword, password: this.newPassword })
      } catch (e) {
        if (e instanceof Error401 || e instanceof Error400) {
          this.error = true
          return
        }
        throw e
      }
      this.onBack()
      AppToast.info(null, 'Heslo bolo zmenené')
    } finally {
      this.loading = false
    }
  }

  onUpdate(value: string) {
    const valid = (this.newPassword.match(this.mediumRegex) || this.newPassword.match(this.strongRegex)) && this.newPassword != this.oldPassword
    if (this.disabled && valid) {
      this.disabled = false
    } else if (!this.disabled && !valid) {
      this.disabled = true
    }
  }

  onBack() {
    this.$router.push({ name: 'profile' })
  }
}
</script>

<style scoped lang="scss"></style>
