import { PortalUser } from './models/user'

export function calculateDiffPercent(num1: number, num2: number): number {
  const diff = num1 - num2
  if (diff === num1) return 0
  var difference = (diff / num2) * 100
  return Math.round(100 * (difference + Number.EPSILON)) / 100 ?? 0
}

export function formatDate(date: Date | null | undefined, noTime: boolean = false, noYear: boolean = false): string | null {
  if (!date) {
    return null
  }
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hour = date.getHours()
  const minute = date.getMinutes()
  if (noTime) {
    if (noYear) {
      return `${day}.${month}`
    }
    return `${day}.${month}.${year}`
  }
  if (noYear) {
    return `${day}.${month} ${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`
  }
  return `${day}.${month}.${year} ${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`
}

export function formatPhoneNumber(number: string | null | undefined): string {
  if (!number) {
    return ''
  }
  var retval = ''
  retval = number.substring(0, 4)
  retval += ' ' + number.substring(4, 7)
  retval += ' ' + number.substring(7, 10)
  retval += ' ' + number.substring(10, 13)
  return retval
}

export function formatCurrencyValue(value: number): string {
  if (value % 1 == 0) {
    return value.toLocaleString('sk-SK', { maximumFractionDigits: 2 })
  } else {
    return value.toLocaleString('sk-SK', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
  }
}

export function addDays(date: Date, days: number): Date {
  date.setDate(date.getDate() + days)
  return date
}

export function getBase64(blob: Blob): Promise<string> {
  const temporaryFileReader = new FileReader()
  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }
    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result as string)
    }
    temporaryFileReader.readAsDataURL(blob)
  })
}

export function delay(ms: number) {
  return new Promise((res) => setTimeout(res, ms))
}

export function isDST(d: Date) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset()
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(jan, jul) !== d.getTimezoneOffset()
}

export function fallbackString(value: string | null | undefined): string {
  if (value === undefined || value === null) {
    return 'údaj dočasne nedostupný'
  }
  return value
}

export function adminClientDisplay(user: PortalUser): string {
  var prepend = ''
  const fullName = user.omniumUser?.fullName
  if (fullName !== undefined && fullName !== null) {
    prepend = `<span style="font-weight: 600">${fullName}</span> - `
  }
  return prepend + fallbackString(user.email)
}

export function numDaysBetween(d1: Date, d2: Date) {
  var diff = Math.abs(d1.getTime() - d2.getTime())
  return diff / (1000 * 60 * 60 * 24)
}

export function inflect(num: number, zero: string, one: string, twoToFour: string, many: string) {
  switch (num) {
    case 0:
      return zero
    case 1:
      return one
    case 2:
      return twoToFour
    case 3:
      return twoToFour
    case 4:
      return twoToFour
    default:
      return many
  }
}

export function shortName(fullName?: string | null | undefined): string | null {
  if (fullName) {
    const split = fullName.split(' ')
    if (split.length > 1) {
      return `${split[split.length - 1]} ${split[0].charAt(0)}.`
    } else {
      return fullName
    }
  }
  return null
}
