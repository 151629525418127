<template>
  <Overview>
    <template #heading> Profil </template>
    <div class="w-full" :class="{ 'flex flex-col items-center': desktop }">
      <div class="px-2" :class="{ 'w-[calc(375px)]': desktop, 'w-full': !desktop }">
        <div class="text-h4 font-semibold w-full" :class="{ 'mt-4': !desktop }">Moje údaje</div>
        <div class="w-full mt-6">
          <ProfileItem label="Meno a priezvisko">
            <div class="font-semibold">{{ fallback(omniumUser?.fullName) }}</div>
          </ProfileItem>
          <ProfileItem label="Telefónne číslo" class="mt-4">
            <div class="font-semibold">{{ phone }}</div>
          </ProfileItem>
          <ProfileItem label="E-mail" class="mt-4">
            <div class="flex items-center gap-2 relative w-fit">
              <div class="font-semibold">{{ portalUser?.email }}</div>
              <div
                class="w-fit cursor-pointer flex items-center justify-center"
                v-tooltip.bottom.focus="'Tento e-mail je aj Vašim prihlasovacím menom do portálu. Ak si ho prajete zmeniť, kontaktujte nás.'"
                tabindex="1"
              >
                <i class="pi pi-info-circle text-primary-900" style="font-size: 1rem"></i>
              </div>
            </div>
          </ProfileItem>
          <div class="mt-0.5 w-full">
            <router-link :to="{ name: 'change-password' }">
              <div class="font-semibold text-primary-900 text-regular py-4 underline">Zmeniť prihlasovacie heslo</div>
            </router-link>
          </div>
          <div class="w-full shrink-0">
            <div class="brd w-full mt-2 mb-6"></div>
            <Bank></Bank>
          </div>
        </div>
      </div>
    </div>
  </Overview>
</template>

<script lang="ts">
import Overview from '@/components/Layout/Overview.vue'
import Bank from '@/components/Profile/Bank.vue'
import ProfileItem from '@/components/Profile/ProfileItem.vue'
import { fallbackString, formatPhoneNumber } from '@/misc'
import { PortalUser, OmniumUser } from '@/models/user'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Overview,
    ProfileItem,
    Bank,
  },
})
export default class UserProfileView extends Vue {
  mounted() {
    this.init()
  }

  init() {}

  fallback(value: string | null | undefined) {
    return fallbackString(value)
  }

  get portalUser(): PortalUser | null | undefined {
    return this.$store.state.user.portalUser
  }

  get omniumUser(): OmniumUser | null | undefined {
    return this.portalUser?.omniumUser
  }

  get phone(): string {
    if (this.omniumUser?.phone === undefined || this.omniumUser?.phone === null) {
      return fallbackString(this.omniumUser?.phone)
    }
    return formatPhoneNumber(this.omniumUser?.phone)
  }

  get desktop() {
    return !this.$store.state.responsive.mobile
  }
}
</script>

<style scoped lang="scss"></style>
