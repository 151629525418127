<template>
  <Responsive>
    <template #mobile>
      <Overview>
        <template #heading> Portfóliá </template>
        <div v-if="strategies && strategies.length > 0" class="w-full">
          <div v-for="strategy in strategies" class="w-full">
            <router-link :to="{ name: 'strategy-detail', params: { code: strategy.code } }">
              <StrategyPreview :strategy="strategy" />
            </router-link>
          </div>
          <div class="mt-4"><StrategiesUpdated :strategy="strategies[0]" /></div>
        </div>
        <div v-else class="w-full mt-4 px-6">
          <NoStrategy></NoStrategy>
        </div>
        <div class="w-full mt-10">
          <StrategyActions :showIban="strategies && strategies.length > 0"></StrategyActions>
        </div>
        <div v-if="strategies && strategies.length > 0" class="w-fit mt-6"><CapitolDown :strategy="strategies[0]" /></div>
      </Overview>
    </template>
    <template #desktop>
      <Overview>
        <div v-if="strategies && strategies.length > 0" class="grid grid-cols-12 h-full w-full gap-5 px-6 pb-4">
          <div class="col-span-3 h-full">
            <div class="w-full h-full flex flex-col pr-7">
              <div class="w-full grow flex flex-col gap-2 brd">
                <div v-for="str in strategies" class="w-full">
                  <router-link :to="{ name: 'strategy-detail', params: { code: str.code } }">
                    <StrategyPreview :strategy="str" :selected="str === strategies[0]" />
                  </router-link>
                </div>
                <div class="w-fit grow pl-4 pb-2"><StrategiesUpdated :strategy="strategies[0]" /></div>
                <div class="w-fit pb-6"><CapitolDown :strategy="strategies[0]" /></div>
              </div>
              <div class="w-full mt-4">
                <StrategyActions :showIban="true"></StrategyActions>
              </div>
            </div>
          </div>
          <div class="col-span-9 h-full">
            <div class="w-full h-full">
              <StrategyDetail :strategy="strategies[0]"> </StrategyDetail>
            </div>
          </div>
        </div>
        <div v-else class="w-full mt-4 px-6 flex flex-col items-center">
          <NoStrategy></NoStrategy>
          <div class="mt-4">
            <StrategyActions :showIban="false"></StrategyActions>
          </div>
        </div>
      </Overview>
    </template>
  </Responsive>
</template>

<script lang="ts">
import CapitolDown from '@/components/CapitolDown.vue'
import Overview from '@/components/Layout/Overview.vue'
import StrategyPreview from '@/components/Preview/StrategyPreview.vue'
import Responsive from '@/components/Responsive.vue'
import StrategiesUpdated from '@/components/StrategiesUpdated.vue'
import NoStrategy from '@/components/Strategy/NoStrategy.vue'
import StrategyActions from '@/components/Strategy/StrategyActions.vue'
import StrategyDetail from '@/components/Strategy/StrategyDetail.vue'
import { Strategy } from '@/models/portal'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Responsive,
    Overview,
    StrategyDetail,
    StrategyPreview,
    StrategiesUpdated,
    NoStrategy,
    StrategyActions,
    CapitolDown,
  },
})
export default class PortfolioView extends Vue {
  strategies?: Strategy[]

  beforeMount() {
    this.init()
  }

  init() {
    this.strategies = this.$store.state.portal.strategies
  }
}
</script>

<style scoped lang="scss"></style>
