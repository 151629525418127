import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import PrimeVue from 'primevue/config'
import IdleVue from 'idle-vue'

//theme
import './styles/theme.css'

//imports
import './index.css'
import './styles/global.scss'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'

import AppToast from './toast'

//components
import ProgressSpinner from 'primevue/progressspinner'
import DynamicDialog from 'primevue/dynamicdialog'
import InlineMessage from 'primevue/inlinemessage'
import ConfirmDialog from 'primevue/confirmdialog'
import SelectButton from 'primevue/selectbutton'
import ToastService from 'primevue/toastservice'
import FileUpload from 'primevue/fileupload'
import InputText from 'primevue/inputtext'
import DataView from 'primevue/dataview'
import TabPanel from 'primevue/tabpanel'
import Password from 'primevue/password'
import Checkbox from 'primevue/checkbox'
import TabView from 'primevue/tabview'
import Sidebar from 'primevue/sidebar'
import TabMenu from 'primevue/tabmenu'
import Tooltip from 'primevue/tooltip'
import Divider from 'primevue/divider'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Editor from 'primevue/editor'
import Chart from 'primevue/chart'
import Toast from 'primevue/toast'

import DialogService from 'primevue/dialogservice'
import ConfirmationService from 'primevue/confirmationservice'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Config from './config'
import { clickOutside } from './directives/click-outside'

export const app = createApp(App)

app.config.errorHandler = function (err, vm, info) {
  AppToast.error((err as Error).name, (err as Error).message)
  console.error(err)
}

app.use(store, key)
app.use(router)
app.use(PrimeVue, {
  locale: {
    accept: 'Áno',
    reject: 'Nie',
  },
})
app.use(ToastService)
app.use(DialogService)
app.use(ConfirmationService)
app.use(IdleVue, {
  store,
  idleTime: Config.IDLE_TIME,
  startAtIdle: false,
})

app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)

app.component('Toast', Toast)
app.component('Chart', Chart)
app.component('Editor', Editor)
app.component('Dialog', Dialog)
app.component('Button', Button)
app.component('Divider', Divider)
app.directive('Tooltip', Tooltip)
app.component('TabMenu', TabMenu)
app.component('Sidebar', Sidebar)
app.component('TabView', TabView)
app.component('Checkbox', Checkbox)
app.component('Password', Password)
app.component('TabPanel', TabPanel)
app.component('DataView', DataView)
app.component('InputText', InputText)
app.component('FileUpload', FileUpload)
app.component('SelectButton', SelectButton)
app.directive('click-outside', clickOutside)
app.component('ConfirmDialog', ConfirmDialog)
app.component('InlineMessage', InlineMessage)
app.component('DynamicDialog', DynamicDialog)
app.component('ProgressSpinner', ProgressSpinner)

app.mount('#app')
