import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full h-14 px-6 flex gap-4 items-center cursor-pointer hover:bg-white hover:bg-opacity-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass('pi ' + _ctx.icon),
      style: {"font-size":"1rem"}
    }, null, 2),
    _createElementVNode("div", null, _toDisplayString(_ctx.name), 1),
    (_ctx.trailingIcon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass('pi ' + _ctx.trailingIcon),
          style: {"font-size":"0.75rem"}
        }, null, 2))
      : _createCommentVNode("", true)
  ]))
}