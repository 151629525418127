import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2" }
const _hoisted_2 = { class: "font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full h-14 px-6 flex gap-4 border-white border-opacity-40 items-center cursor-pointer", { 'text-secondary-500 bg-lightPurple bg-opacity-10': _ctx.active, 'text-white': !_ctx.active }])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("i", {
        class: _normalizeClass('pi ' + _ctx.icon),
        style: {"font-size":"1rem"}
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1),
      (_ctx.count && _ctx.count > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["count-avatar text-primary-900 h-5 text-regular font-bold", { 'w-6': _ctx.count > 9, 'w-5': _ctx.count <= 9 }]),
            style: {"padding-right":"0.5px","padding-top":"0.5px"}
          }, _toDisplayString(_ctx.count > 9 ? 9 : _ctx.count) + _toDisplayString(_ctx.count > 9 ? '+' : ''), 3))
        : _createCommentVNode("", true)
    ])
  ], 2))
}