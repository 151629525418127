import { ActionContext } from 'vuex'
import { State } from '..'
import { PortalUser, User } from '@/models/user'
import UserApi from '@/api/modules/user'
import { Error401 } from '@/errors/axiosErrors'

export default {
  namespaced: true,
  state: (): User => ({ registered: false, portalUser: {} }),
  mutations: {
    login(user: User, { access, refresh }) {
      user.accessToken = access
      user.refreshToken = refresh
    },
    logout(user: User) {
      user.accessToken = undefined
      user.refreshToken = undefined
      user.registered = false
      user.portalUser = {}
      sessionStorage.clear()
    },
    loginOmniumUser(user: User, { registered, email }) {
      user.registered = registered
      user.portalUser.email = email
    },
    setCurrentUser(user: User, portalUser: PortalUser) {
      user.portalUser = portalUser
    },
    setRefreshToken(user: User, refreshToken: string) {
      user.refreshToken = refreshToken
    },
  },
  actions: {
    async login(context: ActionContext<User, State>, { email, password }) {
      const data = await UserApi.login(email, password)
      context.commit('login', data)
      await context.dispatch('initUser')
    },
    async logout(context: ActionContext<User, State>) {
      try {
        await UserApi.logout()
      } catch (e) {
        if (e instanceof Error401) {
          return
        }
        throw e
      } finally {
        context.commit('logout')
      }
    },
    async tokenRefresh(context: ActionContext<User, State>) {
      const token = context.state.refreshToken
      if (token) {
        const data = await UserApi.tokenRefresh(token)
        context.commit('login', data)
      }
    },
    async changePassword(context: ActionContext<User, State>, { oldPassword, password }) {
      await UserApi.changePassword(oldPassword, password)
    },
    async loginOmniumUser(context: ActionContext<User, State>, { webname, password }) {
      const data = await UserApi.loginOmniumUser(webname, password)
      context.commit('loginOmniumUser', data)
    },
    async registerClient(context: ActionContext<User, State>, { webname, password, portalPassword }) {
      await UserApi.registerClient(webname, password, portalPassword)
    },
    async getCurrentUser(context: ActionContext<User, State>) {
      const data = await UserApi.getCurrentUser()
      context.commit('setCurrentUser', data)
    },
    async initUser(context: ActionContext<User, State>) {
      await context.dispatch('getCurrentUser')
      await context.dispatch('portal/getAllStrategies', null, { root: true })
      await context.dispatch('portal/getUnseenMessageCount', null, { root: true })
    },
  },
  getters: {
    isLoggedIn(state: User): boolean {
      return state.accessToken !== null && state.accessToken !== undefined && state.refreshToken !== null && state.refreshToken !== undefined
    },
    isStaff(state: User, getters: any): boolean {
      return getters.isAgent || getters.isAdmin
    },
    isAdmin(state: User): boolean {
      return state.portalUser.isAdmin !== null && state.portalUser.isAdmin !== undefined && state.portalUser.isAdmin
    },
    isAgent(state: User): boolean {
      return state.portalUser.agentUser !== null && state.portalUser.agentUser !== undefined
    },
    isClient(state: User): boolean {
      return state.portalUser.omniumUser !== null && state.portalUser.omniumUser !== undefined
    },
  },
}
