<template>
  <Overview>
    <template #heading> Kontakty </template>
    <div class="w-full px-2" :class="{ 'flex flex-col items-center': desktop, 'mt-4': !desktop }">
      <div :class="{ 'w-[calc(375px)]': desktop, 'w-full': !desktop }">
        <div v-if="agent" class="sdw w-full flex flex-col items-center p-6 rounded-lg mb-10">
          <div class="w-24 h-24 rounded-full bg-border flex items-center justify-center leading-none">
            <i v-if="!photoBase64" class="pi pi-user" style="font-size: 2rem"></i>
            <img v-else :src="photoBase64" width="96" height="96" style="object-fit: cover; border-radius: 10000px" />
          </div>
          <div class="text-h4 font-bold mt-2">{{ agent.name }}</div>
          <div class="text-regular text-secondary-500 font-semibold">{{ agent.role ?? 'Váš portfólio manažér' }}</div>
          <div class="mt-10 flex flex-col gap-3 w-full">
            <a :href="'mailto:' + (agent.email ?? 'info@omniumcapital.sk')" target="_blank" rel="noreferrer" class="hover-agent-info w-fit">
              <div class="flex gap-3 items-center text-regular font-semibold">
                <i class="pi pi-envelope" style="font-size: 1rem"></i>
                <div>{{ agent.email ?? 'info@omniumcapital.sk' }}</div>
              </div>
            </a>
            <a :href="'tel:' + phone(false)" target="_blank" rel="noreferrer" class="hover-agent-info w-fit">
              <div class="w-full flex gap-3 items-center text-regular font-semibold">
                <i class="pi pi-phone" style="font-size: 1rem"></i>
                <div>{{ phone(true) }}</div>
              </div>
            </a>
          </div>
        </div>
        <div>
          <img :src="require('@/assets/logo-primary.svg')" width="174" />
        </div>
        <div class="flex flex-col gap-3 mt-6 pb-4">
          <div class="flex gap-3 text-regular text-black">
            <div><i class="pi pi-map-marker" style="font-size: 1rem"></i></div>
            <div>
              <div class="font-semibold">Omnium Capital, a.s. o.c.p.</div>
              <div>Potočná 169/85 909 01 Skalica</div>
              <div class="mt-1 text-text-500">Na tejto adrese po dohode radi privítame našich klientov pri osobnom stretnutí.</div>
            </div>
          </div>
          <a href="mailto:info@omniumcapital.sk" target="_blank" rel="noreferrer" class="hover:underline w-fit">
            <div class="flex gap-3 text-regular items-center leading-none">
              <div><i class="pi pi-envelope" style="font-size: 1rem"></i></div>
              <div class="font-semibold">info@omniumcapital.sk</div>
            </div>
          </a>
          <a href="tel:02/70770701" target="_blank" rel="noreferrer" class="hover:underline w-fit">
            <div class="flex gap-3 text-regular items-center leading-none">
              <div><i class="pi pi-phone" style="font-size: 1rem"></i></div>
              <div class="font-semibold">02/707 707 01</div>
            </div>
          </a>
          <a href="https://www.omniumcapital.sk" target="_blank" rel="noreferrer" class="hover:underline w-fit">
            <div class="flex gap-3 text-regular items-center leading-none">
              <div><i class="pi pi-globe" style="font-size: 1rem"></i></div>
              <div class="font-semibold flex gap-1">
                <div>www.omniumcapital.sk</div>
                <div class="text-secondary-500"><i class="pi pi-arrow-up-right" style="font-size: 0.7rem"></i></div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </Overview>
</template>

<script lang="ts">
import PortalApi from '@/api/modules/portal'
import Overview from '@/components/Layout/Overview.vue'
import { Error404 } from '@/errors/axiosErrors'
import { formatPhoneNumber, getBase64 } from '@/misc'
import { Agent, OmniumUser, PortalUser } from '@/models/user'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Overview,
  },
})
export default class ContactView extends Vue {
  photoBase64: string | null = null

  async beforeMount() {
    await this.init()
  }

  async init() {
    if (this.agent && this.agent.id && this.agent.photo && this.agent.photo.length > 0) {
      try {
        this.photoBase64 = await getBase64(await PortalApi.getAgentPhoto(this.agent.id))
      } catch (e) {
        if (e instanceof Error404) {
          return
        }
        throw e
      }
    }
  }

  get portalUser(): PortalUser | null | undefined {
    return this.$store.state.user.portalUser
  }

  get omniumUser(): OmniumUser | null | undefined {
    return this.portalUser?.omniumUser
  }

  get agent(): Agent | null | undefined {
    return this.omniumUser?.agent
  }

  phone(format: boolean): string | null | undefined {
    if (format) {
      return formatPhoneNumber(this.agent?.phone)
    }
    return this.agent?.phone
  }

  get desktop() {
    return !this.$store.state.responsive.mobile
  }
}
</script>

<style scoped lang="scss">
.sdw {
  -webkit-box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}

.p-avatar.p-avatar-xl {
  width: 6rem;
  height: 6rem;
  font-size: 4rem;
}

a {
  color: #000;
}

.hover-agent-info:hover > div > div {
  text-decoration: underline;
}
</style>
