<template>
  <div v-if="ignoreResponsive" class="w-full flex flex-col items-center h-full">
    <div class="flex flex-col w-full h-12 bg-primary-100 text-primary-900 justify-center items-center flex-shrink-0">
      <div class="px-4 w-[calc(375px)]">
        <div class="text-h5 font-semibold">
          <slot name="heading"></slot>
        </div>
      </div>
    </div>
    <div class="w-[calc(375px)] px-2 flex flex-col items-center pb-4 grow">
      <slot></slot>
    </div>
  </div>
  <Responsive v-else>
    <template #mobile>
      <div class="w-full flex flex-col items-center h-full">
        <div class="flex flex-col w-full h-12 bg-primary-100 text-primary-900 justify-center items-center flex-shrink-0">
          <div class="px-4 w-[calc(375px)]">
            <div class="text-h5 font-semibold">
              <slot name="heading"></slot>
            </div>
          </div>
        </div>
        <div class="w-[calc(375px)] px-2 flex flex-col items-center pb-4 grow">
          <slot></slot>
        </div>
      </div>
    </template>
    <template #desktop>
      <div class="w-full flex justify-center h-full">
        <div style="max-width: 1400px" class="h-full w-full pt-6 flex flex-col">
          <div v-if="hasHeadingSlot" class="pb-8 w-full text-h2 font-semibold px-6 leading-none h-[calc(60px)]">
            <slot name="heading"></slot>
          </div>
          <div class="w-full" :class="{ 'h-[calc(100%-60px)]': hasHeadingSlot, 'h-full': !hasHeadingSlot }">
            <slot></slot>
          </div>
        </div>
      </div>
    </template>
  </Responsive>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Responsive from '../Responsive.vue'

@Options({
  components: {
    Responsive,
  },
  props: {
    ignoreResponsive: {
      type: Boolean,
      required: false,
      nullable: false,
      default: false,
    },
  },
})
export default class Overview extends Vue {
  ignoreResponsive!: boolean

  async mounted() {
    await this.init()
  }

  async init() {}

  get hasHeadingSlot() {
    return !!this.$slots.heading
  }
}
</script>

<style scoped lang="scss"></style>
