<template>
  <Detail>
    <template #trailing>
      <router-link :to="{ name: 'login' }">
        <div
          class="w-8 h-8 rounded-full bg-primary-100 cursor-pointer flex items-center justify-center text-primary-900 leading-none"
        >
          <i class="pi pi-times" style="font-size: 0.75rem"></i>
        </div>
      </router-link>
    </template>
    <div class="w-full h-full flex flex-col">
      <div class="text-h4 font-bold my-5">Skontrolujte si e-mail</div>
      <div class="text-regular">Na Váš e-mail sme zaslali odkaz na obnovu hesla. Platnosť odkazu je 5 hodín.</div>
    </div>
  </Detail>
</template>

<script lang="ts">
import Detail from '@/components/Layout/Detail.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Detail,
  },
})
export default class ResetPasswordSuccessView extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
