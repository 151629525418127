<template>
  <CustomDialog title="Manažment klientov" routeOnClose="agents">
    <div v-if="loading" class="w-full h-full flex items-center justify-center">
      <ProgressSpinner></ProgressSpinner>
    </div>
    <ClientsSelect
      v-else
      leftTabTitle="Všetci klienti"
      rightTabTitle="Agentovi klienti"
      addButtonLabel="Priradiť agentovi"
      removeButtonLabel="Odobrať agentovi"
      :leftTabClients="leftTabClients"
      :rightTabClients="rightTabClients"
      :leftTabCallback="addClient"
      :rightTabCallback="removeClient"
      :activeIndex="1"
      leftTabMessageWhenEmpty="Žiadni dostupní klienti"
      rightTabMessageWhenEmpty="Žiadni priradení klienti"
    >
    </ClientsSelect>
  </CustomDialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import UserApi from '@/api/modules/user'
import { PortalUser } from '@/models/user'
import AppToast from '@/toast'
import CustomDialog from '@/components/Dialog/CustomDialog.vue'
import ClientsSelect from '@/components/ClientSelect/ClientsSelect.vue'

@Options({
  components: {
    CustomDialog,
    ClientsSelect,
  },
  props: ['id'],
})
export default class AgentManageView extends Vue {
  id!: number

  agent?: PortalUser

  leftTabClients: PortalUser[] = []
  rightTabClients: PortalUser[] = []

  loading: boolean = false

  async beforeMount() {
    await this.init()
  }

  async init() {
    this.loading = true
    this.agent = this.agents.find((element) => element.id == this.id)
    if (!this.agent?.agentUser) {
      await this.close()
    }
    try {
      await this.$store.dispatch('admin/getAllClients')
      this.leftTabClients = [...this.clients.filter((element) => element.omniumUser?.agent == undefined || element.omniumUser?.agent == null)]
      this.rightTabClients = [...this.clients.filter((element) => element.omniumUser?.agent?.id == this.agent?.agentUser?.id)]
    } finally {
      this.loading = false
    }
  }

  async close() {
    await this.$router.push({ name: 'agents' })
  }

  async addClient(client: PortalUser) {
    const agent = this.agent?.agentUser
    if (agent) {
      const rightTabClients = [...this.rightTabClients, client]
      await UserApi.updateAgentClients(agent, rightTabClients)
      this.rightTabClients = rightTabClients
      this.leftTabClients = this.leftTabClients.filter((element) => element.id != client.id)
      AppToast.info(null, 'Klient bol priradený agentovi ' + agent.name)
    }
  }
  async removeClient(client: PortalUser) {
    const agent = this.agent?.agentUser
    if (agent) {
      const rightTabClients = this.rightTabClients.filter((element) => element.id != client.id)
      await UserApi.updateAgentClients(agent, rightTabClients)
      this.rightTabClients = rightTabClients
      this.leftTabClients = [...this.leftTabClients, client]
      AppToast.info(null, 'Klient bol odobratý agentovi ' + agent.name)
    }
  }

  get agents(): PortalUser[] {
    return this.$store.state.admin.agents
  }

  get clients(): PortalUser[] {
    return this.$store.state.admin.clients
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
