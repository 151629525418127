<template>
  <router-view />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
})
export default class DetailView extends Vue {
  async beforeMount() {
    await this.init()
  }

  async init() {}
}
</script>

<style scoped lang="scss"></style>
