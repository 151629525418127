<template>
  <Detail back="Späť">
    <div class="w-full h-full flex flex-col">
      <div class="text-h4 font-bold my-5">Obnova prístupu</div>
      <div class="text-regular">
        Ak ste zabudli heslo k svojmu účtu, zadajte Váš e-mail a zašleme Vám inštrukcie na obnovu hesla.
      </div>
      <div class="flex flex-col gap-2 mt-6">
        <label for="email" class="label">Prihlasovací e-mail</label>
        <InputText id="email" placeholder="Zadajte e-mail" v-model="email" @keydown.enter="resetPassword" />
      </div>
      <div class="mt-4">
        <Button label="Obnoviť heslo" @click="resetPassword()" :loading="loading"></Button>
      </div>
      <div class="grow"></div>
      <div class="mt-16 pb-6 text-regular font-medium">
        <div v-if="showContactInfo" class="fade">
          <div>Ak si nepamätáte prihlasovací e-mail, prosím kontaktujte nás:</div>
          <div class="mt-4">
            <Contact />
          </div>
        </div>
        <div v-else class="w-full flex justify-center">
          <div class="link" @click="showContactInfo = true">Nepamätám si prihlasovací e-mail</div>
        </div>
      </div>
    </div>
  </Detail>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import UserApi from '@/api/modules/user'
import Contact from '@/components/Intro/Contact.vue'
import Detail from '@/components/Layout/Detail.vue'

@Options({
  components: {
    Detail,
    Contact,
  },
})
export default class ResetPasswordView extends Vue {

  loading: boolean = false
  email: string = ''

  showContactInfo: boolean = false

  async resetPassword() {
    if (this.loading) {
      return
    }
    this.loading = true
    try {
      await UserApi.resetPassword(this.email)
      this.$router.push({ name: 'reset-password-success' })
    } finally {
      this.loading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
