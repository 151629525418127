import { Responsive } from '@/models/responsive'

export default {
  namespaced: true,
  state: (): Responsive => ({
    mobile: false,
  }),
  mutations: {
    setResponsiveMode(context: Responsive, width: number) {
      if (width < 1024 && !context.mobile) {
        context.mobile = true
      } else if (width >= 1024 && context.mobile) {
        context.mobile = false
      }
    },
  },
  getters: {},
}
