<template>
  <div class="w-full h-full">
    <!-- <slot name="mobile"></slot> -->
    <slot v-if="mobile" name="mobile"></slot>
    <slot v-else name="desktop"></slot>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {},
})
export default class Responsive extends Vue {
  get mobile(): boolean {
    return this.$store.state.responsive.mobile
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
