<template>
  <div class="w-full h-14 px-6 flex gap-4 items-center cursor-pointer hover:bg-white hover:bg-opacity-10">
    <i :class="'pi ' + icon" style="font-size: 1rem"></i>
    <div>{{ name }}</div>
    <i v-if="trailingIcon" :class="'pi ' + trailingIcon" style="font-size: 0.75rem"></i>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    icon: {
      type: String,
      required: true,
      nullable: false,
    },
    name: {
      type: String,
      required: true,
      nullable: false,
    },
    trailingIcon: {
      type: String,
      required: false,
      nullable: true,
    },
  },
})
export default class AdminMenuButton extends Vue {
  icon!: string
  name!: string
  trailingIcon?: string | undefined | null

  mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
