import UserApi from '@/api/modules/user'
import { Admin } from '@/models/admin'
import { PortalUser } from '@/models/user'
import { ActionContext } from 'vuex'
import { State } from '..'
import { Message } from '@/models/message'
import MessageApi from '@/api/modules/message'

export default {
  namespaced: true,
  state: (): Admin => ({
    clients: [],
    agents: [],
    admins: [],
    messages: [],
  }),
  mutations: {
    setClients(context: Admin, clients: PortalUser[]) {
      context.clients = clients
    },
    setAgents(context: Admin, agents: PortalUser[]) {
      context.agents = agents
    },
    setAdmins(context: Admin, admins: PortalUser[]) {
      context.admins = admins
    },
    setMessages(context: Admin, messages: Message[]) {
      context.messages = messages
    },
  },
  actions: {
    async getAllClients(context: ActionContext<Admin, State>) {
      const data = await UserApi.getAllClients() ?? []
      context.commit('setClients', data)
    },
    async getAllAgents(context: ActionContext<Admin, State>) {
      const data = await UserApi.getAllAgents() ?? []
      context.commit('setAgents', data)
    },
    async getAllAdmins(context: ActionContext<Admin, State>) {
      const data = await UserApi.getAllAdmins() ?? []
      context.commit('setAdmins', data)
    },
    async getAllMessages(context: ActionContext<Admin, State>) {
      const data = await MessageApi.getAllPortlMessages() ?? []
      context.commit('setMessages', data)
    },
  },
  getters: {},
}
