import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Responsive = _resolveComponent("Responsive")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Responsive, null, {
    mobile: _withCtx(() => [
      _withDirectives(_createVNode(_component_Button, {
        icon: "pi pi-sync",
        onClick: _ctx.dailySyncStrategies,
        loading: _ctx.loading
      }, null, 8, ["onClick", "loading"]), [
        [
          _directive_tooltip,
          'Spustiť dennú synchronizáciu',
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    desktop: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Spustiť dennú synchronizáciu",
        icon: "pi pi-sync",
        onClick: _ctx.dailySyncStrategies,
        loading: _ctx.loading
      }, null, 8, ["onClick", "loading"])
    ]),
    _: 1
  }))
}