<template>
  <div
    class="text-regular font-semibold text-primary-900 text-center leading-none px-1.5 py-2 flex items-center justify-center rounded-md"
    :class="{
      'bg-primary-100': active
    }"
  >
    {{ label }}
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    label: {
      type: String,
      required: true,
      nullable: false,
    },
    active: {
      type: Boolean,
      required: false,
      nullable: false,
      default: false,
    },
  },
})
export default class SelectIntervalButton extends Vue {
  label!: string
  active!: boolean

  beforeMount() {
    this.init()
  }

  init() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
