<template>
  <div v-if="loading" class="w-full flex items-center justify-center">
    <ProgressSpinner></ProgressSpinner>
  </div>
  <div v-else>
    <Responsive>
      <template #mobile>
        <div class="w-full flex justify-end py-2 bdr px-4">
          <Button label="Nová správa" icon="pi pi-plus" outlined @click="openNewMessage()"></Button>
        </div>
        <div class="pb-40">
          <DataView :value="messages" dataKey="id" paginator :alwaysShowPaginator="false" :rows="10" paginatorTemplate="PrevPageLink PageLinks NextPageLink">
            <template #list="slotProps">
              <AdminPreview leading="Predmet" :title="slotProps.data.subject" :trailing="slotProps.data.date ? formatDate(slotProps.data.date) : null">
                <template #actions>
                  <div class="flex gap-2">
                    <Button v-tooltip.bottom="'Zobraziť správu'" icon="pi pi-eye" @click="openMessage(slotProps.data)" />
                    <DeleteMessageButton :message="slotProps.data" />
                  </div>
                </template>
                <template v-if="slotProps.data.author?.email" #body>
                  <div>Autor: {{ slotProps.data.author?.email }}</div>
                </template>
              </AdminPreview>
            </template>
            <template #empty>
              <div class="px-4 py-6 w-full text-center text-h5 text-text-500">
                {{ (messages ?? []).length <= 0 ? 'Žiadne správy': 'Žiadne nájdené záznamy' }}
              </div>
            </template>
          </DataView>
        </div>
      </template>
      <template #desktop>
        <div class="w-full flex justify-end py-4">
          <Button label="Nová správa" icon="pi pi-plus" outlined @click="openNewMessage()"></Button>
        </div>
        <DataTable v-if="messages" :value="messages" dataKey="id" paginator :rows="10" :alwaysShowPaginator="false" paginatorTemplate="PrevPageLink PageLinks NextPageLink">
          <Column field="date" header="Dátum" class="text-text-700">
            <template #body="slotProps">
              <div v-if="slotProps.data.date">
                <div>{{ formatDate(slotProps.data.date) }}</div>
              </div>
              <div v-else>
                <div>-</div>
              </div>
            </template>
          </Column>
          <Column header="Autor" class="text-text-700">
            <template #body="slotProps">
              <div v-if="slotProps.data.author?.email">
                <div>{{ slotProps.data.author?.email }}</div>
              </div>
              <div v-else>
                <div>-</div>
              </div>
            </template>
          </Column>
          <Column field="subject" header="Predmet" class="font-semibold"></Column>
          <Column header="" class="text-text-700">
            <template #body="slotProps">
              <div class="flex gap-2">
                <Button v-tooltip.bottom="'Zobraziť správu'" icon="pi pi-eye" @click="openMessage(slotProps.data)" />
                <DeleteMessageButton :message="slotProps.data" />
              </div>
            </template>
          </Column>
          <template #empty>
            <div class="w-full text-center text-h5 text-text-500">
              {{ (messages ?? []).length <= 0 ? 'Žiadne správy': 'Žiadne nájdené záznamy' }}
            </div>
          </template>
        </DataTable>
      </template>
    </Responsive>
  </div>
</template>

<script lang="ts">
import DeleteMessageButton from '@/components/Buttons/DeleteMessageButton.vue'
import AdminPreview from '@/components/Preview/AdminPreview.vue'
import Responsive from '@/components/Responsive.vue'
import { formatDate, isDST } from '@/misc'
import { Message } from '@/models/message'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    DeleteMessageButton,
    Responsive,
    AdminPreview,
  },
})
export default class AdminMessagesView extends Vue {
  loading: boolean = false

  async beforeMount() {
    await this.init()
  }

  async init() {
    this.loading = true
    try {
      await this.$store.dispatch('admin/getAllMessages')
    } finally {
      this.loading = false
    }
  }

  openNewMessage() {
    this.$router.push({ name: 'admin-message-create' })
  }

  openMessage(message: Message) {
    if (message.id) {
      this.$router.push({ name: 'admin-message', params: { id: message.id } })
    }
  }

  formatDate(date: string) {
    return formatDate(new Date(date))
  }

  get messages(): Message[] {
    return this.$store.state.admin.messages
  }
}
</script>

<style scoped lang="scss">
.bdr {
  border-bottom: 1px solid #d9d9d9;
}
</style>
