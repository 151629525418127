<template>
  <div>
    <Button
      v-tooltip.bottom="'Vymazať agenta'"
      icon="pi pi-trash"
      severity="danger"
      text
      @click="deleteAgent"
      :loading="loading"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { useConfirm } from 'primevue/useconfirm'
import { PortalUser } from '@/models/user'
import UserApi from '@/api/modules/user'
import AppToast from '@/toast'

@Options({
  props: {
    agent: {
      type: Object as () => PortalUser,
      required: true,
      nullable: false,
    },
  },
})
export default class DeleteAgentButton extends Vue {
  agent!: PortalUser

  loading: boolean = false

  confirm = useConfirm()

  async beforeMount() {
    await this.init()
  }

  async init() {}

  deleteAgent() {
    this.confirm.require({
      message: `Naozaj chcete vymazať agenta <b>${this.agent!.agentUser?.name}</b>?`,
      header: 'Vymazať agenta',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      accept: async () => {
        this.loading = true
        try {
          if (this.agent.agentUser) {
            await UserApi.deleteAgent(this.agent.agentUser)
            const agents = this.agents.filter(element => element.id != this.agent.id)
            this.$store.commit('admin/setAgents', agents)
            AppToast.info(null, 'Agent bol odstránený')
          }
        } finally {
          this.loading = false
        }
      },
    })
  }

  get agents(): PortalUser[] {
    return this.$store.state.admin.agents
  }
}
</script>

<style scoped lang="scss"></style>
