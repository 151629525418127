import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-small font-normal text-text-500 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.lastUpdated)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Dáta k " + _toDisplayString(_ctx.lastUpdated), 1))
    : _createCommentVNode("", true)
}