<template>
  <div v-if="lastUpdated" class="text-small font-normal text-text-500 text-center">Dáta k {{ lastUpdated }}</div>
</template>

<script lang="ts">
import { formatDate } from '@/misc'
import { Strategy } from '@/models/portal'
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    strategy: {
      type: Object as () => Strategy,
      required: true,
      nullable: false,
    },
  },
})
export default class StrategiesUpdated extends Vue {
  strategy!: Strategy
  lastUpdated: string | null = null

  beforeMount() {
    this.init()
  }

  init() {
    const strategy = this.strategy
    if (strategy && strategy.values) {
      const value = strategy.values[0]
      if (value && value.date) {
        this.lastUpdated = formatDate(new Date(value.date), true)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
