import { createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full"
}
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = {
  key: 1,
  class: "w-full mt-4 px-6"
}
const _hoisted_5 = { class: "w-full mt-10" }
const _hoisted_6 = {
  key: 2,
  class: "w-fit mt-6"
}
const _hoisted_7 = {
  key: 0,
  class: "grid grid-cols-12 h-full w-full gap-5 px-6 pb-4"
}
const _hoisted_8 = { class: "col-span-3 h-full" }
const _hoisted_9 = { class: "w-full h-full flex flex-col pr-7" }
const _hoisted_10 = { class: "w-full grow flex flex-col gap-2 brd" }
const _hoisted_11 = { class: "w-full" }
const _hoisted_12 = { class: "w-fit grow pl-4 pb-2" }
const _hoisted_13 = { class: "w-fit pb-6" }
const _hoisted_14 = { class: "w-full mt-4" }
const _hoisted_15 = { class: "col-span-9 h-full" }
const _hoisted_16 = { class: "w-full h-full" }
const _hoisted_17 = {
  key: 1,
  class: "w-full mt-4 px-6 flex flex-col items-center"
}
const _hoisted_18 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StrategyPreview = _resolveComponent("StrategyPreview")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StrategiesUpdated = _resolveComponent("StrategiesUpdated")!
  const _component_NoStrategy = _resolveComponent("NoStrategy")!
  const _component_StrategyActions = _resolveComponent("StrategyActions")!
  const _component_CapitolDown = _resolveComponent("CapitolDown")!
  const _component_Overview = _resolveComponent("Overview")!
  const _component_StrategyDetail = _resolveComponent("StrategyDetail")!
  const _component_Responsive = _resolveComponent("Responsive")!

  return (_openBlock(), _createBlock(_component_Responsive, null, {
    mobile: _withCtx(() => [
      _createVNode(_component_Overview, null, {
        heading: _withCtx(() => [
          _createTextVNode(" Portfóliá ")
        ]),
        default: _withCtx(() => [
          (_ctx.strategies && _ctx.strategies.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.strategies, (strategy) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_router_link, {
                      to: { name: 'strategy-detail', params: { code: strategy.code } }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_StrategyPreview, { strategy: strategy }, null, 8, ["strategy"])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 256)),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_StrategiesUpdated, {
                    strategy: _ctx.strategies[0]
                  }, null, 8, ["strategy"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_NoStrategy)
              ])),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_StrategyActions, {
              showIban: _ctx.strategies && _ctx.strategies.length > 0
            }, null, 8, ["showIban"])
          ]),
          (_ctx.strategies && _ctx.strategies.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_CapitolDown, {
                  strategy: _ctx.strategies[0]
                }, null, 8, ["strategy"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    desktop: _withCtx(() => [
      _createVNode(_component_Overview, null, {
        default: _withCtx(() => [
          (_ctx.strategies && _ctx.strategies.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.strategies, (str) => {
                        return (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_router_link, {
                            to: { name: 'strategy-detail', params: { code: str.code } }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_StrategyPreview, {
                                strategy: str,
                                selected: str === _ctx.strategies[0]
                              }, null, 8, ["strategy", "selected"])
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]))
                      }), 256)),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_StrategiesUpdated, {
                          strategy: _ctx.strategies[0]
                        }, null, 8, ["strategy"])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_CapitolDown, {
                          strategy: _ctx.strategies[0]
                        }, null, 8, ["strategy"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_StrategyActions, { showIban: true })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_StrategyDetail, {
                      strategy: _ctx.strategies[0]
                    }, null, 8, ["strategy"])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createVNode(_component_NoStrategy),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_StrategyActions, { showIban: false })
                ])
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}