<template>
  <Detail :hideHeader="true">
    <div class="w-full h-full flex flex-col">
      <div class="text-h4 font-bold my-5">Platnosť odkazu vypršala</div>
      <div class="text-regular">
        Platnosť odkazu na obnovu hesla je 5 hodín a už vypršala. Prejdite do portálu a začnite odznova.
      </div>
      <div class="flex flex-col gap-4 mt-6">
        <router-link :to="{ name: 'reset-password' }">
          <Button label="Získať nový odkaz"></Button>
        </router-link>
        <router-link :to="{ name: 'login' }">
          <Button label="Prejsť do portálu" outlined></Button>
        </router-link>
      </div>
    </div>
  </Detail>
</template>

<script lang="ts">
import Detail from '@/components/Layout/Detail.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Detail,
  },
})
export default class ResetPasswordExpiredView extends Vue {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
