<template>
  <div>
    <div class="flex flex-col gap-2">
      <label for="username" class="label">Váš prihlasovací e-mail</label>
      <div id="username" class="item">
        {{ email ?? 'None' }}
      </div>
    </div>
    <div class="flex flex-col gap-2 mt-4">
      <label for="password" class="label">Vaše nové heslo</label>
      <Password
        v-model="password"
        promptLabel=" "
        weakLabel="Slabé"
        mediumLabel="Stredné"
        strongLabel="Silné"
        toggleMask
        class="w-full"
        :mediumRegex="mediumRegex.source"
        :strongRegex="strongRegex.source"
        :inputStyle="{ width: '100%' }"
        @update:modelValue="onUpdate"
        @keydown.enter="confirm"
      >
        <template #header>
          <div class="text-regular font-semibold pb-4">Zvoľte si heslo</div>
        </template>
        <template #footer>
          <Divider />
          <div class="leading-none text-regular">Heslo musí obsahovať:</div>
          <ul class="pl-2 ml-2 mt-4 text-regular" style="line-height: 1.5">
            <li>Aspoň jedno malé písmeno</li>
            <li>Aspoň jedno veľké písmeno</li>
            <li>Aspoň jednu číslicu</li>
            <li>Minimálne 8 znakov</li>
          </ul>
        </template>
      </Password>
    </div>
    <div class="mt-4">
      <Button label="Potvrdiť" :loading="loading" :disabled="disabled" @click="confirm"></Button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Intro from './Intro.vue'

@Options({
  components: {
    Intro,
  },
  props: {
    email: {
      type: String,
      required: false,
      nullable: false,
    },
    onCreate: {
      type: Function,
      required: false,
      nullable: false,
    },
  },
})
export default class CreatePassword extends Vue {
  email!: string
  onCreate!: Function

  loading: boolean = false
  disabled: boolean = true

  password: string = ''

  mediumRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')
  strongRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')

  async confirm() {
    if (this.loading) {
      return
    }
    this.loading = true
    try {
      await this.onCreate(this.password)
    } finally {
      this.loading = false
    }
  }

  onUpdate(value: string) {
    const valid = this.password.match(this.mediumRegex) || this.password.match(this.strongRegex)
    if (this.disabled && valid) {
      this.disabled = false
    } else if (!this.disabled && !valid) {
      this.disabled = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul {
  list-style-type: disc;
}
</style>
