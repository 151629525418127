<template>
  <CustomDialog title="Upraviť klienta" routeOnClose="clients">
    <div class="mt-4 custom-dialog-wrapper px-4">
      <div class="flex flex-col gap-2 w-full">
        <label for="email" class="label">E-mail</label>
        <InputText id="email" placeholder="Zadajte email" v-model="email" @keydown.enter="updateClient()" style="width: 100%" />
      </div>
      <div v-if="error" class="mt-4">
        <InlineMessage severity="error" class="w-full"> {{ error }} </InlineMessage>
      </div>
      <div class="mt-4">
        <Button label="Potvrdiť" @click="updateClient()" :loading="loading"></Button>
      </div>
    </div>
  </CustomDialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import UserApi from '@/api/modules/user'
import { PortalUser } from '@/models/user'
import AppToast from '@/toast'
import CustomDialog from '@/components/Dialog/CustomDialog.vue'

@Options({
  components: {
    CustomDialog,
  },
  props: ['id'],
})
export default class ClientEditView extends Vue {
  id!: number
  error: string | null = null

  email!: string

  client?: PortalUser
  loading: boolean = false

  async beforeMount() {
    await this.init()
  }

  async init() {
    this.client = this.clients.find((element) => element.id == this.id)
    if (!this.client?.email) {
      await this.close()
    }
    this.email = this.client?.email!
  }

  async updateClient() {
    this.error = null
    this.loading = true
    try {
      const portalUser = await UserApi.updatePortalUser(this.client!, this.email)
      const clients = this.clients
      const index = clients.findIndex((element) => element.id === portalUser.id)
      if (index >= 0) {
        clients[index] = portalUser
        this.$store.commit('setClients', clients)
        AppToast.info(null, 'Klient bol upravený')
      }
      this.close()
    } catch (e: any) {
      this.error = e.message
      return
    } finally {
      this.loading = false
    }
  }

  async close() {
    await this.$router.push({ name: 'clients' })
  }

  get clients(): PortalUser[] {
    return this.$store.state.admin.clients
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
