<template>
  <div class="w-full h-full flex flex-col">
    <div class="w-full h-16 flex items-center justify-between shrink-0 pl-4 bg-white text-text-700 text-h5 font-semibold leading-none fixed top-0 bdr z-index">
      <div>{{ title }}</div>
      <div class="p-4 cursor-pointer" @click="close()">
        <i class="pi pi-times" style="font-size: 1rem"></i>
      </div>
    </div>
    <div class="h-16 w-full shrink-0"></div>
    <div class="w-full grow shrink-0 flex flex-col items-center pb-20">
      <div class="container h-full flex flex-col items-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    title: {
      type: String,
      required: false,
      nullable: false,
    },
    routeOnClose: {
      type: String,
      required: false,
      nullable: true,
    },
  },
})
export default class CustomDialog extends Vue {
  title!: string
  routeOnClose?: string | undefined | null

  close() {
    if (this.routeOnClose) {
      this.$router.push({ name: this.routeOnClose })
    }
    this.$emit('onClose')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bdr {
  border: 1px solid rgb(206 212 218);
}

.z-index {
  z-index: 1000;
}
</style>
