<template>
  <Responsive>
    <template #mobile>
      <div class="w-full text-black bdr py-4 cursor-pointer" @click="goToDetail()">
        <div class="w-full flex gap-4 items-center text-regular">
          <div v-if="!message.seen" class="text-secondary-500">
            <i class="pi pi-circle-fill" style="font-size: 0.6rem"></i>
          </div>
          <div class="flex flex-col">
            <div class="flex items-center gap-1">
              <div :class="{ 'font-semibold': !message.seen }">{{ message.subject }}</div>
              <div v-if="message.attachments && message.attachments.length > 0">
                <i class="pi pi-paperclip" style="font-size: 0.75rem"></i>
              </div>
            </div>
            <div class="text-text-500">{{ formatMessageDate(message.date) }}</div>
          </div>
        </div>
      </div>
    </template>
    <template #desktop>
      <div
        class="w-full h-[calc(74px)] px-2 text-black rounded-md hover:bg-primary-100 remove-bdr-hover cursor-pointer"
        :class="{ 'bg-primary-100': selected }"
        @click="goToDetail()"
      >
        <div class="w-full h-[calc(74px)] flex gap-4 items-center text-regular py-4 px-2" :class="{ 'bdr-desktop': !selected }">
          <div v-if="!message.seen" class="text-secondary-500">
            <i class="pi pi-circle-fill" style="font-size: 0.6rem"></i>
          </div>
          <div class="flex flex-col">
            <div class="flex items-center gap-1">
              <div :class="{ 'font-semibold': !message.seen }">{{ message.subject }}</div>
              <div v-if="message.attachments && message.attachments.length > 0">
                <i class="pi pi-paperclip" style="font-size: 0.75rem"></i>
              </div>
            </div>
            <div class="text-text-500">{{ formatMessageDate(message.date) }}</div>
          </div>
        </div>
      </div>
    </template>
  </Responsive>
</template>

<script lang="ts">
import MessageApi from '@/api/modules/message'
import { formatDate, isDST } from '@/misc'
import { Message } from '@/models/message'
import { Options, Vue } from 'vue-class-component'
import Responsive from '../Responsive.vue'

@Options({
  props: {
    message: {
      type: Object as () => Message,
      required: true,
      nullable: false,
    },
    selected: {
      type: Boolean,
      required: false,
      nullable: false,
      default: false,
    },
  },
  components: {
    Responsive,
  },
})
export default class MessagePreview extends Vue {
  message!: Message
  selected!: boolean

  beforeMount() {
    this.init()
  }

  init() {}

  async goToDetail() {
    if (!this.message.seen) {
      try {
        await MessageApi.markMessageSeen(this.message.id).then((_) => {
          this.$store.commit('portal/decreeseUnseenMessageCount')
          this.message.seen = true
        })
      } catch (e) {}
    }
    this.$router.push({ name: 'message-detail', params: { id: this.message.id } })
  }

  formatMessageDate(date: string) {
    const real = new Date(date)
    if (isDST(real)) {
      real.setHours(real.getHours() + 2)
    } else {
      real.setHours(real.getHours() + 1)
    }
    return formatDate(real)
  }

  get desktop() {
    return !this.$store.state.responsive.mobile
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bdr {
  border-bottom: 1px solid #d9d9d9;
}

.remove-bdr-hover:hover > div {
  border-color: transparent;
}

.bdr-desktop {
  border-bottom: 0.5px solid #d9d9d9;
  border-top: 0.5px solid #d9d9d9;
}
</style>
