<template>
  <div class="grow w-full mt-14 overflow-y-auto flex flex-col items-center">
    <div class="container">
      <TabMenu :model="items" />
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { MenuItem } from 'primevue/menuitem'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
})
export default class AdminSectionsView extends Vue {
  active: number = 0
  items!: MenuItem[]

  beforeMount() {
    this.init()
  }

  init() {
    const isAdmin = this.$store.getters['user/isAdmin']
    this.items = [
      { label: 'Klienti', icon: 'pi pi-fw pi-users', to: '/admin/sections/clients' },
      { label: 'Agenti', icon: 'pi pi-fw pi-users', to: '/admin/sections/agents', visible: isAdmin },
      { label: 'Administrátori', icon: 'pi pi-fw pi-users', to: '/admin/sections/admins', visible: isAdmin },
      { label: 'Správy', icon: 'pi pi-fw pi-envelope', to: '/admin/sections/messages' },
    ]
  }
}
</script>

<style scoped lang="scss"></style>
