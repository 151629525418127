<template>
  <div class="w-full h-full flex flex-col items-center">
    <div class="w-full h-[calc(56px)] flex flex-col bg-primary-900 shrink-0 fixed z-50">
      <Responsive>
        <template #mobile>
          <div class="w-full h-full flex justify-between items-center gap-4">
            <router-link :to="{ name: 'portfolio' }" class="h-full px-4 flex items-center w-fit cursor-pointer">
              <img :src="require('@/assets/logo.svg')" width="136" />
            </router-link>
            <SideMenu />
          </div>
        </template>
        <template #desktop>
          <div class="w-full h-full flex justify-between items-center gap-4">
            <router-link :to="{ name: 'portfolio' }" class="h-full px-4 flex items-center w-56 cursor-pointer">
              <img :src="require('@/assets/logo.svg')" width="136" />
            </router-link>
            <div class="text-white w-full h-full flex justify-center items-center leading-none">
              <div class="nav-btn" @click="$router.push({ name: 'portfolio' })">Portfóliá</div>
              <div class="nav-btn" @click="$router.push({ name: 'all-messages' })">
                <div class="flex gap-1.5 items-center">
                  <div>Správy</div>
                  <div
                    v-if="count && count > 0"
                    class="count-avatar text-primary-900 h-5 text-regular font-bold"
                    style="padding-right: 0.5px; padding-top: 0.5px"
                    :class="{ 'w-6': count > 9, 'w-5': count <= 9 }"
                  >
                    {{ count > 9 ? 9 : count }}{{ count > 9 ? '+' : '' }}
                  </div>
                </div>
              </div>
              <div class="nav-btn" @click="$router.push({ name: 'contact' })">Kontakty</div>
            </div>
            <div class="w-56 h-full shrink-0">
              <SideMenu />
            </div>
          </div>
        </template>
      </Responsive>
    </div>
    <div class="grow w-full mt-14 overflow-y-auto">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import SideMenu from '@/components/Menu/SideMenu.vue'
import Responsive from '@/components/Responsive.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    SideMenu,
    Responsive,
  },
})
export default class HomeView extends Vue {
  async beforeMount() {
    await this.init()
  }

  async init() {}

  get count(): number {
    return this.$store.state.portal.unseenMessageCount ?? 0
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.nav-btn {
  padding: 0px 16px;
  cursor: pointer;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #eab402;
}
</style>
