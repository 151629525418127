<template>
  <Button icon="pi pi-plus" :label="label" outlined :loading="loading" @click="add()" />
</template>

<script lang="ts">
import { PortalUser } from '@/models/user'
import { Options, Vue } from 'vue-class-component'

type CallbackFunction = (client: PortalUser) => Promise<void>

@Options({
  props: {
    client: {
      type: Object as () => PortalUser,
      required: true,
      nullable: false,
    },
    label: {
      type: String,
      required: true,
      nullable: false,
    },
    addClient: {
      type: Function,
      required: true,
      nullable: false,
    },
  },
})
export default class AddClientButton extends Vue {
  loading: boolean = false

  label!: string
  client!: PortalUser
  addClient!: CallbackFunction

  async beforeMount() {
    await this.init()
  }

  async add() {
    this.loading = true
    try {
      await this.addClient(this.client)
    } finally {
      this.loading = false
    }
  }

  async init() {}
}
</script>

<style scoped lang="scss"></style>
