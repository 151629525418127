import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_Button, {
      icon: "pi pi-trash",
      severity: "danger",
      text: "",
      onClick: _ctx.deleteMessage,
      loading: _ctx.loading
    }, null, 8, ["onClick", "loading"]), [
      [
        _directive_tooltip,
        'Vymazať správu',
        void 0,
        { bottom: true }
      ]
    ])
  ]))
}