<template>
  <div class="w-full h-full flex flex-col items-center">
    <div class="w-full h-[calc(56px)] flex flex-col bg-primary-900 justify-center shrink-0 fixed z-50">
      <div class="w-full flex justify-between items-center gap-4 h-full">
        <router-link :to="{ name: 'login' }"  class="px-4 w-fit h-full flex items-center cursor-pointer">
          <img :src="require('@/assets/logo.svg')" width="136" />
        </router-link>
      </div>
    </div>
    <div class="grow w-full mt-14 overflow-y-auto">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'

export default class OnboardingView extends Vue {
  async mounted() {
    this.$store.commit('user/logout')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
